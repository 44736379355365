import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import Input from "../common/input";
import Spinner from "../common/spinner";
import {
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Select from "../common/select";
import DatePicker from "react-datepicker";
import "./businessCustomer.scss";
import * as service from "./businessCustomerServices.js";
import Alart from "../common/alart";
import { abnValidator, log } from "../../helper/helper";

function BusinessCustomer(props) {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedGender, setGender] = useState("Male");
  const [givenName, setGivenName] = useState("");
  const [givenNameError, setGivenNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState("");
  const [abn, setAbn] = useState("");
  const [abnError, setAbnError] = useState("");
  const [alartTriggerMessage, setAlartTriggerMessage] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postCodeError, setPostCodeError] = useState("");

  const currentdate = new Date();
  const maxdate = new Date(
    currentdate.setYear(currentdate.getFullYear() + 100)
  );
  let gender = [
    { id: "0", name: "Male" },
    { id: "1", name: "Female" },
    { id: "2", name: "Non-binary" },
    { id: "3", name: "Prefer not to say" },
  ];

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
  });

  const handleAlart = () => {
    setName("");
    setEmail("");
    setGivenName("");
    setLastName("");
    setMobile("");
    setBirthday("");
    setAbn("");
    setAlartTriggerMessage("");
    setPostCode("");
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const setGenderSelect = (value) => {
    setGender(value);
  };

  const register = async () => {
    const english = /^[A-Za-z]*$/;

    setNameError("");
    setAbnError("");
    setEmailError("");
    setGivenNameError("");
    setLastNameError("");
    setMobileError("");
    setBirthdayError("");
    setPostCodeError("");

    if (name.replace(/\s/g, "").length < 2) {
      return setNameError("Minimum 2 letters (English Only)");
    }
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(email)) {
      return setEmailError("Email address is not valid!");
    }
    if (
      givenName.replace(/\s/g, "").length < 2 ||
      !english.test(givenName.replace(/\s/g, ""))
    ) {
      return setGivenNameError("Minimum 2 letters (English Only)");
    }
    if (
      lastName.replace(/\s/g, "").length < 2 ||
      !english.test(lastName.replace(/\s/g, ""))
    ) {
      return setLastNameError("Minimum 2 letters (English Only)");
    }
    if (!mobile.replace(/\s/g, "").length === 12) {
      return setMobileError("12 Digits (Beginning with +61)");
    }
    const sortedBirth = Moment(birthday).format("YYYY-MM-DD");
    if (sortedBirth === "") {
      return setBirthdayError("Please enter your birthday");
    }
    if (!abnValidator(abn)) {
      return setAbnError("ABN must be a 11 digit number");
    }

    if (postCode.length !== 4) {
      return setPostCodeError("Post code must be 4 digits");
    }

    setLoading(true);
    try {
      const result = await service.adminRegisterBusinessCustomer(
        name,
        email,
        givenName,
        lastName,
        mobile,
        sortedBirth,
        selectedGender,
        abn,
        postCode
      );
      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 200:
            sendPasswordEmail(email, result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      setLoading(false);
      toast.error(error.message);
    }
  };

  const sendPasswordEmail = (email, message) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setLoading(false);
        setAlartTriggerMessage(message);
      })
      .catch((error) => {
        setLoading(false);
        setAlartTriggerMessage(error);
      });
  };

  const handleMobile = (value) => {
    if (value.length < 13) {
      const result = value.replace(/\D/g, "");
      if (value.length === 1) {
        if (value === "0") {
          setMobile("+61");
        } else if (value === "+") {
          setMobile("+61");
        } else {
          setMobile(`+61${result}`);
        }
      } else if (result.length < 12) {
        setMobile(`+${result}`);
        if (value.length === 12) {
          setMobileError("");
        } else {
          setMobileError("12 Digits (Beginning with +61)");
        }
      }
    }
  };

  return (
    <div className="business-customer">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-4">
          <Input
            label="Business Name:"
            type="String"
            name="name"
            value={name}
            error={nameError}
            onChange={(e) => {
              setName(e.target.value);
              if (e.target.value.replace(/\s/g, "").length > 1) {
                setNameError("");
              } else {
                setNameError("Minimum 2 letters (English Only)");
              }
            }}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Email Address:"
            type="email"
            name="email"
            value={email}
            error={emailError}
            onChange={(e) => {
              const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
              if (regex.test(e.target.value)) {
                setEmailError("");
              } else {
                setEmailError("Email address is not valid!");
              }
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="col-md-4">
          <Select
            name="gender"
            label="Gender:"
            options={gender}
            onChange={(e) => setGenderSelect(e.target.value)}
            disableSelect={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Input
            label="Given Name:"
            type="String"
            name="givenName"
            value={givenName}
            error={givenNameError}
            onChange={(e) => {
              setGivenName(e.target.value);
              if (e.target.value.replace(/\s/g, "").length > 1) {
                setGivenNameError("");
              } else {
                setGivenNameError("Minimum 2 letters (English Only)");
              }
            }}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Last Name:"
            type="String"
            name="lastName"
            value={lastName}
            error={lastNameError}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Mobile Number:"
            type="String"
            name="mobile"
            value={mobile}
            error={mobileError}
            onChange={(e) => handleMobile(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="dateTime">Birthday:</label>
            <DatePicker
              selected={birthday}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setBirthday(date)}
              className="form-control"
              error={birthdayError}
              maxDate={maxdate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <Input
            label="ABN:"
            type="String"
            name="abn"
            value={abn}
            error={abnError}
            onChange={(e) => {
              if (abnValidator(e.target.value)) {
                setAbnError("");
              } else {
                setAbnError("ABN must be a 11 digit number");
              }
              setAbn(e.target.value);
            }}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="Post Code:"
            type="String"
            name="postCode"
            value={postCode}
            error={postCodeError}
            onChange={(e) => {
              const result = e.target.value.replace(/\D/g, "");
              if (result) {
                if (result.length === 1) {
                  if (result != "0") {
                    setPostCode(result);
                    setPostCodeError("");
                  } else {
                    setPostCodeError("This field can not start with a zero");
                  }
                } else if (result.length === 0) {
                  setPostCode("");
                } else if (result.length < 5) {
                  setPostCode(result);
                }
              } else {
                setPostCode("");
              }
            }}
          />
        </div>
      </div>
      <div className="row mg-custom">
        <div className="col-md-12">
          <Button className="bus-btn" onClick={register}>
            Send
          </Button>
        </div>
      </div>
      {alartTriggerMessage !== "" ? (
        <Alart
          message={alartTriggerMessage}
          actionTitle="Ok"
          handleAlartAction={() => handleAlart()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default BusinessCustomer;
