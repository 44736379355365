import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import loginBackground from "../../assets/login-background.jpg";
import "./login.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import config from "../../config.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../common/spinner";
import * as loginService from "./loginServices";
import { log } from "../../helper/helper";

class Login extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
    isLoading: false,
  };

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        this.props.history.replace("/login");
      }
    });
  }

  doSubmit = async () => {
    this.setState({ isLoading: true });
    try {
      const user = await loginService.loginInFirebase(
        this.state.data.username,
        this.state.data.password
      );
      log(user);
      if (user) {
        this.callLoginService(user);
      }
    } catch (error) {
      log(error.message);
      this.setState({ isLoading: false });
      toast.error("There is a problem.");
    }
  };

  callLoginService = async (user) => {
    log("****************");
    log(user);
    log("****************");
    try {
      const result = await loginService.getMobileNumberInLogin();
      log(result);
      if (result) {
        this.setState({ isLoading: false });
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            const mobile = result.data.mobile_number;
            this.props.history.replace("/confirmMobile", {
              mobile,
            });
            return;
          case 401:
            return log(result.data.code);
          case 500:
            return log(result.data.code);
          default:
            return log(result.data.code);
        }
      }
    } catch (error) {
      log(error.message);
      toast.error("There is a problem.");
    }
    this.setState({ isLoading: false });
  };

  render() {
    localStorage.removeItem("isLoginSuccessfully");
    localStorage.removeItem("startTime");

    const isLoading = this.state.isLoading;
    const renderAuthButton = () => {
      if (isLoading) {
        return <Spinner />;
      }
    };

    return (
      <div>
        {renderAuthButton()}
        <div className="container loginForm">
          <ToastContainer />
          <div className="row loginRow">
            <div className="col-md-6 d-none d-sm-none d-md-block">
              <div className="loginImageHolder">
                <img
                  src={loginBackground}
                  className="loginImage"
                  alt="background"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="loginInput">
                <h1>Welcome to Bearer</h1>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("username", "", "email", "Username")}
                  {this.renderInput("password", "", "password", "Password")}
                  {this.renderButton("Login")}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
