import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as service from "../findCustomerService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function LoyaltyTier({ user, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setLoyalBtnDisable(true);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [loyalBtnDisable, setLoyalBtnDisable] = useState(true);
  const [loyaltySelection, setLoyaltySelection] = useState(0);
  const [loyaltySelectionServer, setLoyaltySelectionServer] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const setLoyalDefault = () => {
    switch (user ? user.loyalty_tier : "") {
      case "Basic":
        setLoyaltySelection(0);
        setLoyaltySelectionServer(0);
        break;
      case "1":
        setLoyaltySelection(1);
        setLoyaltySelectionServer(1);
        break;
      case "2":
        setLoyaltySelection(2);
        setLoyaltySelectionServer(2);
        break;
      case "3":
        setLoyaltySelection(3);
        setLoyaltySelectionServer(3);
        break;
      default:
        break;
    }
  };

  const setLoyaltyData = (value) => {
    setLoyaltySelection(value);
    if (value !== loyaltySelectionServer) {
      setLoyalBtnDisable(false);
    } else {
      setLoyalBtnDisable(true);
    }
  };

  const handleLoyaltySubmit = async () => {
    handleClose(true);
    setLoading(true);
    let loyalFinalChoose = "";

    switch (loyaltySelection) {
      case 0:
        loyalFinalChoose = "Basic";
        break;
      case 1:
        loyalFinalChoose = "1";
        break;
      case 2:
        loyalFinalChoose = "2";
        break;
      case 3:
        loyalFinalChoose = "3";
        break;
      default:
        break;
    }
    try {
      const result = await service.adminCustomerChangeLoyaltyTier(
        user.customer_uid,
        loyalFinalChoose
      );
      log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            setLoyaltySelectionServer(loyaltySelection);
            setLoading(false);
            toast.success(result.data.message);
            handleReloadSingle();
            return;
          case 401:
            setLoading(false);
            return props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>Loyalty Tier</h5>
      </div>
      <div className="col-md-4">
        <div className="value">{user ? user.loyalty_tier : 0}</div>
      </div>
      <div className="col-md-4">
        <Button
          variant="primary"
          className="edit-btn"
          onClick={() => {
            handleShow();
            setLoyalDefault();
          }}
        >
          Edit
        </Button>
        <Modal
          show={show}
          className="loyal-modal modal-popup-custom"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Loyalty Tier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-radio-wrapper">
              <input
                type="radio"
                value={loyaltySelection === 0}
                name="loyaltySelection"
                checked={loyaltySelection === 0}
                onChange={() => setLoyaltyData(0)}
              />{" "}
              <label htmlFor="">Basic</label>
              <br />
              <input
                type="radio"
                value={loyaltySelection === 1}
                name="loyaltySelection"
                checked={loyaltySelection === 1}
                onChange={() => setLoyaltyData(1)}
              />{" "}
              <label htmlFor="">1</label>
              <br />
              <input
                type="radio"
                value={loyaltySelection === 2}
                name="loyaltySelection"
                checked={loyaltySelection === 2}
                onChange={() => setLoyaltyData(2)}
              />{" "}
              <label htmlFor="">2</label>
              <br />
              <input
                type="radio"
                value={loyaltySelection === 3}
                name="loyaltySelection"
                checked={loyaltySelection === 3}
                onChange={() => setLoyaltyData(3)}
              />{" "}
              <label htmlFor="">3</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={loyalBtnDisable}
              onClick={handleLoyaltySubmit}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default LoyaltyTier;
