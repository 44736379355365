import React, { useState, useEffect } from "react";
import "./webEnquiries.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as services from "./webEnquiriesServices";
import { log, timestampToDateFull } from "../../helper/helper";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import {
  Button,
  Segmented,
  Row,
  Col,
  Switch,
  Tooltip,
  Space,
  Input,
  Typography,
  Card,
  Divider,
  Rate,
  Select,
} from "antd";
import {
  SearchOutlined,
  MailOutlined,
  CalendarOutlined,
  MailFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import * as helper from "../../helper/helper";

const WebEnquiries = (props) => {
  const { Text } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;

  const [isLoading, setLoading] = useState(true);
  const [assignMessageFilterValue, setAssignMessageFilterValue] =
    useState("Show All Messages");
  const [messageData, setMessageData] = useState();
  const [messageType, setMessageType] = useState("inbox");
  const [searchInbox, setSearchInbox] = useState("");
  const [messageInbox, setMessageInbox] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState();
  const [rateValue, setRateValue] = useState(0);
  const [rateFilterValue, setRateFilterValue] = useState(0);

  const [selectedMessageIsRead, setSelectedMessageIsRead] = useState(false);
  const [selectedMessageIsOpen, setSelectedMessageIsOpen] = useState(false);
  const [categorySelect, setCategorySelect] = useState("");
  const [subCategorySelect, setSubCategorySelect] = useState("");
  const [assignTo, setAssignTo] = useState("");

  const [titleTextStatusError, setTitleTextStatusError] = useState("");
  const [noteTextStatusError, setNoteTextStatusError] = useState("");
  const [assignmentStatusError, setAssignmentStatusError] = useState("");
  const [adminTitle, setAdminTitle] = useState("");
  const [adminNote, setAdminNote] = useState("");
  const [assignmentReason, setAssignmentReason] = useState("");

  const [userCaseHistory, setUserCaseHistory] = useState({});
  const [historyShow, setHistoryShow] = useState(false);
  const handleHistoryClose = () => setHistoryShow(false);
  const handleHistoryShow = () => setHistoryShow(true);

  const [userNoteHistory, setUserNoteHistory] = useState({});
  const [noteHistoryShow, setNoteHistoryShow] = useState(false);
  const handleNoteHistoryClose = () => setNoteHistoryShow(false);
  const handleNoteHistoryShow = () => setNoteHistoryShow(true);

  const rateDesc = ["Weak", "Semi-Normal", "Normal", "High", "Urgent"];

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
    getMessagesList();
  }, []);

  const getMessagesList = async () => {
    try {
      const result = await services.getAdminWebsiteMessageInbox();
      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 201:
            if (result.data) {
              setMessageData(result.data);
            }
            if (result.data && result.data.website_messages_inbox) {
              setMessageInbox(result.data.website_messages_inbox);
            }
            getAdminInfo();
          case 200:
            setMessageData(result.data);
            setMessageInbox(result.data.website_messages_inbox);
            getAdminInfo();
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const getAdminInfo = async () => {
    try {
      const result = await services.getAdminInfo();
      log(result.data);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAdminList(result.data.adminsList);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const renderOptions = () => {
    return adminList.map((option) => (
      <Option key={option.admin_uid} value={option.admin_uid}>
        {option.given_name} {option.last_name}
      </Option>
    ));
  };

  const handelSaveInboxMessage = async () => {
    setTitleTextStatusError("");
    setAssignmentStatusError("");
    setNoteTextStatusError("");

    if (!selectedMessageIsRead) {
      toast.error("You can not change this message without switch it to Read");
      return;
    }

    if (adminTitle.trim() === "") {
      setTitleTextStatusError("error");
      return;
    }
    if (adminNote.trim() === "") {
      setNoteTextStatusError("error");
      return;
    }

    const asign = assignTo && assignTo.length > 0 ? true : false;
    if (asign) {
      if (assignmentReason.trim() === "") {
        setAssignmentStatusError("error");
        return;
      }
    }

    setLoading(true);
    try {
      const result = await services.adminWebsiteMessageChange(
        selectedMessage.message_uid,
        messageType,
        selectedMessageIsRead,
        selectedMessageIsOpen ? "Open" : "Closed",
        rateValue,
        categorySelect,
        subCategorySelect,
        adminTitle,
        adminNote,
        assignTo,
        assignmentReason
      );
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAssignmentReason("");
            setAdminTitle("");
            setAdminNote("");
            setCategorySelect("");
            setSubCategorySelect("");
            setSelectedMessage();
            getMessagesList();
            setAssignTo("");
            setRateValue(0);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const handleCategoryChange = (value) => {
    setCategorySelect(value);
  };

  const handleSubCategoryChange = (value) => {
    setSubCategorySelect(value);
  };

  const handleSelectInboxChange = (value) => {
    setSelectedMessage();
    setAssignMessageFilterValue("Show All Messages");
    setSearchInbox("");

    setRateFilterValue(0);
    switch (value) {
      case "My Inbox":
        setMessageType("inbox");
        if (messageData && messageData.website_messages_inbox) {
          setMessageInbox(messageData.website_messages_inbox);
        }
        break;
      case "I Assigned to..":
        setMessageType("assignment");
        if (messageData && messageData.website_messages_assignment) {
          setMessageInbox(messageData.website_messages_assignment);
        }
        break;
      case "Refer To Me":
        setMessageType("referal");
        if (messageData && messageData.website_messages_refer_to_me) {
          setMessageInbox(messageData.website_messages_refer_to_me);
        }
        break;
      default:
        break;
    }
  };

  const handleAssignmentAdminReadMessageFilter = (value) => {
    setAssignMessageFilterValue(value);
    setSelectedMessage();
    switch (value) {
      case "is Read":
        const isReadMessage = messageData.website_messages_assignment.filter(
          (message) => {
            return message.is_assigned_message_read === true;
          }
        );
        setMessageInbox(isReadMessage);
        break;
      case "Not Read":
        const notReadMessage = messageData.website_messages_assignment.filter(
          (message) => {
            return message.is_assigned_message_read === false;
          }
        );
        setMessageInbox(notReadMessage);
        break;
      case "Show All Messages":
        setMessageInbox(messageData.website_messages_assignment);
        break;
      default:
        break;
    }
  };

  const setRateChangeFilter = (value) => {
    setRateFilterValue(value);
    setSelectedMessage();
    if (messageType === "assignment") {
      if (value === 0) {
        setMessageInbox(messageData.website_messages_assignment);
      } else {
        const filterMessage = messageData.website_messages_assignment.filter(
          (message) => {
            return message.message_priority === value;
          }
        );
        setMessageInbox(filterMessage);
      }
    } else {
      if (value === 0) {
        setMessageInbox(messageData.website_messages_refer_to_me);
      } else {
        const filterMessage = messageData.website_messages_refer_to_me.filter(
          (message) => {
            return message.message_priority === value;
          }
        );
        setMessageInbox(filterMessage);
      }
    }
  };

  const handleSearchFilter = (value) => {
    setSearchInbox(value);
    setSelectedMessage();
    switch (messageType) {
      case "inbox":
        if (value.trim().length > 0) {
          const filterInbox = messageData.website_messages_inbox.filter(
            (message) =>
              (message.email_address &&
                message.email_address.includes(value)) ||
              (message.name && message.name.includes(value)) ||
              (message.message_body && message.message_body.includes(value))
          );
          setMessageInbox(filterInbox);
        } else {
          setMessageInbox(messageData.website_messages_inbox);
        }
        break;
      case "assignment":
        if (value.trim().length > 0) {
          const filterAssignment =
            messageData.website_messages_assignment.filter(
              (message) =>
                (message.email_address &&
                  message.email_address.includes(value)) ||
                (message.name && message.name.includes(value)) ||
                (message.message_body && message.message_body.includes(value))
            );
          setMessageInbox(filterAssignment);
        } else {
          setMessageInbox(messageData.website_messages_assignment);
        }
        break;
      case "referal":
        if (value.trim().length > 0) {
          const filterRefer = messageData.website_messages_refer_to_me.filter(
            (message) =>
              (message.email_address &&
                message.email_address.includes(value)) ||
              (message.name && message.name.includes(value)) ||
              (message.message_body && message.message_body.includes(value))
          );
          setMessageInbox(filterRefer);
        } else {
          setMessageInbox(messageData.website_messages_refer_to_me);
        }
        break;
      default:
        break;
    }
  };

  const handleAdminFindAdminName = (uid) => {
    const admin = adminList.find((admin) => admin.admin_uid === uid);
    return admin.given_name + " " + admin.last_name;
  };

  return (
    <div className="web-enquiries">
      <ToastContainer />
      {renderSpinner()}
      <div
        className="web-content"
        style={{ contain: "content", minHeight: "100vh" }}
      >
        <div className="sider-bar">
          <Row style={{ marginBottom: 20 }} gutter={[16, 16]}>
            <Input
              style={{ margin: 5 }}
              size="large"
              placeholder="Search Email, Name, Body..."
              prefix={<SearchOutlined />}
              value={searchInbox}
              onChange={(e) => handleSearchFilter(e.target.value)}
            />
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
            <Col span={24}>
              <Select
                defaultValue="My Inbox"
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                onChange={handleSelectInboxChange}
                options={[
                  {
                    value: "My Inbox",
                    label: "My Inbox",
                  },
                  {
                    value: "I Assigned to..",
                    label: "I Assigned to..",
                  },
                  {
                    value: "Refer To Me",
                    label: "Refer To Me",
                  },
                ]}
              />
            </Col>
            {messageType !== "inbox" && (
              <Col span={18}>
                <Space>
                  <Text>Filter by Rate:</Text>
                  <Rate
                    tooltips={rateDesc}
                    onChange={setRateChangeFilter}
                    value={rateFilterValue}
                  />
                </Space>
              </Col>
            )}
            {messageType === "assignment" && (
              <Col span={24}>
                <Segmented
                  options={["is Read", "Not Read", "Show All Messages"]}
                  value={assignMessageFilterValue}
                  onChange={handleAssignmentAdminReadMessageFilter}
                  style={{ width: "100%" }}
                />
              </Col>
            )}
          </Row>
          <Row>
            {messageInbox.map((message) => (
              <Card
                key={message.message_uid}
                style={{ width: "100%", margin: "10px 0" }}
                type="inner"
                title={message.name}
                extra={
                  <Space>
                    {messageType !== "assignment" && (
                      <Tooltip
                        title={message.is_read ? "is read" : "not read yet"}
                      >
                        {message.is_read ? <MailOutlined /> : <MailFilled />}
                      </Tooltip>
                    )}
                    {messageType === "assignment" && (
                      <Tooltip
                        title={
                          message.is_assigned_message_read
                            ? "Admin saw the message"
                            : "Admin not see yet"
                        }
                      >
                        {message.is_assigned_message_read ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </Tooltip>
                    )}
                  </Space>
                }
                className={selectedMessage === message ? "selected-card" : ""}
                onClick={() => {
                  setSelectedMessage(message);
                  setSelectedMessageIsRead(message.is_read);
                  setSelectedMessageIsOpen(
                    message.message_case_status === "Open" ? true : false
                  );
                  setCategorySelect(message.message_category);
                  setSubCategorySelect(message.message_sub_category);
                  setAdminTitle(message.admin_note_title);
                  setAdminNote(message.admin_note_text);
                  setUserCaseHistory(message.case_history);
                  setUserNoteHistory(message.admin_notes_history);
                }}
              >
                <Space direction="vertical" block="true">
                  <Text style={{ textAlign: "left" }}>
                    {helper.timestampToDateFull(message.message_timestamp)}
                  </Text>
                  <Text className="message-body">{message.message_body}</Text>
                  {messageType !== "inbox" &&
                    message.owner_admin_uid ===
                      message.assigned_to_new_admin_uid && (
                      <Tooltip title="You assign this message to yourself">
                        <span className="assign-wrapper">
                          <span className="assign-to-self">
                            <RedoOutlined style={{ color: "#FFF" }} />
                          </span>
                        </span>
                      </Tooltip>
                    )}
                </Space>
              </Card>
            ))}
          </Row>
        </div>
        <div className="content" style={{ textAlign: "left", marginTop: 50 }}>
          {selectedMessage && (
            <>
              <Space direction="vertical" style={{ display: "block" }}>
                <Text>{selectedMessage.name}</Text>
                <Text>{selectedMessage.email_address}</Text>
                <Text type="secondary">
                  {helper.timestampToDateFull(
                    selectedMessage.message_timestamp
                  )}
                </Text>
                {<Divider />}
                <Space direction="horizontal">
                  <Text>Case Status:</Text>
                  <Text
                    className="case-status"
                    style={{
                      background:
                        selectedMessage.message_case_status === "Open"
                          ? "#4096ff"
                          : "#52c41a",
                    }}
                  >
                    {selectedMessage.message_case_status}
                  </Text>
                  <Text className="modify-date">
                    {helper.timestampToDateFull(
                      selectedMessage.message_timestamp
                    )}
                  </Text>
                </Space>

                <Divider />
                <p
                  className="message-body"
                  style={{ marginTop: 50, whiteSpace: "pre-line" }}
                >
                  {selectedMessage.message_body}
                </p>

                <Space direction="horizontal" style={{ padding: "20px 0" }}>
                  {userCaseHistory && (
                    <Button
                      onClick={() => handleHistoryShow()}
                      icon={<CalendarOutlined />}
                    >
                      Case History
                    </Button>
                  )}
                  {userNoteHistory && (
                    <Button
                      onClick={() => handleNoteHistoryShow()}
                      icon={<CalendarOutlined />}
                    >
                      Notes History
                    </Button>
                  )}
                </Space>
              </Space>
              <Divider />
              <Row>
                <Col span={12}>
                  <Switch
                    checkedChildren="Read"
                    unCheckedChildren="Unread"
                    checked={selectedMessageIsRead}
                    onChange={() =>
                      setSelectedMessageIsRead(!selectedMessageIsRead)
                    }
                  />
                </Col>
                <Col span={12}>
                  <Switch
                    checkedChildren="Still Open"
                    unCheckedChildren="Close"
                    checked={selectedMessageIsOpen}
                    onChange={() =>
                      setSelectedMessageIsOpen(!selectedMessageIsOpen)
                    }
                  />
                </Col>
              </Row>
              <Row style={{ margin: "20px 0" }}>
                <Col>
                  <span>
                    <span className="ant-rate-text">Case Priority: </span>
                    <Rate
                      tooltips={rateDesc}
                      onChange={setRateValue}
                      value={rateValue}
                    />
                    {rateValue ? (
                      <span className="ant-rate-text">
                        {rateDesc[rateValue - 1]}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Space.Compact direction="vertical" block>
                    <Text>Category:</Text>
                    <Select
                      onChange={handleCategoryChange}
                      defaultValue=""
                      value={categorySelect}
                      style={{
                        width: "100%",
                      }}
                      placeholder="Category"
                      allowClear
                      options={[
                        {
                          value: "Business Customer",
                          label: "Business Customer",
                        },
                        {
                          value: "Individual Customer",
                          label: "Individual Customer",
                        },
                        {
                          value: "Bearers",
                          label: "Bearers",
                        },
                      ]}
                    />
                  </Space.Compact>
                </Col>
                <Col span={12}>
                  <Space.Compact direction="vertical" block>
                    <Text>Sub Category:</Text>
                    <Select
                      onChange={handleSubCategoryChange}
                      defaultValue=""
                      style={{
                        width: "100%",
                      }}
                      value={subCategorySelect}
                      allowClear
                      placeholder="Sub-category"
                      options={[
                        {
                          value: "Complain",
                          label: "Complain",
                        },
                        {
                          value: "Opportunity",
                          label: "Opportunity",
                        },
                        {
                          value: "Questions",
                          label: "Questions",
                        },
                      ]}
                    />
                  </Space.Compact>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Space.Compact direction="vertical" block>
                    <Text>Title:</Text>
                    <Input
                      status={titleTextStatusError}
                      placeholder="Title"
                      value={adminTitle}
                      onChange={(e) => setAdminTitle(e.target.value)}
                    />
                  </Space.Compact>
                </Col>
                <Col span={24}>
                  <Space.Compact direction="vertical" block>
                    <Text>Note Text:</Text>
                    <TextArea
                      status={noteTextStatusError}
                      rows={4}
                      placeholder="Note Text.."
                      value={adminNote}
                      onChange={(e) => setAdminNote(e.target.value)}
                    />
                  </Space.Compact>
                </Col>
              </Row>
              {selectedMessageIsOpen && (
                <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Space.Compact direction="vertical" block>
                      <Text>Assign To: </Text>
                      <Select
                        defaultValue=""
                        style={{
                          width: "100%",
                        }}
                        onChange={(value) => setAssignTo(value)}
                        placeholder="Assign To"
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {renderOptions()}
                      </Select>
                    </Space.Compact>
                  </Col>
                  <Col span={24}>
                    <Space.Compact direction="vertical" block>
                      <Text>Reason:</Text>
                      <TextArea
                        status={assignmentStatusError}
                        rows={4}
                        placeholder="Admin Select Reason"
                        value={assignmentReason}
                        onChange={(e) => setAssignmentReason(e.target.value)}
                      />
                    </Space.Compact>
                  </Col>
                </Row>
              )}
              <Row style={{ margin: "50px 0" }}>
                <Col span={5} offset={19}>
                  <Button
                    type="primary"
                    onClick={() => handelSaveInboxMessage()}
                    block
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
      {userCaseHistory && (
        <Modal
          show={historyShow}
          className="note-history-modal"
          onHide={handleHistoryClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Case Histroy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-design">
              <table className="table">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(userCaseHistory)
                    .sort(([a], [b]) => b - a)
                    .map(([key, value]) => (
                      <tr key={value.message_case_update_timestmap}>
                        <td style={{ paddingLeft: 10 }}>
                          {value.message_case_status}
                        </td>
                        <td>{value.new_assignment_reason}</td>
                        <td>
                          {timestampToDateFull(
                            value.message_case_update_timestmap
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {userNoteHistory && (
        <Modal
          show={noteHistoryShow}
          className="note-history-modal"
          onHide={handleNoteHistoryClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Note Histroy</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-design">
              <table className="table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Message</th>
                    <th>Time</th>
                    <th>Admin Name</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(userNoteHistory)
                    .sort(([a], [b]) => b - a)
                    .map(([key, value]) => (
                      <tr key={key}>
                        <td style={{ paddingLeft: 10 }}>{value.note_title}</td>
                        <td>{value.note_text}</td>
                        <td>
                          {timestampToDateFull(
                            value.message_case_update_timestmap
                          )}
                        </td>
                        <td>
                          {handleAdminFindAdminName(value.drafted_by_admin_uid)}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default WebEnquiries;
