import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";
import { timestampToDateFull } from "../../helper/helper.jsx";
import HistorySingleComponents from "../parcel/parcelHistorySingleComponents";

function MotorbikeHistorySingle(props) {
  const [time] = useState(
    props.location.state ? props.location.state.key : null
  );
  const [value] = useState(
    props.location.state ? props.location.state.value : null
  );
  log(value);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
    if (value === null) {
      return props.history.replace("/home");
    }
  }, []);

  const handleHistoryValue = () => {
    if (value !== null) {
      return (
        <div>
          <HistorySingleComponents
            header="Admin UID"
            value={value.admin_uid}
            isChanged={false}
          />
        </div>
      );
    }
  };

  return <div className="history-single">{handleHistoryValue()}</div>;
}

export default MotorbikeHistorySingle;
