import React, { useEffect, useState } from "react";
import AllowedByBearer from "../findCustomer/components/allowedByBearer";
import Note from "../findCustomer/components/note";
import DatePicker from "react-datepicker";
import UserProfile from "../findCustomer/components/userProfile";
import "../findCustomer/findCustomer.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import RiderStatus from "./components/riderStatus";
import "./findRider.scss";
import RiderBearerInfo from "./components/riderBearerInfo";
import RateAverage from "./components/rateAverage";
import RiderIsOnJob from "./components/riderIsOnJob";
import BonusPercentage from "./components/bonusPercentage";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as service from "../../services/backendServices";
import * as riderService from "./findRiderService";
import { Link } from "react-router-dom";
import RiderSignStatus from "./components/riderSignStatus";
import LatestActivity from "../findCustomer/components/latestActivity";
import { log } from "../../helper/helper";
import Moment from "moment";
import { Button, Modal } from "react-bootstrap";

function RiderSingle(props) {
  const state = props.history.location.state
    ? props.history.location.state
    : null;
  const [rider, setRider] = useState();
  const [isLoading, setLoading] = useState(false);
  const [startData, setStartDate] = useState();
  const [showTimePicker, setShowTimePicker] = useState(false);
  const currentdate = new Date();
  const maxdate = new Date(
    currentdate.setYear(currentdate.getFullYear() + 100)
  );
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (state === null) {
      return props.history.replace("/");
    }

    getRiderInfoByID();
  }, []);

  const getRiderInfoByID = async () => {
    setLoading(true);
    try {
      const result = await riderService.adminGetRiderInfo(state.riderId);
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            setRider(result.data.rider);
            break;
          case 401:
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
      props.history.goBack();
      setLoading(false);
    }
  };

  const adminShowAllTransports = async () => {
    setLoading(true);
    try {
      const result = await service.adminShowAllTransports(
        "rider",
        rider.rider_uid,
        ""
      );
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            if (result.data.cursor_transport_id) {
              return props.history.push("/home/showAllTransports", {
                transportList: result.data.transportList,
                userType: "rider",
                uid: rider.rider_uid,
                cursorTransportId: result.data.cursor_transport_id,
              });
            } else {
              return props.history.push("/home/showAllTransports", {
                transportList: result.data.transportList,
                userType: "rider",
                uid: rider.rider_uid,
              });
            }

          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminShowActiveTransport = async () => {
    setLoading(true);
    try {
      const result = await service.adminShowActiveTransport(
        "rider",
        rider.rider_uid
      );
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            return props.history.push("/home/showActiveTransports", {
              transportList: result.data.transportList,
              userType: "rider",
            });
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminGetModesOfTransport = async () => {
    setLoading(true);
    try {
      const result = await service.adminGetModesOfTransport(rider.rider_uid);
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            return props.history.push("/home/modesOfTransport", {
              transportModes: result.data,
            });
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const handelSearchRiderTime = async () => {
    const selectedDate = Moment(startData).format("YYYY-MM-DD");
    setLoading(true);
    try {
      const result = await riderService.adminRiderGetOnlineTime(
        rider.rider_uid,
        selectedDate
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            const filteredData = result.data.time.filter(
              (item) => item !== null
            );
            const min = filteredData.map((item) =>
              Math.round((item.counter * 90) / 60)
            );
            const totalMin = min.reduce((acc, cur) => acc + cur, 0);

            setShowTimePicker(false);
            log(result.data);
            return props.history.push(
              "/home/findRider/riderList/riderSingle/onlineTimesheet",
              {
                times: result.data,
                total: totalMin,
                selectedDate: Moment(startData).format("YYYY-MM-DD"),
                riderName: rider.given_name + " " + rider.last_name,
                mobile: rider.mobile_number,
              }
            );
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="search-single">
      <ToastContainer />
      {renderSpinner()}
      {rider ? (
        <div className="rider-single-body">
          <div className="user-section">
            <RiderSignStatus user={rider ? rider : null} />
          </div>
          <div className="user-info">
            <UserProfile label="Rider" user={rider ? rider : null} />
          </div>
          <div className="rider-status">
            <RiderStatus user={rider ? rider : null} props={props} />
          </div>
          <div className="user-bearer-info">
            <RiderBearerInfo user={rider ? rider : null} />
          </div>
          <div className="rider-rate-average">
            <RateAverage user={rider ? rider : null} />
          </div>
          <div className="user-bearer-info">
            <LatestActivity
              user={rider ? rider : null}
              isCustomer={false}
              props={props}
              handleReloadSingle={() => getRiderInfoByID()}
            />
          </div>
          <div className="balance row-style">
            <div className="row">
              <div className="col-md-4">
                <h5>Balance</h5>
              </div>
              <div className="col-md-4">
                <div className="value"></div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          {rider.ncc && rider.ncc.latest_result && (
            <div className="rider-ncc row-style">
              <div className="row">
                <div className="col-md-4">
                  <h5>NCC</h5>
                </div>
                <div className="col-md-4">
                  <span
                    className={
                      rider.ncc.latest_result === "NDCO"
                        ? "rider-ncc-green"
                        : "rider-ncc-red"
                    }
                  >
                    {rider.ncc.latest_result}
                  </span>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          )}
          <div className="row-style">
            <div className="row">
              <div className="col-md-4">
                <h5>Online Time Calculator</h5>
              </div>
              <div className="col-md-4">
                <div className="value"></div>
              </div>
              <div className="col-md-4">
                <Button
                  variant="primary"
                  className="edit-btn"
                  onClick={() => {
                    setShowTimePicker(true);
                  }}
                >
                  View
                </Button>
                <Modal
                  show={showTimePicker}
                  className="online-time-calculator-time-selector"
                  onHide={() => setShowTimePicker(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h2>Select Time</h2>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className="form-group"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <DatePicker
                        selected={startData}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        maxDate={maxdate}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => handelSearchRiderTime()}
                    >
                      Search
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
          <div className="note">
            <Note
              user={rider ? rider : null}
              props={props}
              handleReloadSingle={() => getRiderInfoByID()}
            />
          </div>
          <div className="allowed-by-bearer">
            <AllowedByBearer
              user={rider ? rider : null}
              props={props}
              handleReloadSingle={() => getRiderInfoByID()}
            />
          </div>
          {rider && (rider.is_on_job === true || rider.is_on_job === false) ? (
            <div className="rider-is-on-job">
              <RiderIsOnJob
                user={rider ? rider : null}
                props={props}
                handleReloadSingle={() => getRiderInfoByID()}
              />
            </div>
          ) : null}

          <div className="bonus-percentage">
            <BonusPercentage
              user={rider ? rider : ""}
              props={props}
              handleReloadSingle={() => getRiderInfoByID()}
            />
          </div>
          {rider ? (
            <div className="group-btn">
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: "/home/sendSMS",
                      phone: rider.mobile_number,
                      userType: "Rider",
                    }}
                    className="single-view"
                  >
                    Send SMS
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/home/sendEmail",
                      email: rider.email_address,
                      userType: "Rider",
                    }}
                    className="single-view"
                  >
                    Send Email
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: "/home/notification",
                      uid: rider.rider_uid,
                      userType: "rider",
                    }}
                    className="single-view"
                  >
                    Send Push Notif
                  </Link>
                </li>
                <li>
                  <button disabled to="/">
                    Create Bouns
                  </button>
                </li>
                <li>
                  <button disabled to="/">
                    Stripe
                  </button>
                </li>
                <li>
                  <button onClick={adminGetModesOfTransport}>
                    Modes of Transport
                  </button>
                </li>
                <li>
                  <button onClick={adminShowAllTransports}>
                    Show All Transport
                  </button>
                </li>
                <li>
                  <button onClick={adminShowActiveTransport}>
                    Show Active Transport
                  </button>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
export default RiderSingle;
