import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as service from "../findCustomerService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function CrmTag({ user, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => {
    setCrmNoteBtnDisable(true);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [crmBtnDisable, setCrmNoteBtnDisable] = useState(true);
  const [crmSelection, setCrmSelection] = useState(0);
  const [crmServer, setCrmServer] = useState();

  const setCRMDefault = () => {
    switch (user ? user.crm_tag : "") {
      case "Normal":
        setCrmSelection(0);
        setCrmServer(0);
        break;
      case "Recently Signed Up":
        setCrmSelection(1);
        setCrmServer(1);
        break;
      case "Retained User":
        setCrmSelection(2);
        setCrmServer(2);
        break;
      case "Unhappy":
        setCrmSelection(3);
        setCrmServer(3);
        break;
      default:
        break;
    }
  };

  const setCrmData = (value) => {
    log(crmServer);
    setCrmSelection(value);
    if (value !== crmServer) {
      setCrmNoteBtnDisable(false);
    } else {
      setCrmNoteBtnDisable(true);
    }
  };

  const changeCRMTag = async () => {
    handleClose(true);
    setLoading(true);
    let crmFinalChoose = "";

    switch (crmSelection) {
      case 0:
        crmFinalChoose = "Normal";
        break;
      case 1:
        crmFinalChoose = "Recently Signed Up";
        break;
      case 2:
        crmFinalChoose = "Retained User";
        break;
      case 3:
        crmFinalChoose = "Unhappy";
        break;
      default:
        break;
    }

    try {
      const result = await service.adminCustomerChangeCRM(
        user.customer_uid,
        crmFinalChoose
      );
      log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setCrmServer(crmSelection);
            handleReloadSingle();
            setLoading(false);
            return;
          case 401:
            setLoading(false);
            return props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>CRM Tag</h5>
      </div>
      <div className="col-md-4">
        <div className="value">{user ? user.crm_tag : ""}</div>
      </div>
      <div className="col-md-4">
        <Button
          variant="primary"
          className="edit-btn"
          onClick={() => {
            handleShow();
            setCRMDefault();
          }}
        >
          Edit
        </Button>
        <Modal
          show={show}
          className="crm-modal modal-popup-custom"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>CRM tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-radio-wrapper">
              <input
                type="radio"
                value={crmSelection === 0}
                name="crmSelection"
                checked={crmSelection === 0}
                onChange={() => setCrmData(0)}
              />{" "}
              <label htmlFor="">Normal</label>
              <br />
              <input
                type="radio"
                value={crmSelection === 1}
                name="crmSelection"
                checked={crmSelection === 1}
                onChange={() => setCrmData(1)}
              />{" "}
              <label htmlFor="">Recently Signed Up</label>
              <br />
              <input
                type="radio"
                value={crmSelection === 2}
                name="crmSelection"
                checked={crmSelection === 2}
                onChange={() => setCrmData(2)}
              />{" "}
              <label htmlFor="">Retained User</label>
              <br />
              <input
                type="radio"
                value={crmSelection === 3}
                name="crmSelection"
                checked={crmSelection === 3}
                onChange={() => setCrmData(3)}
              />{" "}
              <label htmlFor="">Unhappy</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={crmBtnDisable}
              onClick={changeCRMTag}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default CrmTag;
