import React from "react";
import * as utils from "../../../helper/helper";

function TransportTimeLine({ transport }) {
  let lastTime = 0;
  const handleTimeStamp = (itemTime) => {
    if (lastTime !== 0) {
      const newTime = itemTime - lastTime;
      var min = newTime / 1000 / 60;
      var r = min % 1;
      var sec = Math.floor(r * 60);
      if (sec < 10) {
        sec = "0" + sec;
      }
      min = Math.floor(min);
      lastTime = itemTime;
      return <div className="timeline-line">{min + ":" + sec}</div>;
    } else {
      lastTime = itemTime;
    }
  };

  const handleTitle = (title) => {
    switch (title) {
      case "event_time_created_by_customer_in_ms":
        return "Order Created";
      case "event_time_created_processing_payment_in_ms":
        return "Processing Payment";
      case "event_time_created_payment_authorised_in_ms":
        return "Payment Authorised";
      case "event_time_search_first_attemp_in_ms":
        return "First Search";
      case "event_time_search_second_attemp_in_ms":
        return "Second Search";
      case "event_time_accepted_rider_ontheway_to_origin_in_ms":
        return "Rider Accepted";
      case "event_time_accepted_canceled_by_customer_after_accept_in_ms":
        return "Cancelled by the Customer";
      case "event_time_accepted_canceled_by_rider_after_accept_in_ms":
        return "Cancelled by the Bearer";
      case "event_time_accepted_rider_arrived_to_origin_in_ms":
        return "Bearer Arrived to Origin";
      case "event_time_active_parcel_picked_up_in_ms":
        return "Parcel Picked Up";
      case "event_time_active_rider_arrived_to_destination_in_ms":
        return "Bearer Arrived to Destination";
      case "event_time_active_canceled_by_customer_during_delivery_in_ms":
        return "Cancelation requested by the Customer";
      case "event_time_active_canceled_by_rider_during_delivery_in_ms":
        return "Cancelation requested by the Bearer";
      case "event_time_active_parcel_dropped_off_at_destination_in_ms":
        return "Parcel Dropped Off";
      case "event_time_active_completion_confirmed_by_rider_in_ms":
        return "Bearer Confirms Job Completion";
      case "event_time_completed_on_original_details_in_ms":
        return "Order Closed";
      default:
        break;
    }
  };

  const handleTransportLastStepTime = (item) => {
    const firstStep = transport.event_time[0].time;
    let lastStep = 0;

    if (
      transport.event_time[transport.event_time.length - 1].title ===
      "event_time_completed_on_original_details_in_ms"
    ) {
      lastStep = transport.event_time[transport.event_time.length - 2].time;
    } else {
      lastStep = transport.event_time[transport.event_time.length - 1].time;
    }

    const newTime = lastStep - firstStep;
    var min = newTime / 1000 / 60;
    var r = min % 1;
    var sec = Math.floor(r * 60);
    if (sec < 10) {
      sec = "0" + sec;
    }
    min = Math.floor(min);

    if (
      transport.event_time[transport.event_time.length - 1].title ===
      "event_time_completed_on_original_details_in_ms"
    ) {
      if (
        item.time === transport.event_time[transport.event_time.length - 2].time
      ) {
        return min + ":" + sec;
      } else {
        return "";
      }
    } else {
      if (
        item.time === transport.event_time[transport.event_time.length - 1].time
      ) {
        return min + ":" + sec;
      } else {
        return "";
      }
    }
  };
  return (
    <div style={{ textAlign: "center" }}>
      <div className="row">
        <div className="timeline-itam-wrapper">
          {transport && transport.event_time
            ? transport.event_time.map((item) => (
                <div key={item.title}>
                  {item.time > 0 ? (
                    <div className="timeline-item-info">
                      {handleTimeStamp(item.time)}
                      <div className="timeline-transport-info">
                        <span className="timeline-transport-last-step">
                          {handleTransportLastStepTime(item)}
                        </span>
                        <span className="timeline-time">
                          {item.time
                            ? utils.timestampToDateTime(item.time)
                            : null}
                        </span>
                        <span className="timeline-name">
                          {handleTitle(item.title)}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))
            : null}
        </div>
      </div>
    </div>

    // <div className="row">
    //   <div className="timeline-itam-wrapper">
    //     {transport && transport.event_time_created_by_customer_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_created_by_customer_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Order Created</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport && transport.event_time_created_processing_payment_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_created_processing_payment_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Processing Payment</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport && transport.event_time_created_payment_authorised_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_created_payment_authorised_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Payment Authorised</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport && transport.event_time_search_first_attemp_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_search_first_attemp_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">First Search</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport && transport.event_time_search_second_attemp_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_search_second_attemp_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Second Search</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_accepted_rider_ontheway_to_origin_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_accepted_rider_ontheway_to_origin_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Rider Accepted</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_accepted_canceled_by_customer_after_accept_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_accepted_canceled_by_customer_after_accept_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Cancelled by the Customer</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_accepted_canceled_by_rider_after_accept_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_accepted_canceled_by_rider_after_accept_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Cancelled by the Bearer</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_accepted_rider_arrived_to_origin_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_accepted_rider_arrived_to_origin_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Bearer Arrived to Origin</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport && transport.event_time_active_parcel_picked_up_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_active_parcel_picked_up_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Parcel Picked Up</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_active_rider_arrived_to_destination_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_active_rider_arrived_to_destination_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">
    //             Bearer Arrived to Destination
    //           </span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_active_canceled_by_customer_during_delivery_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_active_canceled_by_customer_during_delivery_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">
    //             Cancelation requested by the Customer
    //           </span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_active_canceled_by_rider_during_delivery_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_active_canceled_by_rider_during_delivery_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">
    //             Cancelation requested by the Bearer
    //           </span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_active_parcel_dropped_off_at_destination_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_active_parcel_dropped_off_at_destination_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Parcel Dropped Off</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_active_completion_confirmed_by_rider_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_active_completion_confirmed_by_rider_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">
    //             Bearer Confirms Job Completion
    //           </span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //     {transport &&
    //     transport.event_time_completed_on_original_details_in_ms ? (
    //       <div className="timeline-item-info">
    //         <div className="timeline-transport-info">
    //           <span className="timeline-time">
    //             {utils.timestampToDateTime(
    //               transport.event_time_completed_on_original_details_in_ms
    //             )}
    //           </span>
    //           <span className="timeline-name">Order Closed</span>
    //         </div>
    //         <div className="timeline-line"></div>
    //       </div>
    //     ) : null}
    //   </div>
    // </div>
  );
}

export default TransportTimeLine;
