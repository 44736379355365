import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function RiderList(props) {
  const [riders, setRider] = useState([]);
  const auth = getAuth();
  useEffect(() => {
    if (props.history.location.state) {
      setRider(props.history.location.state.riders);
    } else {
      return props.history.push("/home/findRider");
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
  });

  const getSinglePage = (rider) => {
    if (rider.is_sign_up_completed) {
      props.history.push("/home/findRider/riderList/riderSingle", {
        riderId: rider.rider_uid,
      });
    } else {
      props.history.push("/home/findRider/riderList/uncompletedRider", {
        riderId: rider.rider_uid,
      });
    }
  };

  const handleSignupStatus = (rider) => {
    if (rider.is_sign_up_completed) {
      switch (rider.sign_up_documents_review_result) {
        case "Approved":
          return (
            <span className="rider-signup-status signup-status-approved">
              Approved
            </span>
          );
        case "Rejected":
          return (
            <span className="rider-signup-status signup-status-rejected">
              Rejected
            </span>
          );
        case "Processing":
          return (
            <span className="rider-signup-status signup-status-processing">
              Processing
            </span>
          );
        case "Further Investigation":
          return (
            <span className="rider-signup-status signup-status-investigation">
              Further Investigation
            </span>
          );
        default:
          break;
      }
    } else {
      return (
        <span className="rider-signup-status signup-status-purple">
          Incomplete
        </span>
      );
    }
  };

  const dataResult = props.history.location.state
    ? props.history.location.state
    : "";

  const handleRidersName = (name, lastName) => {
    let n = name !== "" ? name : "-";
    let ln = lastName !== "" ? lastName : "-";
    return n + " " + ln;
  };
  return (
    <div className="search-list">
      <h6>
        Found {riders.length} {riders.length > 1 ? "result" : "results"} for :{" "}
        {dataResult !== "" ? dataResult.inputString : ""}
      </h6>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>NCC</th>
            <th>User Status</th>
            <th>Signup Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {riders.map((rider) => (
            <tr key={rider.rider_uid}>
              <td>{handleRidersName(rider.given_name, rider.last_name)}</td>
              <td>{rider.email_address}</td>
              <td>{rider.mobile_number}</td>
              <td>
                {rider.ncc && rider.ncc.latest_result ? (
                  <span
                    className={
                      rider.ncc.latest_result === "NDCO"
                        ? "rider-ncc-green"
                        : "rider-ncc-red"
                    }
                  >
                    {rider.ncc.latest_result}
                  </span>
                ) : null}
              </td>
              {rider.is_account_deleted ? (
                <td className="deleted">
                  <span>Deleted</span>
                </td>
              ) : (
                <td className="active">
                  <span>Active</span>
                </td>
              )}
              <td>{handleSignupStatus(rider)}</td>
              <td>
                <button
                  className="search-view"
                  onClick={() => getSinglePage(rider)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RiderList;
