import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./sendSMS.scss";
import Input from "../common/input";
import Select from "../common/select";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as service from "./sendSMSServices";
import { log } from "../../helper/helper";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function SendSMS(props) {
  const [mobileNumber, setMobileNumber] = useState(
    props.history.location.phone ? props.history.location.phone : ""
  );
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isMobileNumberEnable, setIsMobileNumberEnable] = useState(false);
  const [targetSelected, setTargetSelected] = useState(
    "Missed call: Discover the benefits of becoming a Bearer Application"
  );
  const [userTypeSelected, setUserTypeSelected] = useState(
    props.history.location.userType &&
      props.history.location.userType === "Rider"
      ? "Rider"
      : "Customer"
  );
  const [isSelectedUserTypeDisable, setIsSelectedUserTypeDisable] =
    useState(false);

  const [target, setTarget] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
    if (mobileNumber !== "") {
      setIsMobileNumberEnable(true);
      if (mobileNumber.slice(0, 4) !== "+614") {
        setMobileNumberError("Enter a valid Mobile number starting +614...");
      }
      setIsSelectedUserTypeDisable(true);
      if (props.history.location.userType === "Rider") {
        handleUserTypeSelection("Rider");
      }
    }
  }, []);

  const handleUserTypeSelection = (value) => {
    setUserTypeSelected(value);
    if (value == "Rider") {
      setTarget([
        {
          id: "0",
          name: "Missed call: Discover the benefits of becoming a Bearer Application",
          serverValue: "get_to_know_bearer_for_bearers",
        },
        {
          id: "1",
          name: "First Message: Start a new Bearer Application",
          serverValue: "start_a_bearer_application",
        },
        {
          id: "2",
          name: "Reminder: Complete an existing Bearer Application",
          serverValue: "continue_a_bearer_application_reminder",
        },
        {
          id: "3",
          name: " Check the Documents’ Portal",
          serverValue: "check_the_documents_portal_reminder",
        },
        // {
        //   id: "4",
        //   name: "Free NCC Promotion",
        //   serverValue: "free_ncc_checks_offer_announcement",
        // },
        // {
        //   id: "4",
        //   name: "Go Online Stay Online Promotion",
        //   serverValue: "stay-online-promotion-announcement",
        // },
      ]);
    } else {
      setTarget([]);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleSetMobile = (value) => {
    if (value.length < 13) {
      const result = value.replace(/\D/g, "");
      if (value.length < 4) {
        setMobileNumber("+614");
      } else if (result.length < 12) {
        setMobileNumber(`+${result}`);
      }
      if (result.length === 11) {
        setMobileNumberError("");
      } else {
        setMobileNumberError("12 Digits (Beginning with a +614)");
      }

      if (mobileNumber.slice(0, 4) !== "+614") {
        setMobileNumberError("Enter a valid Mobile number starting +614...");
      }
    }
  };

  const sendSMS = async () => {
    if (mobileNumber.length === 0 || mobileNumberError !== "") {
      setMobileNumberError("Enter a valid Mobile number starting +614...");
      return;
    }
    let templateName = target.find((o) => o.name === targetSelected);

    setLoading(true);
    try {
      const result = await service.adminSendSMS(
        mobileNumber,
        templateName.serverValue
      );

      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setLoading(false);
            return;
          case 401:
            setLoading(false);
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  let userTypeSelect = [
    {
      id: "0",
      name: "Customer",
    },
    {
      id: "1",
      name: "Rider",
    },
  ];

  return (
    <div className="admin-send-sms">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-12">
          <Select
            name="userType"
            label="User Type"
            options={userTypeSelect}
            onChange={(e) => handleUserTypeSelection(e.target.value)}
            disabled={false}
            disableSelect={isSelectedUserTypeDisable}
            value={userTypeSelected}
          />
        </div>
        <div className="col-md-12">
          <Select
            name="target"
            label="Template name"
            options={target}
            onChange={(e) => setTargetSelected(e.target.value)}
            disabled={false}
            disableSelect={false}
          />
        </div>
        <div className="col-md-12">
          <Input
            label="Mobile number:"
            type="String"
            name="title"
            value={mobileNumber}
            onChange={(e) => handleSetMobile(e.target.value)}
            error={mobileNumberError}
            disabled={isMobileNumberEnable}
          />
        </div>
      </div>
      {target.length > 0 && (
        <div className="row mg-custom">
          <div className="col-md-12">
            <Button className="sms-send" onClick={sendSMS}>
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SendSMS;
