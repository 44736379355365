import React, { useEffect, useState } from "react";
import "../global/global.scss";
import * as service from "./couponsServices";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { Link } from "react-router-dom";
import "./coupons.scss";

import Select from "../common/select";
import Input from "../common/input";
import { log } from "../../helper/helper";

function CouponList(props) {
  const [isLoading, setLoading] = useState(true);
  const [isBtnShow, setBtnShow] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [couponsFilter, setCouponsFilter] = useState([]);
  const [cursorTransportId, setCursorTransportId] = useState("");
  const [targetSelected, setTargetSelected] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        loadCoupons();
      } else {
        props.history.replace("/login");
      }
    });
  }, []);

  let target = [
    { id: "1", name: "Name Tag" },
    { id: "2", name: "Percent Off" },
    { id: "3", name: "User Type" },
    { id: "4", name: "Allowed User ID" },
  ];

  const loadCoupons = async () => {
    try {
      const result = await service.adminShowAllCoupons(cursorTransportId);
      setLoading(false);
      log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            setCoupons(result.data.couponsList);
            setCouponsFilter(result.data.couponsList);

            if (result.data.cursor_coupon_id) {
              setCursorTransportId(result.data.cursor_coupon_id);
              setBtnShow(true);
            } else {
              setCursorTransportId("");
              setBtnShow(false);
            }
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      setLoading(false);
      toast.error(error.message);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const showLoadMoreBtn = () => {
    if (isBtnShow) {
      <div className="load-more">
        <button onClick={() => loadCoupons()}>Load More</button>
      </div>;
    }
  };

  const handleFilterData = (value) => {
    setFilter(value);
    if (value !== "") {
      switch (targetSelected) {
        case "Name Tag":
          const name = coupons.filter(
            (li) => li.name_tag.indexOf(value) !== -1
          );
          setCouponsFilter(name);
          break;
        case "Percent Off":
          const percent = coupons.filter(
            (li) => li.percent_off.indexOf(value) !== -1
          );
          setCouponsFilter(percent);
          break;
        case "User Type":
          const type = coupons.filter(
            (li) => li.allowed_user_type.indexOf(value) !== -1
          );
          setCouponsFilter(type);
          break;
        case "Allowed User ID":
          const id = coupons.filter(
            (li) => li.allowed_user_id.indexOf(value) !== -1
          );
          setCouponsFilter(id);
          break;
        default:
          break;
      }
    } else {
      setCouponsFilter(coupons);
    }
  };

  return (
    <div className="show-all-list coupons">
      <ToastContainer />
      {renderSpinner()}
      <div className="header">
        <div className="headline">
          <h3>Show All Coupons</h3>
        </div>
        <span className="add-new-coupons">
          <Link to={"/home/couponList/couponSingle"} className="single-view">
            Create New
          </Link>
        </span>
      </div>
      <div className="filter-section">
        <div className="row">
          <div className="col-md-6">
            <Select
              name="target"
              options={target}
              onChange={(e) => {
                setTargetSelected(e.target.value);
                setFilter("");
                setCouponsFilter(coupons);
              }}
              disabled={false}
              defaultValue="Select your Option"
              disabledDefaultValue={false}
              disableSelect={false}
            />
          </div>
          <div className="col-md-6">
            <Input
              // label="Notification Message:"
              placeHolder="filter list"
              type="String"
              name="message"
              value={filter}
              onChange={(e) => handleFilterData(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name Tag</th>
            <th>Description</th>
            <th>Allowed User ID</th>
            <th>Allowed User Type</th>
            <th>Coupon Type</th>
            <th>Percent Off</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {couponsFilter.map((coupon) => (
            <tr key={coupon.coupon_id}>
              <td>{coupon.name_tag}</td>
              <td>{coupon.description}</td>
              <td>{coupon.allowed_user_id}</td>
              <td>{coupon.allowed_user_type}</td>
              <td>{coupon.coupon_type}</td>
              <td>{coupon.percent_off}</td>
              <td>
                <button
                  onClick={() => {
                    props.history.push("/home/couponList/couponSingle", {
                      coupon: coupon,
                    });
                  }}
                  className="single-view"
                >
                  View
                </button>
              </td>
              <td>
                {coupon.coupon_history !== null ? (
                  <button
                    onClick={() => {
                      props.history.push("/home/couponList/history", {
                        history: coupon.coupon_history,
                      });
                    }}
                    className="single-view"
                  >
                    History
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showLoadMoreBtn()}
    </div>
  );
}

export default CouponList;
