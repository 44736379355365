import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import "./assignmentTransports.scss";
import * as service from "./assignmentTransportsService";
import {
  timestampToDateYearFormat,
  timestampToDateTimeFormat,
  log,
} from "../../helper/helper";

import ridingImage from "../../assets/riding.png";
import walkingImage from "../../assets/walking.png";
import cyclingImage from "../../assets/cycling.png";

function AssignmentTransports(props) {
  const [transports, setTransports] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [cursorTransportId, setCursorTransportId] = useState("");
  const [isHidden, setBtnHidden] = useState(false);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
      adminShowAllTransports();
    });
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const adminShowAllTransports = async () => {
    setLoading(true);
    try {
      const result = await service.adminShowAllAssignmentTransports(
        cursorTransportId
      );
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            const list = [...transports, ...result.data.transportList];
            setTransports(list);
            if (result.data.cursor_transport_id) {
              setCursorTransportId(result.data.cursor_transport_id);
            } else {
              setCursorTransportId("");
              setBtnHidden(true);
            }
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleTransportDate = (time) => {
    return timestampToDateYearFormat(time._seconds, time._nanoseconds);
  };

  const handleTransportTime = (time) => {
    return timestampToDateTimeFormat(time._seconds, time._nanoseconds);
  };

  const handleBearerType = (bearer) => {
    switch (bearer) {
      case "riding":
        return (
          <div className="bearer">
            <img src={ridingImage} alt="rider" />
          </div>
        );
      case "cycling":
        return (
          <div className="bearer">
            <img src={cyclingImage} alt="cycle" />
          </div>
        );
      case "walking":
        return (
          <div className="bearer">
            <img src={walkingImage} alt="walk" />
          </div>
        );

      default:
        break;
    }
  };

  const handleTransportStatus = (status) => {
    switch (status) {
      case "Canceled by Customer":
        return (
          <div className="deleted">
            <span>Canceled by Customer</span>{" "}
          </div>
        );
      case "Canceled by Rider":
        return (
          <div className="rider-deleted">
            <span>Canceled by Rider</span>{" "}
          </div>
        );
      case "Completed":
        return (
          <div className="active mar0">
            <span>Completed</span>{" "}
          </div>
        );
      default:
        return <span>{status}</span>;
    }
  };

  return (
    <div className="show-all-list">
      <ToastContainer />
      {renderSpinner()}
      <table className="table">
        <thead>
          <tr>
            <th>Date Time</th>
            <th>Rider Name</th>
            <th>Customer Name</th>
            <th>Bearer Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transports.map((transport) => (
            <tr key={transport.transport_id}>
              <td>
                {handleTransportDate(transport.time)}
                <br />
                {handleTransportTime(transport.time)}
              </td>
              <td>
                {transport.rider_given_name + " " + transport.rider_last_name}
              </td>
              <td>
                {transport.customer_given_name +
                  " " +
                  transport.customer_last_name}
              </td>
              <td>{handleBearerType(transport.bearer_type)}</td>
              <td>
                <button
                  onClick={() =>
                    props.history.push("/home/transportSingle", {
                      transportId: transport.transport_id,
                    })
                  }
                  className="single-view"
                >
                  Transport Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default AssignmentTransports;
