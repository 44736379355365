import React, { useEffect, useState } from "react";
import AllowedByBearer from "./components/allowedByBearer";
import CrmTag from "./components/crmTag";
import CustomerBearerInfo from "./components/customerBearerInfo";
import LatestActivity from "./components/latestActivity";
import LoyaltyTier from "./components/loyaltyTier";
import Note from "./components/note";
import OrderLimit from "./components/orderLimit";
import UserProfile from "./components/userProfile";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as service from "../../services/backendServices";
import { Link } from "react-router-dom";
import * as customerService from "./findCustomerService";
import { log } from "../../helper/helper";

function CustomerSingle(props) {
  const state = props.history.location.state
    ? props.history.location.state
    : null;
  const [customer, setCustomer] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (state === null) {
      return props.history.replace("/");
    }
    getCustomerByID();
  }, []);

  const getCustomerByID = async () => {
    setLoading(true);
    try {
      const result = await customerService.adminGetCustomerInfo(
        state.customerId
      );
      setLoading(false);
      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 200:
            return setCustomer(result.data.customer);
          case 401:
            return props.history.replace("/login");
          case 500:
            return props.history.replace("/");
          default:
            return props.history.replace("/");
        }
      }
    } catch (error) {
      log(error);
    }
    setLoading(false);
  };

  const adminShowActiveTransport = async () => {
    setLoading(true);
    try {
      const result = await service.adminShowActiveTransport(
        "customer",
        customer.customer_uid
      );
      setLoading(false);
      log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            return props.history.push("/home/showActiveTransports", {
              transportList: result.data.transportList,
              userType: "customer",
              uid: customer.customer_uid,
              cursorTransportId: result.data.cursor_transport_id,
            });
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminShowAllTransports = async () => {
    setLoading(true);
    try {
      const result = await service.adminShowAllTransports(
        "customer",
        customer.customer_uid,
        ""
      );
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            if (result.data.cursor_transport_id) {
              return props.history.push("/home/showAllTransports", {
                transportList: result.data.transportList,
                userType: "customer",
                uid: customer.customer_uid,
                cursorTransportId: result.data.cursor_transport_id,
              });
            } else {
              return props.history.push("/home/showAllTransports", {
                transportList: result.data.transportList,
                userType: "customer",
                uid: customer.customer_uid,
              });
            }

          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const adminCreateNewCoupon = () => {
    props.history.push("/home/couponList/couponSingle", {
      customer: customer,
    });
  };

  const isAccountExist = () => {
    if (customer.is_account_deleted !== true) {
      return (
        <div className="group-btn">
          <ul>
            <li>
              <Link
                to={{
                  pathname: "/home/sendSMS",
                  phone: customer.mobile_number,
                  userType: "Customer",
                }}
                className="single-view"
              >
                Send SMS
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/home/sendEmail",
                  email: customer.email_address,
                  userType: "Customer",
                }}
                className="single-view"
              >
                Send Email
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: "/home/notification",
                  uid: customer.customer_uid,
                  name: customer.given_name + " " + customer.last_name,
                  userType: "customer",
                }}
                className="single-view"
              >
                Send Push Notif
              </Link>
            </li>
            <li>
              <button onClick={adminCreateNewCoupon}>Create Coupon</button>
            </li>
            <li>
              <button onClick={adminShowAllTransports}>
                Show All Transport
              </button>
            </li>
            <li>
              <button onClick={adminShowActiveTransport}>
                Show Active Transport
              </button>
            </li>
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="search-single">
      <ToastContainer />
      {renderSpinner()}
      {customer ? (
        <div>
          {" "}
          <div className="user-info">
            <UserProfile label="Customer" user={customer ? customer : null} />
          </div>
          <div className="user-bearer-info">
            <CustomerBearerInfo user={customer ? customer : null} />
          </div>
          <div className="note">
            <Note
              user={customer ? customer : null}
              props={props}
              handleReloadSingle={() => getCustomerByID()}
            />
          </div>
          <div className="latest-activity">
            <LatestActivity
              user={customer ? customer : null}
              isCustomer={true}
              props={props}
              handleReloadSingle={() => getCustomerByID()}
            />
          </div>
          <div className="allowed-by-bearer">
            <AllowedByBearer
              user={customer ? customer : null}
              props={props}
              handleReloadSingle={() => getCustomerByID()}
            />
          </div>
          <div className="crm-tag">
            <CrmTag
              user={customer ? customer : null}
              props={props}
              handleReloadSingle={() => getCustomerByID()}
            />
          </div>
          <div className="loyalty">
            <LoyaltyTier
              user={customer ? customer : null}
              props={props}
              handleReloadSingle={() => getCustomerByID()}
            />
          </div>
          <div className="order-limit">
            <OrderLimit
              user={customer ? customer : null}
              props={props}
              handleReloadSingle={() => getCustomerByID()}
            />
          </div>
          {customer ? isAccountExist() : null}
        </div>
      ) : null}
    </div>
  );
}

export default CustomerSingle;
