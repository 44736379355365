import { getFunctions, httpsCallable } from "firebase/functions";
import config from "../config.json";
import { log } from "../helper/helper";

export function adminShowAllTransports(userType, uid, cursorTransportId) {
  let data = {};
  if (cursorTransportId === "") {
    data = {
      user_type: userType,
      uid: uid,
    };
  } else {
    data = {
      user_type: userType,
      uid: uid,
      cursor_transport_id: cursorTransportId,
    };
  }

  log(data);
  const functions = getFunctions();
  const allTransports = httpsCallable(functions, config.adminShowAllTransports);
  return allTransports(data);
}

export function adminShowActiveTransport(userType, uid) {
  const data = {
    user_type: userType,
    uid: uid,
  };
  log(data);
  const functions = getFunctions();
  const allActiveTransports = httpsCallable(
    functions,
    config.adminShowActiveTransport
  );
  return allActiveTransports(data);
}

export function adminGetModesOfTransport(uid) {
  const data = { uid: uid };
  const functions = getFunctions();
  const modesOfTransport = httpsCallable(
    functions,
    config.adminGetModesOfTransport
  );
  return modesOfTransport(data);
}
