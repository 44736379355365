import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import Select from "../common/select";
import { log } from "../../helper/helper";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "./allCustomer.scss";
import * as service from "./allCustomerService";
import Input from "../common/input";

const AllCustomer = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersFilterList, setCustomersFilterList] = useState([]);

  const [startData, setStartDate] = useState(new Date());
  const [endData, setEndDate] = useState(new Date());
  const [isFilterDateSelected, setIsFilterDateSelected] = useState(false);
  const currentdate = new Date();
  const maxdate = new Date(
    currentdate.setYear(currentdate.getFullYear() + 100)
  );
  const [selectedCustomerTypeName, setSelectedCustomerTypeName] = useState("");
  const [filterTargetSelected, setFilterTargetSelected] = useState("");
  const [filter, setFilter] = useState("");

  let customerType = [
    { id: "0", name: "Individual" },
    { id: "1", name: "Business" },
  ];

  let filterIndividualTarget = [
    { id: "1", name: "Full Name" },
    { id: "2", name: "Email" },
    { id: "3", name: "Phone Number" },
    { id: "4", name: "Referral Code" },
    { id: "5", name: "Engagement Scale" },
  ];

  let filterBusinessTarget = [
    { id: "1", name: "Full Name" },
    { id: "2", name: "Email" },
    { id: "3", name: "Phone Number" },
    { id: "4", name: "Business Name" },
    { id: "5", name: "Referral Code" },
    { id: "6", name: "Engagement Scale" },
  ];

  const [filterTarget, setFilterTarget] = useState([]);

  const handleNoteScale = (scaleNumber) => {
    switch (scaleNumber) {
      case 0:
        return (
          <div className="engagement-note no-interest">
            <span>0: No Interest</span>
          </div>
        );
      case 1:
        return (
          <div className="engagement-note slight-interest">
            <span>1: Slight Interest</span>
          </div>
        );
      case 2:
        return (
          <div className="engagement-note moderate-interest">
            <span>2: Moderate Interest</span>
          </div>
        );
      case 3:
        return (
          <div className="engagement-note interested">
            <span>3: Interested</span>
          </div>
        );
      case 4:
        return (
          <div className="engagement-note very-interested">
            <span>4: Very Interested</span>
          </div>
        );
      case 5:
        return (
          <div className="engagement-note extremely-interested">
            <span>5: Extremely Interested</span>
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
  }, []);

  const getAllCustomer = async () => {
    const item = customerType.filter((option) => {
      return option.name === selectedCustomerTypeName;
    });
    if (item.length < 1) {
      toast.error("you must select customer type");
      return;
    }

    var end = Moment(endData);
    var start = Moment(startData);
    var diffDays = parseInt((end - start) / (1000 * 60 * 60 * 24), 10);
    if (diffDays > 90) {
      toast.error("Date range must be less than 90 days");
      return;
    }

    setLoading(true);
    try {
      const result = await service.adminCustomerInbox(
        isFilterDateSelected,
        isFilterDateSelected ? Moment(startData).format("YYYY-MM-DD") : "",
        isFilterDateSelected ? Moment(endData).format("YYYY-MM-DD") : ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setCustomers(result.data.customer);
            if (result.data.customer.length > 0) {
              let list = result.data.customer.filter((customer) => {
                return customer.user_type === selectedCustomerTypeName;
              });
              if (list.length > 0) {
                setCustomersFilterList(list);
              } else {
                setCustomersFilterList([]);
              }
            }
            return;
          case 201:
            setCustomers([]);
            setCustomersFilterList([]);
            return toast.info(result.data.message);
          case 401:
          case 410:
          case 499:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleTableHeader = () => {
    if (selectedCustomerTypeName === "Individual") {
      return (
        <tr>
          <th>Full Name</th>
          <th>Phone Number</th>
          <th>Email Address</th>
          <th>Referral Code</th>
          <th>Engagement Scale</th>
          <th></th>
        </tr>
      );
    } else if (selectedCustomerTypeName === "Business") {
      return (
        <tr>
          <th>Business Name</th>
          <th>Contact Name</th>
          <th>Phone Number</th>
          <th>Email Address</th>
          <th>Referral Code</th>
          <th>Engagement Scale</th>
          <th></th>
        </tr>
      );
    }
  };

  const handelTableData = (customer) => {
    if (selectedCustomerTypeName === "Individual") {
      return (
        <tr key={customer.customer_uid}>
          <td>{customer.given_name + " " + customer.last_name}</td>
          <td>{customer.mobile_number}</td>
          <td>{customer.email_address}</td>
          <td>{customer.referred_by}</td>
          <td>{handleNoteScale(customer.note_user_engagement_scale)}</td>
          <td>
            <button
              className="search-view"
              onClick={() =>
                props.history.push(
                  "/home/findCustomer/customerList/customerSingle",
                  {
                    customerId: customer.customer_uid,
                  }
                )
              }
            >
              View
            </button>
          </td>
        </tr>
      );
    } else if (selectedCustomerTypeName === "Business") {
      return (
        <tr key={customer.customer_uid}>
          <td>{customer.business_name}</td>
          <td>{customer.given_name + " " + customer.last_name}</td>
          <td>{customer.mobile_number}</td>
          <td>{customer.email_address}</td>
          <td>{customer.referred_by}</td>
          <td>{handleNoteScale(customer.note_user_engagement_scale)}</td>
          <td>
            <button
              className="search-view"
              onClick={() =>
                props.history.push(
                  "/home/findCustomer/customerList/customerSingle",
                  {
                    customerId: customer.customer_uid,
                  }
                )
              }
            >
              View
            </button>
          </td>
        </tr>
      );
    }
  };

  const handleFilterData = (value) => {
    setFilter(value);

    let list = customers.filter((customer) => {
      return customer.user_type === selectedCustomerTypeName;
    });
    if (value !== "") {
      switch (filterTargetSelected) {
        case "Full Name":
          const nameList = list.filter(
            (li) =>
              (
                li.given_name.toLowerCase().toLowerCase() +
                " " +
                li.last_name.toLowerCase().toLowerCase()
              ).indexOf(value.toLowerCase()) !== -1
          );
          setCustomersFilterList(nameList);
          break;
        case "Phone Number":
          const phoneList = list.filter(
            (li) => li.mobile_number.indexOf(value) !== -1
          );
          setCustomersFilterList(phoneList);
          break;
        case "Email":
          const emailList = list.filter((li) =>
            li.email_address.toLowerCase().includes(value.toLowerCase())
          );
          setCustomersFilterList(emailList);
          break;
        case "Engagement Scale":
          const newList = list.filter(
            (li) =>
              (
                li.note_user_engagement_scale +
                " " +
                li.note_user_engagement_scale
              ).indexOf(value) !== -1
          );
          setCustomersFilterList(newList);
          break;
        case "Referral Code":
          const referralCode = list.filter((li) =>
            li.referred_by
              ? li.referred_by.toLowerCase().indexOf(value.toLowerCase()) !== -1
              : ""
          );
          setCustomersFilterList(referralCode);
          break;
        case "Business Name":
          const businessName = list.filter(
            (li) =>
              li.business_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
          );
          setCustomersFilterList(businessName);
          break;
        default:
          break;
      }
    } else {
      setCustomersFilterList(list);
    }
  };

  return (
    <div className="all-customer-list search-list incomplete-rider-ist">
      <ToastContainer />
      {renderSpinner()}
      <div className="filter-section">
        <Row>
          <Col md="6" style={{ marginTop: 35, textAlign: "left" }}>
            Customer Type:
          </Col>
          <Col md="6">
            <Select
              name="target"
              options={customerType}
              defaultValue="Select"
              onChange={(e) => {
                setSelectedCustomerTypeName(e.target.value);
                if (customers.length > 0) {
                  const filterList = customers.filter((customer) => {
                    return customer.user_type === e.target.value;
                  });
                  setCustomersFilterList(filterList);
                }

                setFilter("");
                if (e.target.value === "Individual") {
                  setFilterTarget(filterIndividualTarget);
                } else {
                  setFilterTarget(filterBusinessTarget);
                }
              }}
              disabled={false}
              disabledDefaultValue={false}
              disableSelect={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div
              className="filter-date"
              style={{ textAlign: "left", marginTop: 10 }}
            >
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={isFilterDateSelected}
                  onChange={() => {
                    setIsFilterDateSelected(!isFilterDateSelected);
                  }}
                  style={{ height: 30, marginBottom: 0, marginRight: 5 }}
                />
                Sign up Date Range
              </label>
            </div>
          </Col>
          <Col md="4">
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="dateTime"
                style={{ marginBottom: 20, marginRight: 5 }}
              >
                Start:
              </label>
              <DatePicker
                selected={startData}
                onChange={(date) => {
                  setStartDate(date);
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                disabled={!isFilterDateSelected}
                maxDate={maxdate}
              />
            </div>
          </Col>
          <Col md="4">
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="dateTime"
                style={{ marginBottom: 20, marginRight: 5 }}
              >
                End:
              </label>
              <DatePicker
                selected={endData}
                onChange={(date) => {
                  setEndDate(date);
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                disabled={!isFilterDateSelected}
                maxDate={maxdate}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="9"></Col>
          <Col md="3" style={{ textAlign: "right" }}>
            <button
              className="search-view"
              onClick={() => getAllCustomer()}
              style={{ margin: "10px 0 20px", display: "block", width: "100%" }}
            >
              Search
            </button>
          </Col>
        </Row>
      </div>
      <div className="filter-section">
        <div className="row">
          <div className="col-md-6">
            <Select
              name="target"
              options={filterTarget}
              onChange={(e) => {
                setFilterTargetSelected(e.target.value);
                setFilter("");
                const filterList = customers.filter((customer) => {
                  return customer.user_type === selectedCustomerTypeName;
                });
                setCustomersFilterList(filterList);
              }}
              disabled={false}
              defaultValue="Select your filter type"
              disabledDefaultValue={false}
              disableSelect={false}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeHolder="Filter input"
              type="String"
              name="message"
              value={filter}
              onChange={(e) => handleFilterData(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <h6>
            Found {customersFilterList.length}{" "}
            {customersFilterList.length > 1 ? "result" : "results"}
          </h6>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <table className="table">
            <thead>
              {customersFilterList.length > 0 && handleTableHeader()}
            </thead>
            <tbody>
              {customersFilterList.map((customer) => handelTableData(customer))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default AllCustomer;
