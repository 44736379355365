import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as service from "../findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function RiderIsOnJob({ user, props, handleReloadSingle }) {
  const [checkedSelection, setCheckedSelection] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allowedBtnDisable, setAllowedBtnDisable] = useState(true);

  const inputHasChanged = () => {
    setAllowedBtnDisable(!allowedBtnDisable);
    setCheckedSelection(!checkedSelection);
  };

  const exitBtn = () => {
    setAllowedBtnDisable(true);
    setShow(false);
  };

  const checkDefaultValue = () => {
    setCheckedSelection(user.is_on_job);
  };

  const setRiderIsOnJobClassChange = () => {
    if (user && user.is_on_job === false) {
      return (
        <div className=" status deleted">
          <span>false</span>
        </div>
      );
    } else if (user && user.is_on_job === true) {
      return (
        <div className="status active">
          <span>true</span>
        </div>
      );
    }
  };

  const handleRideOnJob = async () => {
    handleClose(true);
    setLoading(true);
    try {
      const result = await service.adminRiderChangeIsOnJob(
        user.rider_uid,
        checkedSelection
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setAllowedBtnDisable(true);
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };
  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>Rider Is On Job</h5>
      </div>
      <div className="col-md-4">{setRiderIsOnJobClassChange()}</div>
      <div className="col-md-4">
        <Button
          variant="primary"
          className="edit-btn"
          onClick={() => {
            handleShow();
            checkDefaultValue();
          }}
        >
          Edit
        </Button>
        <Modal show={show} className="allowed-by-user" onHide={exitBtn}>
          <Modal.Header closeButton>
            <Modal.Title>Rider Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="toggleSwitch">
              <div className="container">
                <span className="switch-label">Rider Status:</span>
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={checkedSelection}
                    name="allowedByBearer"
                    id="allowedByBearer"
                    onChange={(e) => inputHasChanged(e.target.value)}
                  />
                  <label className="label" htmlFor="allowedByBearer">
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={allowedBtnDisable}
              variant="primary"
              onClick={() => handleRideOnJob()}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default RiderIsOnJob;
