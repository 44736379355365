import React, { useState } from "react";
import * as service from "../findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function RiderRoadMap({ rider, props, handleReloadSingle }) {
  const stage = rider ? rider.sign_up_stage : 0;
  const subStage = rider ? rider.sign_up_sub_stage : 0;
  const [isLoading, setLoading] = useState(false);

  const handleDeleteStep = async (stageNumber) => {
    setLoading(true);
    try {
      const result = await service.adminOnboardingDaleteStage(
        rider.rider_uid,
        stageNumber
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const checkPassed = (number) => {
    if (stage !== 1) {
      if (stage >= number) {
        return "step-thumbnail";
      } else {
        return "step-thumbnail blur";
      }
    } else {
      return "step-thumbnail blur";
    }
  };
  const handleDeletedBtn = (number) => {
    if (stage !== 1) {
      if (stage === number) {
        return (
          <button
            onClick={() => handleDeleteStep(stage)}
            className="deleted-btn"
          >
            delete
          </button>
        );
      }
    }
  };

  const handleBlurStageOne = (number) => {
    if (subStage === 2) {
      return "detail blur";
    } else {
      return "detail";
    }
  };
  const handleDeleteBtnStageOne = () => {
    if (stage === 1) {
      if (subStage === 3) {
        return (
          <button
            onClick={() => handleDeleteStep(stage)}
            className="deleted-btn"
          >
            delete
          </button>
        );
      }
    }
  };
  const handleTickMark = (status) => {
    if (stage >= 1) {
      if (status) {
        return (
          <span>
            <img src={require("../../../assets/tick.png")} alt="" />
          </span>
        );
      }
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="rider-road-map">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-12">
          <h5>Rider Road Map Setup</h5>
        </div>
        <div className="col-md-12">
          <div className="step-thumbnail">
            <div className="title">1</div>
            <div className="value">Terms and Condition and Privacy Policy</div>
          </div>
          <div className="step-thumbnail">
            <div className="title">2</div>
            <div className="value">Contact Details</div>
            {handleDeleteBtnStageOne()}
            <div className="contact-detail">
              <div className="detail">
                {handleTickMark(subStage >= 2 ? true : false)}
                email
              </div>
              <div className="detail">
                {handleTickMark(subStage >= 2 ? true : false)}
                password
              </div>
              <div className={handleBlurStageOne()}>
                {handleTickMark(subStage === 3 ? true : false)}
                sms
              </div>
            </div>
          </div>
          <div className={checkPassed(2)}>
            <div className="title">3</div>
            <div className="value">Personal Details</div>
            {handleDeletedBtn(2)}
          </div>
          <div className={checkPassed(3)}>
            <div className="title">4</div>
            <div className="value">Legal Details</div>
            {handleDeletedBtn(3)}
          </div>
          <div className={checkPassed(4)}>
            <div className="title">5</div>
            <div className="value">Modes of Transport</div>
            {handleDeletedBtn(4)}
          </div>
          <div className={checkPassed(5)}>
            <div className="title">6</div>
            <div className="value">Stripe Onboarding</div>
            {handleDeletedBtn(5)}
          </div>
          <div className={checkPassed(6)}>
            <div className="title">7</div>
            <div className="value">ID Verification</div>
            {handleDeletedBtn(6)}
          </div>
          <div className={checkPassed(7)}>
            <div className="title">8</div>
            <div className="value">Digital Signature</div>
            {handleDeletedBtn(7)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RiderRoadMap;
