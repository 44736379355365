import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleSubmitWithActiceBtn = (e) => {
    e.preventDefault();
    this.doSubmit();
  };

  handleSubmitOnClickWithActiceBtn = (e) => {
    e.preventDefault();
    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
    // this.handleInputChange(input.value);
  };

  renderButton(label, isEnable = false) {
    if (isEnable) {
      return (
        <button disabled={false} className="btn btn-primary">
          {label}
        </button>
      );
    } else {
      return (
        <button disabled={this.validate(isEnable)} className="btn btn-primary">
          {label}
        </button>
      );
    }
  }

  renderMultiButtonAction(label, isDisabled = false, btnIndex) {
    if (isDisabled) {
      return (
        <button
          onClick={() => (this.state.btnLabel = btnIndex)}
          disabled={this.validate(isDisabled)}
          className="btn btn-primary"
        >
          {label}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => (this.state.btnLabel = btnIndex)}
          disabled={isDisabled}
          className="btn btn-primary"
        >
          {label}
        </button>
      );
    }
  }

  renderSelect(name, label, options, isDisabled = false) {
    const { data, errors } = this.state;
    const handleSelectChange = (value) => {
      const data = { ...this.state.data };
      data[name] = value;
      this.setState({ data, errors });
      this.setState({ errors });
      this.doInputChangeBasedOnSelection(value);
    };
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={(event) => handleSelectChange(event.target.value)}
        error={errors[name]}
        disableSelect={isDisabled}
      />
    );
  }

  renderMultiSelect(name, label, options, defaultValue, isDisabled = false) {
    const { data, errors } = this.state;
    const handleSelectChange = (value) => {
      const data = { ...this.state.data };
      data[name] = value;
      this.setState({ data, errors });
      this.setState({ errors });
      this.doInputSelectChangeBasedOnSelection(value, name);
    };
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={(event) => handleSelectChange(event.target.value, name)}
        error={errors[name]}
        defaultValue={defaultValue}
        disableSelect={isDisabled}
      />
    );
  }

  renderInput(name, label, type, placeHolder = "text", disabled = false) {
    const { data, errors } = this.state;
    return (
      <Input
        disabled={disabled}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        placeHolder={placeHolder}
      />
    );
  }
}

export default Form;
