import React from "react";

function HistorySingleComponents({ header, value, isChanged }) {
  const valueDecision = () => {
    if (value === true) {
      return (
        <div className="admin-header-info-value-true">
          <span>True</span>
        </div>
      );
    } else if (value === false) {
      return (
        <div className="admin-header-info-value-false">
          <span>False</span>
        </div>
      );
    } else {
      return <div className="admin-header-info-value">{value}</div>;
    }
  };
  return (
    <div
      className={
        isChanged ? "changed admin-info-components" : "admin-info-components"
      }
    >
      <div className="row">
        <div className="col-md-6">
          <h5 className="admin-header-info">{header}</h5>
        </div>
        <div className="col-md-4">{valueDecision()}</div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}

export default HistorySingleComponents;
