import React, { useEffect, useState } from "react";
import "./global.scss";
import * as service from "../../services/backendServices";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";

import ridingImage from "../../assets/riding.png";
import walkingImage from "../../assets/walking.png";
import cyclingImage from "../../assets/cycling.png";
import {
  timestampToDateYearFormat,
  timestampToDateTimeFormat,
  log,
} from "../../helper/helper";

function ShowAllTransports(props) {
  log(props);
  const auth = getAuth();
  const [transports, setTransports] = useState(
    props.history.location.state
      ? props.history.location.state.transportList
      : []
  );
  const userType = props.history.location.state
    ? props.history.location.state.userType
    : "";

  const uid = props.history.location.state
    ? props.history.location.state.uid
    : "";

  const [cursorTransportId, setCursorTransportId] = useState(
    props.history.location.state
      ? props.history.location.state.cursorTransportId
      : ""
  );
  const [isLoading, setLoading] = useState(false);
  const [isHidden, setBtnHidden] = useState(
    cursorTransportId && cursorTransportId.length > 1 ? false : true
  );

  const loadMoreTransports = async () => {
    if (cursorTransportId.length > 1) {
      setLoading(true);
      try {
        const result = await service.adminShowAllTransports(
          userType,
          uid,
          cursorTransportId
        );
        setLoading(false);
        log(result);
        if (result) {
          setLoading(false);
          switch (result.data.code) {
            case 200:
              const list = [...transports, ...result.data.transportList];
              setTransports(list);
              if (result.data.cursor_transport_id) {
                setCursorTransportId(result.data.cursor_transport_id);
              } else {
                setCursorTransportId("");
                setBtnHidden(true);
              }
              return;
            case 401:
              toast.error(result.data.message);
              return this.props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        log(error);
        toast.error(error.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.history.location.state) {
      props.history.replace("/home/dashboard");
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
  });

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleTransportStatus = (status) => {
    switch (status) {
      case "Canceled by Customer":
        return (
          <div className="deleted">
            <span>Canceled by Customer</span>{" "}
          </div>
        );
      case "Canceled by Rider":
        return (
          <div className="rider-deleted">
            <span>Canceled by Rider</span>{" "}
          </div>
        );
      case "Completed":
        return (
          <div className="active mar0">
            <span>Completed</span>{" "}
          </div>
        );
      default:
        return <span>{status}</span>;
    }
  };

  const handleBearerType = (bearer) => {
    switch (bearer) {
      case "riding":
        return (
          <div className="bearer">
            <img src={ridingImage} alt="rider" />
          </div>
        );
      case "cycling":
        return (
          <div className="bearer">
            <img src={cyclingImage} alt="cycle" />
          </div>
        );
      case "walking":
        return (
          <div className="bearer">
            <img src={walkingImage} alt="walk" />
          </div>
        );

      default:
        break;
    }
  };

  const handleTransportDate = (time) => {
    return timestampToDateYearFormat(time._seconds, time._nanoseconds);
  };

  const handleTransportTime = (time) => {
    return timestampToDateTimeFormat(time._seconds, time._nanoseconds);
  };

  return (
    <div className="show-all-list">
      <ToastContainer />
      {renderSpinner()}
      <div className="headline">
        <h3>Show All Transports</h3>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Transport Date</th>
            <th>Rider's Name</th>
            <th>
              {userType && userType === "customer"
                ? "Original Price"
                : "Original Payable"}
            </th>
            <th>
              {userType && userType === "customer"
                ? "Paid Amount "
                : "Final Paid"}
            </th>
            <th>Mode of Transport</th>
            <th>Transport State</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transports.map((transport) => (
            <tr key={transport.transport_id}>
              <td>
                {handleTransportDate(transport.time)}
                <br />
                {handleTransportTime(transport.time)}
              </td>
              <td>
                {transport.rider_given_name + " " + transport.rider_last_name}
              </td>
              <td>
                {userType && userType === "customer"
                  ? `A$ ${transport.transport_original_price}`
                  : `A$ ${transport.original_payable_to_rider}`}
              </td>
              <td>
                {userType && userType === "customer"
                  ? `A$ ${transport.transport_receivable_price}`
                  : `A$ ${transport.final_paid_to_rider}`}
              </td>
              <td>{handleBearerType(transport.assigned_bearer_type)}</td>
              <td>{handleTransportStatus(transport.transport_state)}</td>

              <td>
                <button
                  onClick={() =>
                    props.history.push("/home/transportSingle", {
                      transportId: transport.transport_id,
                      userType: userType,
                      isFromFindTransport: false,
                    })
                  }
                  className="single-view"
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="load-more">
        <button hidden={isHidden} onClick={() => loadMoreTransports()}>
          Load More
        </button>
      </div>
    </div>
  );
}

export default ShowAllTransports;
