import React from "react";
import "./coupons.scss";
import Joi from "joi-browser";
import "bootstrap/dist/css/bootstrap.css";
import Form from "../common/form";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as service from "./couponsServices";
import * as helper from "../../helper/helper";
class CouponSingle extends Form {
  state = {
    data: {
      nameTag: "",
      maxOveralRedemptions: "",
      maxOveralRedemptionsperCustomer: "",
      minOrderAmount: "",
      maxAmountOff: "",
      percentOff: "",
      description: "",
      changeNote: "",
      marketingDescriptionAdmin: "",
      userType: "",
      couponType: "",
      allowedUserId: "",
      duration: "",
      crmStatus: "",
      loyaltyTier: "",
      onBirthday: "",
      onSignupDay: "",
      stillValidByBearer: "",
    },
    allowedUserIdDisable: false,
    allowedCouponTypeDisable: false,
    allowedUserTypeDisable: false,

    selectedDate: new Date(),
    userType: [
      { id: "1", name: "Business" },
      { id: "2", name: "Individual" },
    ],
    couponType: [
      { id: "1", name: "Unique" },
      { id: "2", name: "Generic" },
    ],
    duration: [
      { id: "1", name: "once" },
      { id: "2", name: "repeating" },
      { id: "3", name: "forever" },
    ],
    crmStatus: [
      { id: "1", name: "None" },
      { id: "2", name: "Recently Signed Up" },
      { id: "3", name: "Retained User" },
      { id: "4", name: "Unhappy User" },
    ],
    loyaltyTier: [
      { id: "1", name: "None" },
      { id: "2", name: "0" },
      { id: "3", name: "1" },
      { id: "4", name: "2" },
      { id: "5", name: "3" },
    ],
    onBirthday: [
      { id: "1", name: "true" },
      { id: "2", name: "false" },
    ],
    onSignupDay: [
      { id: "1", name: "true" },
      { id: "2", name: "false" },
    ],
    stillValidByBearer: [
      { id: "1", name: "true" },
      { id: "2", name: "false" },
    ],
    btnLabel: "",
    errors: {},
    isLoading: false,
  };

  schema = {
    nameTag: Joi.string().min(2).required(),
    maxOveralRedemptions: Joi.string()
      .required()
      .label("Max Overal Redemptions:"),
    maxOveralRedemptionsperCustomer: Joi.string()
      .required()
      .label("Max Overal Redemptions per Customer:"),
    minOrderAmount: Joi.string().required().label("Min Order Amount:"),
    maxAmountOff: Joi.string().required().label("Max Amount Off:"),
    percentOff: Joi.string().required().label("Percent Off:"),
    description: Joi.string().required().label("Description:"),
    changeNote: Joi.string().required().label("Change Note:"),
    userType: Joi.string().required().label("Allowed User Type:"),
    couponType: Joi.string().required().label("Coupon Type:"),
    allowedUserId: Joi.string().required().label("Allowed User Id:"),
    marketingDescriptionAdmin: Joi.string()
      .required()
      .label("Marketing Description Admin:"),
    duration: Joi.string().required().label("Duration:"),
    crmStatus: Joi.string().required().label("CRM Status:"),
    loyaltyTier: Joi.string().required().label("Loyalty Tier:"),
    onBirthday: Joi.string().required().label("On Birthday:"),
    onSignupDay: Joi.string().required().label("On Signup Day:"),
    stillValidByBearer: Joi.string().required().label("Still Valid By Bearer:"),
  };

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return this.props.history.replace("/login");
      } else {
        const coupon = this.props.history.location.state
          ? this.props.history.location.state.coupon
          : undefined;
        const couponSingleUser = this.props.history.location.state
          ? this.props.history.location.state.customer
          : undefined;
        if (coupon !== undefined) {
          if (coupon.coupon_type === "Generic") {
            this.setState({
              allowedUserIdDisable: true,
            });
          }

          const time =
            coupon.duration_validity._seconds * 1000 +
            coupon.duration_validity._nanoseconds * 1000000;

          this.setState({
            selectedDate: new Date(helper.timestampMiliSecondToDateYear(time)),
          });
          this.setState({
            data: {
              nameTag: coupon.name_tag,
              maxOveralRedemptions: coupon.max_overal_redemptions,
              maxOveralRedemptionsperCustomer:
                coupon.max_redemptions_per_customer,
              minOrderAmount: coupon.min_order_amount,
              maxAmountOff: coupon.max_amount_off,
              percentOff: coupon.percent_off,
              description: coupon.description,
              changeNote:
                coupon.change_note !== null || coupon.change_note !== undefined
                  ? coupon.change_note
                  : "",
              marketingDescriptionAdmin: coupon.marketing_description_admin,
              userType: coupon.allowed_user_type,
              couponType: coupon.coupon_type,
              allowedUserId: coupon.allowed_user_id,
              duration: coupon.duration,
              crmStatus: coupon.preliminary_condition_crm_status,
              loyaltyTier: coupon.preliminary_condition_loyalty_tier,
              onBirthday: coupon.preliminary_condition_on_birthday
                ? "true"
                : "false",
              onSignupDay: coupon.preliminary_condition_on_signup_day
                ? "true"
                : "false",
              stillValidByBearer: coupon.still_valid_by_bearer
                ? "true"
                : "false",
            },
          });
        } else if (couponSingleUser !== undefined) {
          this.setState({
            allowedUserIdDisable: true,
            allowedCouponTypeDisable: true,
            allowedUserTypeDisable: true,
            data: {
              couponType: "Unique",
              userType: couponSingleUser.user_type,
              allowedUserId:
                couponSingleUser.user_type === "Individual"
                  ? couponSingleUser.given_name +
                    " " +
                    couponSingleUser.last_name
                  : couponSingleUser.business_name,
            },
          });
        }
      }
    });
  }

  doSubmit = async () => {
    const state = this.state.data;

    var english = /^[A-Za-z0-9]*$/;
    var number = /^[0-9]*$/;

    if (
      !english.test(state.nameTag.replace(/\s/g, "")) ||
      state.nameTag.replace(/\s/g, "") === undefined
    ) {
      return toast.error(
        "Name Tag must contain English Letter(s) and/or Number(s)"
      );
    }

    if (
      !number.test(state.maxOveralRedemptions) ||
      state.maxOveralRedemptions === undefined
    ) {
      return toast.error("Only Numbers are accepted");
    }
    if (
      state.maxOveralRedemptionsperCustomer === "" ||
      state.maxOveralRedemptionsperCustomer === undefined
    ) {
      return toast.error("Fill maxOveralRedemptionsperCustomer");
    }
    if (state.minOrderAmount === "" || state.minOrderAmount === undefined) {
      return toast.error("Fill minOrderAmount");
    }
    if (state.maxAmountOff === "" || state.maxAmountOff === undefined) {
      return toast.error("Fill maxAmountOff");
    }
    if (state.percentOff === "" || state.percentOff === undefined) {
      return toast.error("Fill percentOff");
    }
    if (state.description === "" || state.description === undefined) {
      return toast.error("Fill description");
    }
    if (state.changeNote === "" || state.changeNote === undefined) {
      return toast.error("Fill Change note");
    }
    if (
      state.marketingDescriptionAdmin === "" ||
      state.marketingDescriptionAdmin === undefined
    ) {
      return toast.error("Fill marketingDescriptionAdmin");
    }
    if (state.userType === "" || state.userType === undefined) {
      return toast.error("Fill userType");
    }
    if (state.couponType === "couponType" || state.couponType === undefined) {
      return toast.error("Fill ");
    }
    if (state.allowedUserId === "" || state.allowedUserId === undefined) {
      return toast.error("Fill allowedUserId");
    }
    if (state.duration === "" || state.duration === undefined) {
      return toast.error("Fill duration");
    }
    if (state.crmStatus === "" || state.crmStatus === undefined) {
      return toast.error("Fill crmStatus");
    }
    if (state.loyaltyTier === "" || state.loyaltyTier === undefined) {
      return toast.error("Fill loyaltyTier");
    }
    if (state.onBirthday === "" || state.onBirthday === undefined) {
      return toast.error("Fill onBirthday");
    }
    if (state.onSignupDay === "" || state.onSignupDay === undefined) {
      return toast.error("Fill onSignupDay");
    }
    if (
      state.stillValidByBearer === "" ||
      state.stillValidByBearer === undefined
    ) {
      return toast.error("Fill stillValidByBearer");
    }
    if (
      this.state.selectedDate === "" ||
      this.state.selectedDate === undefined
    ) {
      return toast.error("Fill selectedDate");
    }

    const onBirthday = state.onBirthday === "true" ? true : false;
    const onSignupDay = state.onSignupDay === "true" ? true : false;
    const stillValidByBearer =
      state.stillValidByBearer === "true" ? true : false;

    this.setState({ isLoading: true });

    let serviceProvider;

    if (
      this.props.history.location.state &&
      this.props.history.location.state.coupon !== undefined
    ) {
      serviceProvider = service.adminUpdateCoupon;
    } else {
      serviceProvider = service.adminCreateNewCoupon;
    }
    try {
      const result = await serviceProvider(
        this.props.history.location.state &&
          this.props.history.location.state.coupon !== undefined
          ? this.props.history.location.state.coupon.coupon_id
          : "",
        this.state.data.nameTag.toLocaleUpperCase(),
        this.state.data.maxOveralRedemptions,
        this.state.data.maxOveralRedemptionsperCustomer,
        this.state.data.minOrderAmount,
        this.state.data.maxAmountOff,
        this.state.data.percentOff,
        this.state.data.description,
        this.state.data.changeNote,
        this.state.data.marketingDescriptionAdmin,
        this.state.data.userType,
        this.state.data.couponType,
        this.props.history.location.state &&
          this.props.history.location.state.customer !== undefined
          ? this.props.history.location.state.customer.customer_uid
          : this.state.data.allowedUserId,
        this.state.data.duration,
        this.state.data.crmStatus,
        this.state.data.loyaltyTier,
        Boolean(onBirthday),
        Boolean(onSignupDay),
        Boolean(stillValidByBearer),
        this.state.selectedDate
      );
      this.setState({ isLoading: false });
      helper.log(result);
      if (result) {
        this.setState({ isLoading: false });
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            this.props.history.goBack();
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      helper.log(error);
      toast.error(error.message);
    }
    this.setState({ isLoading: false });
  };

  setStartDate = (date) => {
    this.setState({ selectedDate: date });
  };

  doInputSelectChangeBasedOnSelection = (value, name) => {
    if (name === "couponType") {
      if (value === "Generic") {
        this.setState({
          allowedUserIdDisable: true,
          data: {
            ...this.state.data,
            allowedUserId: "All",
            couponType: "Generic",
          },
        });
      } else {
        this.setState({
          allowedUserIdDisable: false,
          data: { ...this.state.data, allowedUserId: "", couponType: "Unique" },
        });
      }
    }
  };

  render() {
    const isLoading = this.state.isLoading;
    const renderSpinner = () => {
      if (isLoading) {
        return <Spinner />;
      }
    };

    const currentdate = new Date();
    const maxdate = new Date(
      currentdate.setYear(currentdate.getFullYear() + 100)
    );

    return (
      <div>
        <ToastContainer />
        {renderSpinner()}
        <div className="coupons-single">
          <form onSubmit={this.handleSubmitOnClickWithActiceBtn}>
            <div className="row">
              <div className="col-md-4">
                {this.renderInput("nameTag", "Name Tag:", "String", "", false)}
              </div>
              <div className="col-md-4">
                {this.renderInput(
                  "maxOveralRedemptions",
                  "Max Overal Redemptions:",
                  "number",
                  ""
                )}
              </div>
              <div className="col-md-4">
                {this.renderInput(
                  "maxOveralRedemptionsperCustomer",
                  "Max Overal Redemptions per Customer:",
                  "number",
                  "",
                  false
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                {this.renderInput(
                  "minOrderAmount",
                  "Min Order Amount:",
                  "number",
                  ""
                )}
              </div>
              <div className="col-md-4">
                {this.renderInput(
                  "maxAmountOff",
                  "Max Amount Off:",
                  "number",
                  ""
                )}
              </div>
              <div className="col-md-4">
                {this.renderInput("percentOff", "Percent Off:", "number", "")}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {this.renderInput("description", "Description:", "String", "")}
              </div>
            </div>
            <div className="row top-buffer">
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "userType",
                  "Allowed User Type:",
                  this.state.userType,
                  this.state.data.userType,
                  this.state.allowedUserTypeDisable
                )}
              </div>
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "couponType",
                  "Coupon Type:",
                  this.state.couponType,
                  this.state.data.couponType,
                  this.state.allowedUserTypeDisable
                )}
              </div>
              <div className="col-md-4">
                {this.renderInput(
                  "allowedUserId",
                  "Allowed User Id:",
                  "String",
                  "",
                  this.state.allowedUserIdDisable
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="dateTime">Duration Validity:</label>
                  <DatePicker
                    selected={this.state.selectedDate}
                    onChange={(date) => this.setStartDate(date)}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    maxDate={maxdate}
                  />
                </div>
              </div>
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "duration",
                  "Duration:",
                  this.state.duration,
                  this.state.data.duration
                )}
              </div>
              <div className="col-md-4">
                {this.renderInput(
                  "marketingDescriptionAdmin",
                  "Marketing Description Admin:",
                  "String",
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "crmStatus",
                  "CRM Status:",
                  this.state.crmStatus,
                  this.state.data.crmStatus
                )}
              </div>
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "loyaltyTier",
                  "Loyalty Tier:",
                  this.state.loyaltyTier,
                  this.state.data.loyaltyTier
                )}
              </div>
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "onBirthday",
                  "On Birthday:",
                  this.state.onBirthday,
                  this.state.data.onBirthday
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "onSignupDay",
                  "On Signup Day:",
                  this.state.onSignupDay,
                  this.state.data.onSignupDay
                )}
              </div>
              <div className="col-md-4">
                {this.renderMultiSelect(
                  "stillValidByBearer",
                  "Still Valid By Bearer:",
                  this.state.stillValidByBearer,
                  this.state.data.stillValidByBearer
                )}
              </div>
              <div className="col-md-4"></div>
              <div className="row">
                <div className="col-md-12">
                  {this.renderInput("changeNote", "Change note:", "String", "")}
                </div>
              </div>
            </div>
            <div className="row top-buffer">
              <div className="col-md-12">
                <ul className="coupon-action-btn">
                  <li>{this.renderMultiButtonAction("Save", false, 1)}</li>
                  <li>
                    {this.renderMultiButtonAction(
                      "Save & Send Push Notification",
                      false,
                      2
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CouponSingle;
