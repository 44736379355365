import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Input from "../../common/input";
import * as service from "../findCustomerService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function OrderLimit({ user, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderBtnDisable, setOrderBtnDisable] = useState(true);
  const [inputOrder, setInputOrder] = useState("");

  const setCount = (value) => {
    log(value);
    if (Number(value) > 0 && Number(value) !== user.order_limits) {
      setInputOrder(value);
      setOrderBtnDisable(false);
    } else {
      setOrderBtnDisable(true);
      setInputOrder("");
    }
  };
  const handleExit = () => {
    setShow(false);
    setOrderBtnDisable(true);
  };

  const handleChangeOrderLimit = async () => {
    handleClose(true);
    setLoading(true);

    try {
      const result = await service.adminCustomerChangeOrderLimit(
        user.customer_uid,
        Number(inputOrder)
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            handleReloadSingle();
            return;
          case 401:
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
    setInputOrder("");
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const renderOrderLimit = () => {
    if (user ? user.user_type === "Business" : "") {
      return (
        <Button variant="primary" className="edit-btn" onClick={handleShow}>
          Edit
        </Button>
      );
    }
  };
  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>Order Limit</h5>
      </div>
      <div className="col-md-4">
        <div className="value">{user ? user.order_limits : 0}</div>
      </div>
      <div className="col-md-4">
        {renderOrderLimit()}
        <Modal show={show} className="modal-popup-custom" onHide={handleExit}>
          <Modal.Header closeButton>
            <Modal.Title>Order Limit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              label="Order Limit Count:"
              type="number"
              name="order"
              value={inputOrder}
              onChange={(e) => setCount(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={orderBtnDisable}
              onClick={handleChangeOrderLimit}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default OrderLimit;
