import React, { useState, useEffect } from "react";
import "../findCustomer.scss";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../../assets/profile.png";
import { log } from "../../../helper/helper";

function UserProfile({ user, label }) {
  const name = user ? user.given_name : "";
  const lastName = user ? user.last_name : "";
  const mobile = user ? user.mobile_number : "";
  const email = user ? user.email_address : "";
  const birth = user ? user.birth_date : "";
  const avatar = user ? user.avatar_url : "";
  const [imageUrl, setImageUrl] = useState("");

  const downloadAvatar = () => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  useEffect(() => {
    if (avatar !== "") {
      downloadAvatar();
    }
  });

  const renderLocation = () => {
    if (label === "Rider") {
      return (
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="rider-profile-location">
              <h6>
                <span>
                  <img
                    src={require("../../../assets/rider-location.png")}
                    alt="location-icon"
                  />
                </span>
                {` ${user ? user.legal_address_segmented.street_no : ""} ${
                  user ? user.legal_address_segmented.street_name : ""
                } ${user ? user.legal_address_segmented.region_area : ""} ${
                  user ? user.legal_address_segmented.state : ""
                } ${user ? user.legal_address_segmented.post_code : ""}`}
              </h6>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="row">
        <h5>{label} Info</h5>
        <div className="profile-avatar">
          <figure>
            <div className="avatar">
              <img
                src={imageUrl !== "" ? imageUrl : profileImage}
                alt="avatar"
              />
            </div>
          </figure>
          <figcaption>
            <span>
              <img
                src={require("../../../assets/customer-info-profile.png")}
                alt="avatar-icon"
              />
            </span>
            {user
              ? user.user_type === "Business"
                ? user.business_name + "(" + name + " " + lastName + ")"
                : name + " " + lastName
              : ""}
          </figcaption>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="thumbnail">
            <span>
              <img
                src={require("../../../assets/customer-info-phone.png")}
                alt="phone-icon"
              />
            </span>
            <h6>{mobile}</h6>
          </div>
        </div>
        <div className="col-md-4">
          {" "}
          <div className="thumbnail">
            <span>
              <img
                src={require("../../../assets/customer-info-email.png")}
                alt="phone-icon"
              />
            </span>
            <h6>{email}</h6>
          </div>
        </div>
        <div className="col-md-4">
          {" "}
          <div className="thumbnail">
            <span>
              <img
                src={require("../../../assets/customer-info-birth.png")}
                alt="phone-icon"
              />
            </span>
            <h6>{birth}</h6>
          </div>
        </div>
        <div className="user-extra-info">
          <div className="row">
            <div className="col-md-6">
              <div className="title-extra">
                Gender:
                <span className="value">{user ? user.gender : ""}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="title-extra">
                Referred by:
                <span className="value">{user ? user.referred_by : ""}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderLocation()}
    </div>
  );
}

export default UserProfile;
