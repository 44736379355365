import React, { useEffect, useState } from "react";
import "./pricingObject.scss";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Input from "../common/input";
import * as service from "./pricingObjectServices.js";
import { log } from "../../helper/helper";

function PricingObject(props) {
  const [isLoading, setLoading] = useState(true);
  const [segmentOne, setSegmentOne] = useState(0);
  const [segmentTwo, setSegmentTwo] = useState(0);
  const [segmentThree, setSegmentThree] = useState(0);
  const [segmentFour, setSegmentFour] = useState(0);

  const [walkingBase, setWalkingBase] = useState(0);
  const [cyclingBase, setCyclingBase] = useState(0);
  const [ridingBase, setRidingBase] = useState(0);

  const [dropOffTime, setDropOffTime] = useState(0);
  const [pickUpTime, setpickUpTime] = useState(0);
  const [specialCondition, setSpecialCondition] = useState(0);
  const [wage, setWage] = useState(0);
  const [specialConditionNote, setSpecialConditionNote] = useState("");

  const [segmentTimeOne, setSegmentTimeOne] = useState(0);
  const [segmentTimeTwo, setSegmentTimeTwo] = useState(0);
  const [segmentTimeThree, setSegmentTimeThree] = useState(0);
  const [changeNote, setChangeNote] = useState("");

  const [history, setHistory] = useState();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
    loadPricingObject();
  }, []);

  const loadPricingObject = async () => {
    try {
      const result = await service.adminGetPricingObject();
      setLoading(false);
      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 200:
            const data = result.data.price;
            setSegmentOne(data.charges_on_distance.segment_one);
            setSegmentTwo(data.charges_on_distance.segment_two);
            setSegmentThree(data.charges_on_distance.segment_three);
            setSegmentFour(data.charges_on_distance.segment_four);

            setWalkingBase(data.walking_base_fare);
            setCyclingBase(data.cycling_base_fare);
            setRidingBase(data.riding_base_fare);

            setDropOffTime(data.drop_off_time);
            setpickUpTime(data.pick_up_time);
            setSpecialCondition(data.special_condition);
            setWage(data.wage);

            setSegmentTimeOne(data.time_of_the_day.segment_one);
            setSegmentTimeTwo(data.time_of_the_day.segment_two);
            setSegmentTimeThree(data.time_of_the_day.segment_three);
            log(data.pricing_object_history);
            setHistory(data.pricing_object_history);
            setSpecialConditionNote(data.special_condition_note);

            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const onSubmit = async () => {
    if (segmentOne === "") {
      return toast.error("Segment One shouldn't be empty");
    }
    if (segmentTwo === "") {
      return toast.error("Segment Two shouldn't be empty");
    }
    if (segmentThree === "") {
      return toast.error("Segment Three shouldn't be empty");
    }
    if (segmentFour === "") {
      return toast.error("Segment Four shouldn't be empty");
    }
    if (walkingBase === "") {
      return toast.error("Walking Base shouldn't be empty");
    }
    if (cyclingBase === "") {
      return toast.error("Cycling Base shouldn't be empty");
    }
    if (ridingBase === "") {
      return toast.error("Riding Base shouldn't be empty");
    }
    if (dropOffTime === "") {
      return toast.error("Drop off Time shouldn't be empty");
    }
    if (pickUpTime === "") {
      return toast.error("Pickup Time shouldn't be empty");
    }
    if (specialCondition === "") {
      return toast.error("Special Condition shouldn't be empty");
    }
    if (wage === "") {
      return toast.error("Wage shouldn't be empty");
    }
    if (segmentTimeOne === "") {
      return toast.error("Segment One shouldn't be empty");
    }
    if (segmentTimeTwo === "") {
      return toast.error("Segment Two shouldn't be empty");
    }
    if (changeNote.replace(/\s/g, "").length < 2) {
      return toast.error("Change note must be Minimum 1 character");
    }

    if (specialConditionNote.replace(/\s/g, "").length < 2) {
      return toast.error("special condition note must be Minimum 1 character");
    }

    setLoading(true);
    try {
      const result = await service.adminUpdatePricingObject(
        segmentOne,
        segmentTwo,
        segmentThree,
        segmentFour,
        walkingBase,
        cyclingBase,
        ridingBase,
        dropOffTime,
        pickUpTime,
        specialCondition,
        wage,
        segmentTimeOne,
        segmentTimeTwo,
        segmentTimeThree,
        changeNote,
        specialConditionNote
      );
      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success("done perfectly");
            loadPricingObject();
            return;
          case 401:
            toast.error(result.data.message);
            setLoading(false);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="pricing-object">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="title">Charges on Distance:</div>
        <div className="col-md-3">
          <Input
            label="Segment One:"
            type="Number"
            name="segmentOne"
            value={segmentOne}
            onChange={(e) => setSegmentOne(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Segment Two:"
            type="Number"
            name="segmentTwo"
            value={segmentTwo}
            onChange={(e) => setSegmentTwo(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Segment Three:"
            type="Number"
            name="segmentThree"
            value={segmentThree}
            onChange={(e) => setSegmentThree(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Segment Four:"
            type="Number"
            name="segmentFour"
            value={segmentFour}
            onChange={(e) => setSegmentFour(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="title">Base Fare:</div>
        <div className="col-md-3">
          <Input
            label="Walking Base:"
            type="Number"
            name="walkingBase"
            value={walkingBase}
            onChange={(e) => setWalkingBase(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Cycling Base:"
            type="Number"
            name="cyclingBase"
            value={cyclingBase}
            onChange={(e) => setCyclingBase(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Riding Base:"
            type="Number"
            name="ridingBase"
            value={ridingBase}
            onChange={(e) => setRidingBase(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="title">Others:</div>
        <div className="col-md-3">
          <Input
            label="Drop off Time:"
            type="Number"
            name="dropOffTime"
            value={dropOffTime}
            onChange={(e) => setDropOffTime(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Pickup Time:"
            type="Number"
            name="pickUpTime"
            value={pickUpTime}
            onChange={(e) => setpickUpTime(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Special Condition:"
            type="Number"
            name="specialCondition"
            value={specialCondition}
            onChange={(e) => setSpecialCondition(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Wage:"
            type="Number"
            name="wage"
            value={wage}
            onChange={(e) => setWage(e.target.value)}
          />
        </div>
        <div className="col-md-12 margin-top-bottom">
          <Input
            label="Special condition note:"
            type="String"
            name="SpecialConditionNote"
            value={specialConditionNote}
            onChange={(e) => setSpecialConditionNote(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="title">Time of The Day</div>
        <div className="col-md-3">
          <Input
            label="Segment One:"
            type="Number"
            name="segmentTimeOne"
            value={segmentTimeOne}
            onChange={(e) => setSegmentTimeOne(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Segment Two:"
            type="Number"
            name="segmentTimeTwo"
            value={segmentTimeTwo}
            onChange={(e) => setSegmentTimeTwo(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Segment Three:"
            type="Number"
            name="segmentTimeThree"
            value={segmentTimeThree}
            onChange={(e) => setSegmentTimeThree(e.target.value)}
          />
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-md-12 margin-top-bottom">
          <Input
            label="Change note:"
            type="String"
            name="changeNote"
            value={changeNote}
            onChange={(e) => setChangeNote(e.target.value)}
          />
        </div>
      </div>
      <ul>
        <li>
          <button className="btn btn-primary" onClick={() => onSubmit()}>
            save
          </button>
        </li>

        {history !== undefined ? (
          <li>
            <button
              className="btn btn-primary"
              onClick={() => {
                props.history.push("/home/pricingObject/history", {
                  history: history,
                });
              }}
            >
              History
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  );
}

export default PricingObject;
