import React from "react";
import ApproveMotorbikeStatus from "./approveMotorbikeStatus";
import MotorbikeStatusPopups from "./motorbikeStatusPopups";
import RestrictedMotorbikePopup from "./RestrictedMotorbikePopup";
import { log } from "../../../helper/helper";

function MotorbikeLatestStatus({ motor, props, handleReloadSingle }) {
  const handleStatus = () => {
    switch (motor.motorbike_latest_status) {
      case "Approved":
        if (motor.is_rego_date_still_valid) {
          if (motor.is_insurance_policy_date_still_valid) {
            return (
              <div className="value motorbike-status">
                <span className="approved-valid">Approved</span>
              </div>
            );
          } else {
            return (
              <div className="value motorbike-status">
                <span className="approved-invalid-expired">
                  Insurance Policy Expired
                </span>
              </div>
            );
          }
        } else {
          return (
            <div className="value motorbike-status">
              <span className="approved-invalid-expired">
                Registration Expired
              </span>
            </div>
          );
        }
      case "Processing":
        return (
          <div className="value motorbike-status">
            <span className="processing">Processing</span>
          </div>
        );
      case "Further Investigation":
        return (
          <div className="value motorbike-status">
            <span className="further-investigation">Further Investigation</span>
          </div>
        );
      case "Restricted":
        return (
          <div className="value motorbike-status">
            <span className="restricted">Restricted</span>
          </div>
        );
      case "Rejected":
        return (
          <div className="value motorbike-status">
            <span className="rejected">Rejected</span>
          </div>
        );
      case "Policy Expired":
        return (
          <div className="value motorbike-status">
            <span className="expired">Policy Expired</span>
          </div>
        );
      case "Rego Expired":
        return (
          <div className="value motorbike-status">
            <span className="expired">Rego Expired</span>
          </div>
        );

      case "Removed":
        return (
          <div className="value motorbike-status">
            <span className="removed">Removed</span>
          </div>
        );
      default:
        break;
    }
  };

  const handleMotorbikeStatusActions = () => {
    switch (motor.motorbike_latest_status) {
      case "Restricted":
        return (
          <RestrictedMotorbikePopup
            motor={motor}
            props={props}
            handleReloadSingle={handleReloadSingle}
          />
        );
      case "Approved":
        return (
          <ApproveMotorbikeStatus
            motor={motor}
            props={props}
            handleReloadSingle={handleReloadSingle}
          />
        );
      case "Processing":
        if (motor.is_allow_show_edit_button_result) {
          return (
            <MotorbikeStatusPopups
              motor={motor}
              props={props}
              handleReloadSingle={handleReloadSingle}
            />
          );
        } else {
          return null;
        }
      default:
        break;
    }
  };

  return (
    <div className="admin-info-components">
      <div className="row">
        <div className="col-md-4">
          <h5 className="admin-header-info">Motorbike Latest Status</h5>
        </div>
        <div className="col-md-4">{handleStatus()}</div>
        <div className="col-md-4">{handleMotorbikeStatusActions()}</div>
      </div>
    </div>
  );
}

export default MotorbikeLatestStatus;
