import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as service from "./parcelServices.js";
import "./parcel.scss";
import Alart from "../common/alart";
import { log } from "../../helper/helper";

function ParcelList(props) {
  const [isLoading, setLoading] = useState(true);
  const [parcelList, setParcelList] = useState([]);
  const [alartTriggerMessage, setAlartTriggerMessage] = useState(false);
  const [parcelIdToDelete, setParcelIdToDelete] = useState("");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
    getParcels();
  }, []);

  const getParcels = async () => {
    try {
      const result = await service.adminGetParcels();
      log(result.data);
      setLoading(false);

      if (result) {
        switch (result.data.code) {
          case 200:
            setParcelList(result.data.parcelsList);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminDeleteParcel = async (uid) => {
    log(uid);
    setLoading(true);
    try {
      const result = await service.adminDeleteParcel(uid);
      log(result.data);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setParcelIdToDelete("");
            getParcels();
            setAlartTriggerMessage(false);
            return;
          case 401:
            setLoading(false);
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      log(error);
      toast.error(error.message);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleAlart = () => {
    adminDeleteParcel(parcelIdToDelete);
  };

  return (
    <div className="parcel-list">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Size</th>
                <th>Weight</th>
                <th>
                  <img
                    src={require("../../assets/walking.png")}
                    alt="walking"
                  />
                </th>
                <th>
                  <img
                    src={require("../../assets/cycling.png")}
                    alt="cycling"
                  />
                </th>
                <th>
                  <img src={require("../../assets/riding.png")} alt="riding" />
                </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {parcelList.map((parcel) => (
                <tr key={parcel.parcel_id}>
                  <td>{parcel.parcel_type}</td>
                  <td>{parcel.parcel_description}</td>
                  <td>
                    {parcel.parcel_min_weight + "-" + parcel.parcel_max_weight}
                  </td>
                  {parcel.vehicle_type.walking ? (
                    <td className="true">
                      <span>true</span>
                    </td>
                  ) : (
                    <td className="false">
                      <span>false</span>
                    </td>
                  )}
                  {parcel.vehicle_type.cycling ? (
                    <td className="true">
                      <span>true</span>
                    </td>
                  ) : (
                    <td className="false">
                      <span>false</span>
                    </td>
                  )}
                  {parcel.vehicle_type.riding ? (
                    <td className="true">
                      <span>true</span>
                    </td>
                  ) : (
                    <td className="false">
                      <span>false</span>
                    </td>
                  )}
                  <td>
                    <button
                      className="search-view"
                      onClick={() => {
                        props.history.push("/home/parcelList/parcel", {
                          parcel: parcel,
                          newParcel: false,
                        });
                      }}
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <button
                      className="search-view delete"
                      // onClick={() => handleDeleteParcel(parcel.parcel_id)}
                      onClick={() => {
                        const id = parcel.parcel_id;
                        setParcelIdToDelete(id);
                        setParcelIdToDelete((id) => {
                          return id;
                        });
                        setAlartTriggerMessage(true);
                      }}
                    >
                      delete
                    </button>
                  </td>
                  <td>
                    {parcel.parcel_type_history !== null ? (
                      <button
                        className="search-view"
                        onClick={() => {
                          props.history.push("/home/parcelList/parcelHistory", {
                            parcel: parcel.parcel_type_history,
                          });
                        }}
                      >
                        History
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ul>
            <li>
              <button
                className="action-button"
                onClick={() => {
                  props.history.push("/home/parcelList/parcel", {
                    newParcel: true,
                  });
                }}
              >
                Add Parcel
              </button>
            </li>
          </ul>
        </div>
      </div>
      {alartTriggerMessage ? (
        <Alart
          message="You are deleteing the parcel, Are you sure?"
          actionTitle="Yes"
          handleAlartAction={() => handleAlart()}
          isNoActive={true}
          handleCancelAction={() => setAlartTriggerMessage(false)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ParcelList;
