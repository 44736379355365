import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../motorbike.scss";
import * as service from "../findMotorbikeService";
import { log } from "../../../helper/helper";

function RestrictedMotorbikePopup({ motor, props, handleReloadSingle }) {
  const [isLoading, setLoading] = useState(false);
  const [motorStatus, setMotorStatus] = useState(0);
  const [
    textareaValueRejectedMotorStatus,
    setTextareaValueRejectedMotorStatus,
  ] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const inputHasChanged = () => {
    setSelected(!selected);
  };

  const handleMotorStatus = async () => {
    switch (motorStatus) {
      case 0:
        setLoading(true);
        try {
          const result = await service.adminMotorbikeApprove(
            motor.motorbike_uid,
            textareaValueRejectedMotorStatus
          );
          log(result);
          setLoading(false);
          if (result) {
            switch (result.data.code) {
              case 200:
                toast.success(result.data.message);
                handleReloadSingle();
                return;
              case 401:
                toast.error(result.data.message);
                return props.history.replace("/login");
              case 500:
                return toast.error(result.data.message);
              default:
                return toast.error(result.data.message);
            }
          }
        } catch (error) {
          toast.error(error.message);
        }
        setLoading(false);
        break;
      case 1:
        setLoading(true);
        try {
          const result = await service.adminMotorbikeFurtherInvestigation(
            motor.motorbike_uid,
            textareaValueRejectedMotorStatus
          );
          log(result);
          setLoading(false);
          if (result) {
            switch (result.data.code) {
              case 200:
                toast.success(result.data.message);
                handleReloadSingle();
                return;
              case 401:
                toast.error(result.data.message);
                return props.history.replace("/login");
              case 500:
                return toast.error(result.data.message);
              default:
                return toast.error(result.data.message);
            }
          }
        } catch (error) {
          toast.error(error.message);
        }
        setLoading(false);
        break;
      case 2:
        setLoading(true);
        try {
          const result = await service.adminMotorbikeReject(
            motor.motorbike_uid,
            textareaValueRejectedMotorStatus
          );
          log(result);
          setLoading(false);
          if (result) {
            switch (result.data.code) {
              case 200:
                toast.success(result.data.message);
                handleReloadSingle();
                return;
              case 401:
                toast.error(result.data.message);
                return props.history.replace("/login");
              case 500:
                return toast.error(result.data.message);
              default:
                return toast.error(result.data.message);
            }
          }
        } catch (error) {
          toast.error(error.message);
        }
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const handleSubmitButtonShow = () => {
    if (textareaValueRejectedMotorStatus.length > 2 && selected) {
      return (
        <Button variant="primary" onClick={() => handleMotorStatus()}>
          Submit
        </Button>
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      {renderSpinner()}

      <Button variant="primary" className="edit-btn" onClick={handleShow}>
        Manage
      </Button>
      <Modal
        show={show}
        className="crm-modal modal-popup-motorbike-rejected-status"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage the Motorbike</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6}>
                <div className="modal-radio">
                  <input
                    type="radio"
                    value={motorStatus === 0}
                    name="motorStatus"
                    checked={motorStatus === 0}
                    onChange={() => {
                      setSelected(false);
                      setTextareaValueRejectedMotorStatus("");
                      setMotorStatus(0);
                    }}
                  />
                  <label htmlFor="">Approve the Motorbike</label>
                  <br />
                  <input
                    type="radio"
                    value={motorStatus === 1}
                    name="motorStatus"
                    checked={motorStatus === 1}
                    onChange={() => {
                      setSelected(false);
                      setTextareaValueRejectedMotorStatus("");
                      setMotorStatus(1);
                    }}
                  />
                  <label htmlFor="">Further Investigation</label>
                  <br />
                  <input
                    type="radio"
                    value={motorStatus === 2}
                    name="motorStatus"
                    checked={motorStatus === 2}
                    onChange={() => {
                      setSelected(false);
                      setTextareaValueRejectedMotorStatus("");
                      setMotorStatus(2);
                    }}
                  />
                  <label htmlFor="">Reject the motorbike</label>
                </div>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <textarea
                  className="textarea-info"
                  name="moreInfo"
                  id="moreInfo"
                  rows="10"
                  value={textareaValueRejectedMotorStatus}
                  onChange={(e) => {
                    const value = e.target.value;
                    setTextareaValueRejectedMotorStatus(value);
                  }}
                ></textarea>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <div className="toggleSwitch">
                  <div className="container">
                    <span className="switch-label">Confirm</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selected}
                        name="allowedByBearer"
                        id="allowedByBearer"
                        onChange={() => inputHasChanged()}
                      />
                      <label className="label" htmlFor="allowedByBearer">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {handleSubmitButtonShow()}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RestrictedMotorbikePopup;
