import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./../../config.json";
import { log } from "../../helper/helper";

export function adminCustomerInbox(isQueryByDate, startDate, endDate) {
  const data = {
    is_query_by_date: isQueryByDate,
    start_date: startDate,
    end_date: endDate,
  };
  const functions = getFunctions();
  const customer = httpsCallable(functions, config.adminCustomerInbox);
  log(data);
  return customer(data);
}
