import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import loginBackground from "../../assets/login-background.jpg";
import "./login.scss";
import * as loginService from "./loginServices";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";

class ConfirmMobile extends Form {
  state = {
    data: { smsCode: "" },
    errors: {},
    isLoading: false,
  };

  schema = {
    smsCode: Joi.number().required().label("SMS Code"),
  };
  componentDidMount() {
    log(this.props.location);
    if (this.props.location.state == null) {
      this.props.history.replace("/login");
    }

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        this.props.history.replace("/login");
      }
    });
  }

  doSubmit = async () => {
    const smsCode = this.state.data.smsCode;
    this.setState({ isLoading: true });
    try {
      const result = await loginService.adminConfirmSMS(smsCode);
      log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            this.checkLoginPermission();
            return;
          case 401:
            this.setState({ isLoading: false });
            return toast.error(result.data.message);
          case 500:
            this.setState({ isLoading: false });
            return toast.error(result.data.message);
          default:
            this.setState({ isLoading: false });
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error.message);
      this.setState({ isLoading: false });
      toast.error("There is a problem.");
    }
  };

  checkLoginPermission = async () => {
    try {
      const result = await loginService.adminLoginPermission();
      log(result);
      if (result) {
        this.setState({ isLoading: false });
        switch (result.data.code) {
          case 200:
            const userData = result.data;

            localStorage.setItem("riderCount", userData.total_riders);
            localStorage.setItem(
              "customerCount",
              userData.total_individual_customers
            );
            localStorage.setItem(
              "businessCount",
              userData.total_business_customers
            );
            localStorage.setItem("firstName", userData.given_name);
            localStorage.setItem("lastName", userData.last_name);
            localStorage.setItem("avatar", userData.avatar_url);
            localStorage.setItem("isLoginSuccessfully", true);
            const sessionDeadLine = new Date();
            localStorage.setItem("startTime", sessionDeadLine);
            this.props.history.replace("/home/dashboard", {
              userLogedIn: true,
            });
            break;
          case 401:
            toast.error(result.data.message);
            break;
          case 500:
            toast.error(result.data.message);
            break;
          default:
            toast.error(result.data.message);
            break;
        }
      }
    } catch (error) {
      log(error.message);
      toast.error("There is a problem.");
    }
    this.setState({ isLoading: false });
  };

  render() {
    const isLoading = this.state.isLoading;
    const renderAuthButton = () => {
      if (isLoading) {
        return <Spinner />;
      }
    };
    return (
      <div>
        {renderAuthButton()}
        <ToastContainer />
        <div className="container loginForm">
          <div className="row loginRow">
            <div className="col-md-6 d-none d-sm-none d-md-block">
              <div className="loginImageHolder">
                <img
                  src={loginBackground}
                  className="loginImage"
                  alt="background"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="loginInput">
                <h6>Verification code(sms) will be send to:</h6>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput("smsCode", "", "String", "SMS Code")}
                  {this.renderButton("Confirm")}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmMobile;
