import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import * as service from "../findRider/findRiderService";
import "./riderList.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Input from "../common/input";
import Alart from "../common/alart";
import { log } from "../../helper/helper";

function AvatarConfirmation(props) {
  const [isLoading, setLoading] = useState(false);
  const rider = props.history.location.state
    ? props.history.location.state.rider
    : null;
  const [certificateUrl, setCertificateUrl] = useState("");
  const [profileAvatar, setProfileAvatar] = useState("");
  const [profileFullName, setProfileFullName] = useState("");
  const [profileFullNameError, setProfileFullNameError] = useState("");
  const [avatarMatch, setAvatarMatch] = useState(0);
  const [avatarVerifyDisable, setAvatarVerifyDisable] = useState(false);
  const [zeroStepRejectionText, setZeroStepRejectionText] = useState("");
  const [zeroStepRejectBtnDisable, setZeroStepRejectBtnDisable] =
    useState(true);
  const [furtherInvestigation, setFurtherInvestigation] = useState(true);
  const [avatarGenuine, setAvatarGenuine] = useState(0);
  const [alartTriggerMessage, setAlartTriggerMessage] = useState("");

  const getRiderCerticateUrl = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setCertificateUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const getRiderProfileUrl = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setProfileAvatar(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });

    if (rider === null) {
      props.history.replace("/home/allRiders");
    } else {
      if (certificateUrl === "") {
        if (rider ? rider.id_photo_card_url : "") {
          getRiderCerticateUrl(rider ? rider.id_photo_card_url : "");
        }
      }
      if (profileAvatar === "") {
        if (rider ? rider.avatar_url : "") {
          getRiderProfileUrl(rider ? rider.avatar_url : "");
        }
      }
    }
  }, []);

  const handleAvatarProfile = (value) => {
    setAvatarGenuine(value);
    handleBtnsActivate(avatarMatch, value, zeroStepRejectionText);
  };

  const handleAvatarMatch = (value) => {
    setAvatarMatch(value);
    handleBtnsActivate(value, avatarGenuine, zeroStepRejectionText);
  };

  const handleBtnsActivate = (photoId, cardId, textarea) => {
    setAvatarVerifyDisable(true);
    setZeroStepRejectBtnDisable(true);
    setFurtherInvestigation(true);
    if (photoId === 0) {
      if (cardId === 0) {
        setAvatarVerifyDisable(false);
      } else if (cardId === 1) {
        if (textarea.replace(/\s/g, "").length > 1) {
          setZeroStepRejectBtnDisable(false);
        }
      } else {
        if (textarea.replace(/\s/g, "").length > 1) {
          setFurtherInvestigation(false);
        }
      }
    } else if (photoId === 1) {
      if (textarea.replace(/\s/g, "").length > 1) {
        setZeroStepRejectBtnDisable(false);
      }
    } else {
      if (cardId === 0) {
        if (textarea.replace(/\s/g, "").length > 1) {
          setFurtherInvestigation(false);
        }
      } else if (cardId === 1) {
        if (textarea.replace(/\s/g, "").length > 1) {
          setZeroStepRejectBtnDisable(false);
        }
      } else {
        if (textarea.replace(/\s/g, "").length > 1) {
          setFurtherInvestigation(false);
        }
      }
    }
  };

  const verifyAvatarProfile = async () => {
    setProfileFullNameError("");
    if (profileFullName.replace(/\s/g, "") === "") {
      return setProfileFullNameError("Must contain English Letter(s) only");
    }

    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewAvatarAndPhotoID(
        rider.rider_uid,
        profileFullName.toLocaleUpperCase()
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            const nextStep = result.data.next_verification_step;
            switch (nextStep) {
              case 2:
                props.history.push("/home/allRiders/legalDetail", {
                  rider: rider,
                });
                break;
              case 3:
                props.history.replace("/home/allRiders/rightToWork", {
                  rider: rider,
                });
                break;
              default:
                break;
            }
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminRiderDocumentReviewReject = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewReject(
        rider.rider_uid,
        note,
        1,
        ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminRiderFurtherInvestigation = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewFurtherInvestigation(
        rider.rider_uid,
        note,
        1,
        ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleZeroRejectionText = (value) => {
    setZeroStepRejectionText(value);
    handleBtnsActivate(avatarMatch, avatarGenuine, value);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleAlart = () => {
    setCertificateUrl("");
    setProfileAvatar("");
    setProfileFullName("");
    setZeroStepRejectionText("");
    setAlartTriggerMessage("");
    props.history.goBack();
  };

  return (
    <div className="avatar-confirm new-rider-steps">
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="steps">
            <div className="step number active">1</div>
            <div className="step line"></div>
            <div className="step number">2</div>
            <div className="step line"></div>
            <div className="step number">3</div>
            <div className="step line"></div>
            <div className="step number">4</div>
          </div>
        </div>
        <div className="row">
          <div className="header-title">
            <h1>Avatar & Photo ID</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="certificate">
                  <img
                    src={
                      certificateUrl !== ""
                        ? certificateUrl
                        : require("../../assets/card-def.png")
                    }
                    alt="certificate"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-radio-wrapper">
                  <div className="title">
                    Is the provided ID (above) seems genuine?
                  </div>
                  <input
                    className="radio"
                    type="radio"
                    value={avatarGenuine === 0}
                    name="avatarGenuine"
                    checked={avatarGenuine === 0}
                    onChange={() => handleAvatarProfile(0)}
                  />{" "}
                  <label htmlFor=""> Yes</label>
                  <br />
                  <input
                    className="radio"
                    type="radio"
                    value={avatarGenuine === 1}
                    name="avatarGenuine"
                    checked={avatarGenuine === 1}
                    onChange={() => handleAvatarProfile(1)}
                  />{" "}
                  <label htmlFor=""> No</label>
                  <br />
                  <input
                    className="radio"
                    type="radio"
                    value={avatarGenuine === 2}
                    name="avatarGenuine"
                    checked={avatarGenuine === 2}
                    onChange={() => handleAvatarProfile(2)}
                  />{" "}
                  <label htmlFor="">Unsure</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <div className="avatar-image">
                  <img
                    src={
                      profileAvatar !== ""
                        ? profileAvatar
                        : require("../../assets/profile.png")
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="input-text-wrapper">
                <Input
                  label="Full Name as appeared on ID:"
                  type="String"
                  name="profileFullName"
                  value={profileFullName}
                  onChange={(e) => {
                    setProfileFullNameError("");
                    setProfileFullName(e.target.value);
                  }}
                  error={profileFullNameError}
                />
              </div>
              <div className="input-radio-wrapper">
                <div className="title">
                  Are the ID Photo and person's Avatar match?
                </div>
                <input
                  type="radio"
                  value={avatarMatch === 0}
                  name="avatarMatch"
                  checked={avatarMatch === 0}
                  onChange={() => handleAvatarMatch(0)}
                />{" "}
                <label htmlFor=""> Yes</label>
                <br />
                <input
                  type="radio"
                  value={avatarMatch === 1}
                  name="avatarMatch"
                  checked={avatarMatch === 1}
                  onChange={() => handleAvatarMatch(1)}
                />{" "}
                <label htmlFor=""> No</label>
                <br />
                <input
                  type="radio"
                  value={avatarMatch === 2}
                  name="avatarMatch"
                  checked={avatarMatch === 2}
                  onChange={() => handleAvatarMatch(2)}
                />{" "}
                <label htmlFor="">Unsure</label>
              </div>
              <div className="col-md-12">
                <div className="group-btn-verify">
                  <button
                    onClick={() => verifyAvatarProfile()}
                    disabled={avatarVerifyDisable}
                  >
                    Verify
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <div className="text-area-info">
                  <div>Note:</div>
                  <textarea
                    className="textarea-info"
                    name="moreInfo"
                    id="moreInfo"
                    cols="30"
                    rows="10"
                    value={zeroStepRejectionText}
                    onChange={(e) => handleZeroRejectionText(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="group-btn-reject">
                <div className="row">
                  <div className="col-md-4">
                    <button
                      onClick={() =>
                        adminRiderDocumentReviewReject(zeroStepRejectionText)
                      }
                      disabled={zeroStepRejectBtnDisable}
                    >
                      Reject
                    </button>
                  </div>
                  <div className="col-md-8">
                    <button
                      onClick={() =>
                        adminRiderFurtherInvestigation(zeroStepRejectionText)
                      }
                      disabled={furtherInvestigation}
                      className="request"
                    >
                      Request User For More Information
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alartTriggerMessage !== "" ? (
        <Alart
          message={alartTriggerMessage}
          actionTitle="Ok"
          handleAlartAction={() => handleAlart()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default AvatarConfirmation;
