import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import * as service from "../findRider/findRiderService";
import "./riderList.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Input from "../common/input";
import Alart from "../common/alart";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { log } from "../../helper/helper";

function PoliceCheck(props) {
  const rider = props.history.location.state
    ? props.history.location.state.rider
    : null;
  const [isLoading, setLoading] = useState(false);
  const [alartTriggerMessage, setAlartTriggerMessage] = useState("");

  const [policeCheckImageUrl, setPoliceCheckImageUrl] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [dateOfIssueError, setDateOfIssueError] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [gender, setGender] = useState(0);
  const [isLegalAddress, setLegalAddress] = useState(0);

  const legalAddress = rider
    ? rider.residential_address_segmented.street_no +
      " " +
      rider.residential_address_segmented.street_name +
      " " +
      rider.residential_address_segmented.region_area +
      " " +
      rider.residential_address_segmented.state +
      " " +
      rider.residential_address_segmented.post_code
    : "";
  const [policeCheckResult, setPoliceCheckResult] = useState(0);
  const [employerName, setEmployerName] = useState(0);
  const [isPoliceCheckBtnDisable, setPoliceCheckBtnDisable] = useState(false);
  const [rejectionText, setRejectionText] = useState("");
  const [isRejectBtnDisable, setRejectBtnDisable] = useState(true);
  const [isVerifyBtnDisable, setVerifyBtnDisable] = useState(false);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const getPoliceCheckImage = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setPoliceCheckImageUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      } else {
        if (rider === null) {
          props.history.replace("/home/allRiders");
        } else {
          if (policeCheckImageUrl === "") {
            if (rider ? rider.ncc.police_doc_url : "") {
              getPoliceCheckImage(rider ? rider.ncc.police_doc_url : "");
            }
          }
        }
      }
    });
  }, []);

  const handleAlart = () => {
    props.history.replace("/home/allRiders");
  };

  const handleLegalAddress = () => {
    setLegalAddress(0);
    if (policeCheckResult === 0 && employerName === 0) {
      setPoliceCheckBtnDisable(false);
    } else {
      setPoliceCheckBtnDisable(true);
    }
  };

  const handlePoliceCheckResult = () => {
    setPoliceCheckResult(0);
    if (isLegalAddress === 0 && employerName === 0) {
      setPoliceCheckBtnDisable(false);
    } else {
      setPoliceCheckBtnDisable(true);
    }
  };

  const handleEmployerName = () => {
    setEmployerName(0);
    if (policeCheckResult === 0 && isLegalAddress === 0) {
      setPoliceCheckBtnDisable(false);
    } else {
      setPoliceCheckBtnDisable(true);
    }
  };

  const handleTextAreaChange = (value) => {
    setRejectionText(value);
    if (value.replace(/\s/g, "").length > 0) {
      setRejectBtnDisable(false);
    } else {
      setRejectBtnDisable(true);
    }
  };

  const documentReviewVerifyPoliceCheck = async () => {
    setDateOfIssueError("");
    if (dateOfIssue === "") {
      return setDateOfIssueError(
        "The document MUST be valid for at least three calendar months"
      );
    }

    setFullNameError("");
    if (fullName.replace(/\s/g, "") === "") {
      return setFullNameError("Must contain English Letter(s) only");
    }

    setDateOfBirthError("");
    if (dateOfBirth === "") {
      return setDateOfBirthError("Pick a date to continue");
    }

    const sortedDateOfIssue = new Date(dateOfIssue);
    const momentDateOfIssue = Moment(sortedDateOfIssue).format("YYYY-MM-DD");

    const sortedDateOfBirth = new Date(dateOfBirth);
    const momentDateOfBirth = Moment(sortedDateOfBirth).format("YYYY-MM-DD");

    const theGender = gender === 0 ? "Male" : "Female";
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewVerifyPoliceCheck(
        rider.rider_uid,
        momentDateOfIssue,
        fullName.toLocaleUpperCase(),
        momentDateOfBirth,
        theGender
      );
      log(result);

      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setTimeout(function () {
              props.history.replace("/home/allRiders");
              setLoading(false);
            }, 500);
            return;
          case 401:
            setLoading(false);
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const adminRiderDocumentReviewReject = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewReject(
        rider.rider_uid,
        note,
        4,
        ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminRiderFurtherInvestigation = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewFurtherInvestigation(
        rider.rider_uid,
        note,
        4,
        ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="police-check new-rider-steps">
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="steps">
            <div className="step number active">1</div>
            <div className="step line active"></div>
            <div className="step number active">2</div>
            <div className="step line active"></div>
            <div className="step number active">3</div>
            <div className="step line active"></div>
            <div className="step number active">4</div>
          </div>
        </div>
        <div className="row">
          <div className="header-title">
            <h1>Police Check</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <iframe src={policeCheckImageUrl} title="pdf" />
          </div>
          <div className="col-md-5">
            <div className="input-text-wrapper">
              <label htmlFor="dateTime">Date of Issue(certificate):</label>
              <DatePicker
                selected={dateOfIssue}
                onChange={(date) => {
                  setDateOfIssueError("");
                  setDateOfIssue(date);
                }}
                dateFormat="dd-MM-yyyy"
                className="form-control"
                minDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    new Date().getDate()
                  )
                }
                maxDate={new Date()}
              />
              {dateOfIssueError && (
                <div className="alert alert-danger custom-error">
                  {dateOfIssueError}
                </div>
              )}
            </div>
            <div className="input-text-wrapper">
              <Input
                label="Full Name:"
                type="String"
                name="fullName"
                value={fullName}
                error={fullNameError}
                onChange={(e) => {
                  setFullNameError("");
                  setFullName(e.target.value);
                }}
                disabled={isVerifyBtnDisable}
              />
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="input-text-wrapper">
                  <label htmlFor="dateTime">Date of Birth:</label>
                  <DatePicker
                    selected={dateOfBirth}
                    onChange={(date) => setDateOfBirth(date)}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                  />
                  {dateOfBirthError && (
                    <div className="alert alert-danger custom-error">
                      {dateOfBirthError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-radio-wrapper">
                  <div className="title">Gender:</div>
                  <input
                    type="radio"
                    value={gender === 0}
                    name="gender"
                    checked={gender === 0}
                    onChange={() => setGender(0)}
                  />{" "}
                  <label htmlFor="">Male</label>
                  <br />
                  <input
                    type="radio"
                    value={gender === 1}
                    name="gender"
                    checked={gender === 1}
                    onChange={() => setGender(1)}
                  />{" "}
                  <label htmlFor="">Female</label>
                </div>
              </div>
            </div>
            <div className="input-radio-wrapper">
              <div className="title">Residential Address:</div>
              <input
                type="radio"
                value={isLegalAddress === 0}
                name="isLegalAddress"
                checked={isLegalAddress === 0}
                onChange={() => handleLegalAddress()}
              />{" "}
              <label htmlFor=""> {legalAddress}</label>
              <br />
              <input
                type="radio"
                value={isLegalAddress === 1}
                name="isLegalAddress"
                checked={isLegalAddress === 1}
                onChange={() => {
                  setLegalAddress(1);
                  setPoliceCheckBtnDisable(true);
                }}
              />{" "}
              <label htmlFor=""> Other</label>
            </div>
            <div className="input-radio-wrapper">
              <div className="title">Police Check Result:</div>
              <input
                type="radio"
                value={policeCheckResult === 0}
                name="policeCheckResult"
                checked={policeCheckResult === 0}
                onChange={() => handlePoliceCheckResult()}
              />{" "}
              <label htmlFor="">No Disclosable Court Outcomes</label>
              <br />
              <input
                type="radio"
                value={policeCheckResult === 1}
                name="policeCheckResult"
                checked={policeCheckResult === 1}
                onChange={() => {
                  setPoliceCheckResult(1);
                  setPoliceCheckBtnDisable(true);
                }}
              />{" "}
              <label htmlFor="">Disclosable Court Outcomes</label>
            </div>
            <div className="input-radio-wrapper">
              <div className="title">Employe Name:</div>
              <input
                type="radio"
                value={employerName === 0}
                name="employerName"
                checked={employerName === 0}
                onChange={() => handleEmployerName()}
              />{" "}
              <label htmlFor="">Bearer</label>
              <br />
              <input
                type="radio"
                value={employerName === 1}
                name="employerName"
                checked={employerName === 1}
                onChange={() => {
                  setEmployerName(1);
                  setPoliceCheckBtnDisable(true);
                }}
              />{" "}
              <label htmlFor="">Other</label>
            </div>
            <div className="group-btn-verify">
              <button
                onClick={() => documentReviewVerifyPoliceCheck()}
                disabled={isPoliceCheckBtnDisable}
              >
                Verify
              </button>
            </div>
            <div className="text-area-info">
              <div>Note:</div>
              <textarea
                className="textarea-info"
                name="moreInfo"
                id="moreInfo"
                cols="30"
                rows="10"
                value={rejectionText}
                onChange={(e) => handleTextAreaChange(e.target.value)}
              ></textarea>
            </div>
            <div className="group-btn-reject">
              <div className="row">
                <div className="col-md-4">
                  <button
                    onClick={() =>
                      adminRiderDocumentReviewReject(rejectionText)
                    }
                    disabled={isRejectBtnDisable}
                  >
                    Reject
                  </button>
                </div>
                <div className="col-md-8">
                  <button
                    onClick={() =>
                      adminRiderFurtherInvestigation(rejectionText)
                    }
                    disabled={isRejectBtnDisable}
                    className="request"
                  >
                    Request User For More Information
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alartTriggerMessage !== "" ? (
        <Alart
          message={alartTriggerMessage}
          actionTitle="Ok"
          handleAlartAction={() => handleAlart()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default PoliceCheck;
