import React, { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./findCustomer.scss";

function CustomerList(props) {
  const state = props.history.location.state
    ? props.history.location.state
    : null;
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
    if (state === null) {
      return props.history.replace("/");
    }
  }, []);
  return (
    <div className="search-list">
      <h6>
        Found {state.customers.length}{" "}
        {state.customers.length > 1 ? "result" : "results"} for :{" "}
        {state.inputString ? state.inputString : ""}
      </h6>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Customer Type </th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state.customers.map((customer) => (
            <tr key={customer.customer_uid}>
              <td>{customer.given_name + " " + customer.last_name}</td>
              <td>{customer.email_address}</td>
              <td>{customer.mobile_number}</td>
              <td>{customer.user_type}</td>
              {customer.is_account_deleted ? (
                <td className="deleted">
                  <span>Deleted</span>
                </td>
              ) : (
                <td className="active">
                  <span>Active</span>
                </td>
              )}
              <td>
                <button
                  className="search-view"
                  onClick={() =>
                    props.history.push(
                      "/home/findCustomer/customerList/customerSingle",
                      {
                        customerId: customer.customer_uid,
                      }
                    )
                  }
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CustomerList;
