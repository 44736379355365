import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { log } from "../../../helper/helper";
import Spinner from "../../common/spinner";
import * as service from "../findMotorbikeService";

function HiddenByUser({ header, value, id, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const handleShow = () => setShow(true);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleDefault = () => {
    setSelected(value);
  };

  const inputHasChanged = () => {
    setBtnDisabled(!btnDisabled);
    setSelected(!selected);
  };

  const valueDecision = () => {
    if (value === true) {
      return (
        <div className="admin-header-info-value-true">
          <span>True</span>
        </div>
      );
    } else {
      return (
        <div className="admin-header-info-value-false">
          <span>False</span>
        </div>
      );
    }
  };

  const handleMotorHidden = async () => {
    handleClose(true);
    setLoading(true);
    try {
      const result = await service.adminMotorbikeChangeHidden(id, selected);
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setBtnDisabled(true);
            toast.success(result.data.message);
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="admin-info-components">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-4">
          <h5 className="admin-header-info">{header}</h5>
        </div>
        <div className="col-md-4">{valueDecision()}</div>
        <div className="col-md-4">
          <Button
            variant="primary"
            className="edit-btn"
            onClick={() => {
              handleShow();
              handleDefault();
            }}
          >
            Edit
          </Button>
          <Modal show={show} className="allowed-by-user" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="toggleSwitch">
                <div className="container">
                  <span className="switch-label">Status:</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selected}
                      name="allowedByBearer"
                      id="allowedByBearer"
                      onChange={() => inputHasChanged()}
                    />
                    <label className="label" htmlFor="allowedByBearer">
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => handleMotorHidden()}
                disabled={btnDisabled}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default HiddenByUser;
