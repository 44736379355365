import React, { useEffect, useState } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";
import { timestampToDateFull } from "../../helper/helper.jsx";
import HistorySingleComponents from "../parcel/parcelHistorySingleComponents";

function PricingHistorySingle(props) {
  const [time] = useState(
    props.location.state ? props.location.state.key : null
  );
  const [value] = useState(
    props.location.state ? props.location.state.value : null
  );

  log(value);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (value === null) {
      return props.history.replace("/home");
    }
  }, []);

  const handleHistoryValue = () => {
    if (value !== null) {
      return (
        <div>
          <HistorySingleComponents
            header="Admin UID"
            value={value.admin_uid}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Date"
            value={timestampToDateFull(time)}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Data model version"
            value={value.data_model_version}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Change note"
            value={value.change_note}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Cycling base"
            value={value.cycling_base_fare}
            isChanged={value.cycling_base_fare_was_changed}
          />
          <HistorySingleComponents
            header="Walking base"
            value={value.walking_base_fare}
            isChanged={value.walking_base_fare_was_changed}
          />
          <HistorySingleComponents
            header="Riding base"
            value={value.riding_base_fare}
            isChanged={value.riding_base_fare_was_changed}
          />
          <HistorySingleComponents
            header="Drop off time"
            value={value.drop_off_time}
            isChanged={value.drop_off_time_was_changed}
          />
          <HistorySingleComponents
            header="Special condition note"
            value={value.special_condition_note}
            isChanged={value.special_condition_note_was_changed}
          />
          <HistorySingleComponents
            header="Pick up time"
            value={value.pick_up_time}
            isChanged={value.pick_up_time_was_changed}
          />
          <HistorySingleComponents
            header="Special condition"
            value={value.special_condition}
            isChanged={value.special_condition_was_changed}
          />
          <HistorySingleComponents
            header="Wage"
            value={value.wage}
            isChanged={value.wage_was_changed}
          />
          <HistorySingleComponents
            header="Charges on distance, Segment one"
            value={value.charges_on_distance.segment_one}
            isChanged={value.charges_on_distance.segment_one_was_changed}
          />
          <HistorySingleComponents
            header="Charges on distance, Segment two"
            value={value.charges_on_distance.segment_two}
            isChanged={value.charges_on_distance.segment_two_was_changed}
          />
          <HistorySingleComponents
            header="Charges on distance, Segment three"
            value={value.charges_on_distance.segment_three}
            isChanged={value.charges_on_distance.segment_three_was_changed}
          />
          <HistorySingleComponents
            header="Charges on distance, Segment four"
            value={value.charges_on_distance.segment_four}
            isChanged={value.charges_on_distance.segment_four_was_changed}
          />
          <HistorySingleComponents
            header="Time of the day, Segment one"
            value={value.time_of_the_day.segment_one}
            isChanged={value.time_of_the_day.segment_one_was_changed}
          />
          <HistorySingleComponents
            header="Time of the day, Segment two"
            value={value.time_of_the_day.segment_two}
            isChanged={value.time_of_the_day.segment_two_was_changed}
          />
          <HistorySingleComponents
            header="Time of the day, Segment three"
            value={value.time_of_the_day.segment_three}
            isChanged={value.time_of_the_day.segment_three_was_changed}
          />
        </div>
      );
    }
  };

  return <div className="history-single">{handleHistoryValue()}</div>;
}

export default PricingHistorySingle;
