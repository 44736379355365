import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../../assets/profile.png";
import { log } from "../../../helper/helper";

function TransportParcel({ transport, load }) {
  const [imageUrl, setImageUrl] = useState("");
  const avatar =
    transport && transport.parcel_img_url !== null
      ? transport.parcel_img_url
      : "";

  useEffect(() => {
    // Get the download URL
    if (avatar !== "") {
      downloadAvatar();
    }
  }, [load]);

  const downloadAvatar = () => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <h5>Parcel</h5>
      </div>
      <div className="col-md-4">
        <figure>
          {imageUrl !== "" && <img src={imageUrl} alt="parcel" />}
        </figure>
      </div>
      <div className="col-md-4">
        <div className="parcel-info">
          <div className="">
            {transport.parcel_min_weight +
              " ~ " +
              transport.parcel_max_weight +
              " Kg Max"}
          </div>
          <div className="">{transport.parcel_description}</div>
        </div>
      </div>
    </div>
  );
}

export default TransportParcel;
