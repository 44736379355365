import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { log, timestampToDateForTimeLine } from "../../../helper/helper";
import * as utils from "../../../helper/helper";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GoogleMap, Marker } from "@react-google-maps/api";
import first from "../../../assets/rider-position-first-period.svg";
import second from "../../../assets/rider-position-second-period.svg";
import third from "../../../assets/rider-position-third-period.svg";
import forth from "../../../assets/rider-position-forth-period.svg";
import main from "../../../assets/rider-position-main-period.svg";

function OnlineTimeCalculatorTimeSheet(props) {
  const times = props.location.state ? props.location.state.times : null;
  const mobile = props.location.state ? props.location.state.mobile : null;
  const total = props.location.state ? props.location.state.total : null;
  const riderName = props.location.state
    ? props.location.state.riderName
    : null;
  const selectedDate = props.location.state
    ? props.location.state.selectedDate
    : null;
  const [map, setMap] = React.useState(null);
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (times === null) {
      return props.history.replace("/");
    }
  }, []);

  const handleTableColors = (min) => {
    switch (true) {
      case min === 0:
        return "bg-color-0";
      case min >= 1 && min <= 7:
        return "has-time bg-color-1";
      case min >= 8 && min <= 14:
        return "has-time bg-color-2";
      case min >= 15 && min <= 22:
        return "has-time bg-color-3";
      case min >= 23 && min <= 29:
        return "has-time bg-color-4";
      case min >= 30 && min <= 36:
        return "has-time bg-color-5";
      case min >= 37 && min <= 41:
        return "has-time bg-color-6";
      default:
        return "bg-color-0";
    }
  };

  const defaultMapOptions = {
    fullscreenControl: true,
  };

  const onLoadBounds = (map) => {
    if (!map) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    positions.forEach((child) => {
      bounds.extend(new window.google.maps.LatLng(child.lat, child.lng));
    });
    map.fitBounds(bounds);
    setMap(map);
  };

  const loadGoogleMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        onLoad={onLoadBounds}
        onUnmount={onUnmount}
        defaultOptions={defaultMapOptions}
        options={{
          mapId: utils.getMapIdBasedonEnvironment(),
        }}
      >
        {positions.map((marker) => (
          <Marker
            title={"mobile number: " + mobile}
            position={marker}
            key={marker.id}
            icon={{
              url: marker.icon,
            }}
            zIndex={marker.zIndex}
          />
        ))}
        <></>
      </GoogleMap>
    );
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const handleMapView = (time) => {
    setPositions([]);
    setTimeout(() => {
      if (time) {
        const otherProperties = [
          {
            lat: time.lat,
            lng: time.lng,
            icon: main,
            key: 1,
            zIndex: 999,
          },
        ];
        if (time.lat_0_14 && time.lng_0_14) {
          otherProperties.push({
            lat: time.lat_0_14,
            lng: time.lng_0_14,
            icon: first,
            key: 2,
            zIndex: 99,
          });
        }
        if (time.lat_15_29 && time.lng_15_29) {
          otherProperties.push({
            lat: time.lat_15_29,
            lng: time.lng_15_29,
            icon: second,
            key: 3,
            zIndex: 99,
          });
        }
        if (time.lat_30_44 && time.lng_30_44) {
          otherProperties.push({
            lat: time.lat_30_44,
            lng: time.lng_30_44,
            icon: third,
            key: 4,
            zIndex: 99,
          });
        }
        if (time.lat_45_59 && time.lng_45_59) {
          otherProperties.push({
            lat: time.lat_45_59,
            lng: time.lng_45_59,
            icon: forth,
            key: 5,
            zIndex: 99,
          });
        }
        setPositions(otherProperties);
      }
    }, 10);
  };

  return (
    <div className="online-time-calculator-time-show">
      <div className="row">
        <Col md="6" xs="12">
          <h2 className="bearer-name calculator-time-show-title">
            Bearer’s Name: <span>{riderName}</span>
          </h2>
        </Col>
        <Col md="6" xs="12">
          <h2 className="selected-time calculator-time-show-title">
            Date selected: <span>{selectedDate}</span>
          </h2>
        </Col>
      </div>
      <div className="row">
        <Col md="12">
          {positions.length > 0 && (
            <div
              className="map"
              style={{
                width: "100%",
                height: "300px",
                marginBottom: 20,
                padding: "10px",
              }}
            >
              {loadGoogleMap()}
            </div>
          )}
        </Col>
        <Col md="12">
          <div className="search-list">
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Time of the Day</th>
                  <th>00:00 ~ 00:59</th>
                  <th>01:00 ~ 01:59</th>
                  <th>02:00 ~ 02:59</th>
                  <th>03:00 ~ 03:59</th>
                  <th>04:00 ~ 04:59</th>
                  <th>05:00 ~ 05:59</th>
                </tr>
              </thead>
              <tbody>
                <tr key={1}>
                  <td>Last Update</td>
                  <td>
                    {times.time_00
                      ? timestampToDateForTimeLine(times.time_00.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_01
                      ? timestampToDateForTimeLine(times.time_01.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_02
                      ? timestampToDateForTimeLine(times.time_02.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_03
                      ? timestampToDateForTimeLine(times.time_04.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_04
                      ? timestampToDateForTimeLine(times.time_04.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_05
                      ? timestampToDateForTimeLine(times.time_05.timestamp)
                      : "-"}
                  </td>
                </tr>
                <tr key={2}>
                  <td>Online Time</td>
                  <td
                    className={handleTableColors(
                      times.time_00 ? times.time_00.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_00)}
                  >
                    {times.time_00
                      ? Math.round((times.time_00.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_01 ? times.time_01.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_01)}
                  >
                    {times.time_01
                      ? Math.round((times.time_01.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_02 ? times.time_02.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_02)}
                  >
                    {times.time_02
                      ? Math.round((times.time_02.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_03 ? times.time_03.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_03)}
                  >
                    {times.time_03
                      ? Math.round((times.time_03.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_04 ? times.time_04.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_04)}
                  >
                    {times.time_04
                      ? Math.round((times.time_04.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_05 ? times.time_05.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_05)}
                  >
                    {times.time_05
                      ? Math.round((times.time_05.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Time of the Day</th>
                  <th>06:00 ~ 06:59</th>
                  <th>07:00 ~ 07:59</th>
                  <th>08:00 ~ 08:59</th>
                  <th>09:00 ~ 09:59</th>
                  <th>10:00 ~ 10:59</th>
                  <th>11:00 ~ 11:59</th>
                </tr>
              </thead>
              <tbody>
                <tr key={3}>
                  <td>Last Update</td>
                  <td>
                    {times.time_06
                      ? timestampToDateForTimeLine(times.time_06.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_07
                      ? timestampToDateForTimeLine(times.time_07.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_08
                      ? timestampToDateForTimeLine(times.time_08.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_09
                      ? timestampToDateForTimeLine(times.time_09.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_10
                      ? timestampToDateForTimeLine(times.time_10.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_11
                      ? timestampToDateForTimeLine(times.time_11.timestamp)
                      : "-"}
                  </td>
                </tr>
                <tr key={4}>
                  <td>Online Time</td>
                  <td
                    className={handleTableColors(
                      times.time_06 ? times.time_06.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_06)}
                  >
                    {times.time_06
                      ? Math.round((times.time_06.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_07 ? times.time_07.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_07)}
                  >
                    {times.time_07
                      ? Math.round((times.time_07.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_08 ? times.time_08.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_08)}
                  >
                    {times.time_08
                      ? Math.round((times.time_08.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_09 ? times.time_09.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_09)}
                  >
                    {times.time_09
                      ? Math.round((times.time_09.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_10 ? times.time_10.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_10)}
                  >
                    {times.time_10
                      ? Math.round((times.time_10.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_11 ? times.time_11.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_11)}
                  >
                    {times.time_11
                      ? Math.round((times.time_11.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Time of the Day</th>
                  <th>12:00 ~ 12:59</th>
                  <th>13:00 ~ 13:59</th>
                  <th>14:00 ~ 14:59</th>
                  <th>15:00 ~ 15:59</th>
                  <th>16:00 ~ 16:59</th>
                  <th>17:00 ~ 17:59</th>
                </tr>
              </thead>
              <tbody>
                <tr key={5}>
                  <td>Last Update</td>
                  <td>
                    {times.time_12
                      ? timestampToDateForTimeLine(times.time_12.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_13
                      ? timestampToDateForTimeLine(times.time_13.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_14
                      ? timestampToDateForTimeLine(times.time_14.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_15
                      ? timestampToDateForTimeLine(times.time_15.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_16
                      ? timestampToDateForTimeLine(times.time_16.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_17
                      ? timestampToDateForTimeLine(times.time_17.timestamp)
                      : "-"}
                  </td>
                </tr>
                <tr key={6}>
                  <td>Online Time</td>
                  <td
                    className={handleTableColors(
                      times.time_12 ? times.time_12.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_12)}
                  >
                    {times.time_12
                      ? Math.round((times.time_12.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_13 ? times.time_13.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_14)}
                  >
                    {times.time_13
                      ? Math.round((times.time_13.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_14 ? times.time_14.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_14)}
                  >
                    {times.time_14
                      ? Math.round((times.time_14.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_15b ? times.time_15.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_15)}
                  >
                    {times.time_15
                      ? Math.round((times.time_15.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_16 ? times.time_16.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_016)}
                  >
                    {times.time_16
                      ? Math.round((times.time_16.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_17 ? times.time_17.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_17)}
                  >
                    {times.time_17
                      ? Math.round((times.time_17.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>Time of the Day</th>
                  <th>18:00 ~ 18:59</th>
                  <th>19:00 ~ 19:59</th>
                  <th>20:00 ~ 20:59</th>
                  <th>21:00 ~ 21:59</th>
                  <th>22:00 ~ 22:59</th>
                  <th>23:00 ~ 23:59</th>
                </tr>
              </thead>
              <tbody>
                <tr key={7}>
                  <td>Last Update</td>
                  <td>
                    {times.time_18
                      ? timestampToDateForTimeLine(times.time_18.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_19
                      ? timestampToDateForTimeLine(times.time_19.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_20
                      ? timestampToDateForTimeLine(times.time_20.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_21
                      ? timestampToDateForTimeLine(times.time_21.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_22
                      ? timestampToDateForTimeLine(times.time_22.timestamp)
                      : "-"}
                  </td>
                  <td>
                    {times.time_23
                      ? timestampToDateForTimeLine(times.time_23.timestamp)
                      : "-"}
                  </td>
                </tr>
                <tr key={8}>
                  <td>Online Time</td>
                  <td
                    className={handleTableColors(
                      times.time_18 ? times.time_18.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_18)}
                  >
                    {times.time_18
                      ? Math.round((times.time_18.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_19 ? times.time_19.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_19)}
                  >
                    {times.time_19
                      ? Math.round((times.time_19.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_20 ? times.time_20.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_20)}
                  >
                    {times.time_20
                      ? Math.round((times.time_20.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_21 ? times.time_21.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_21)}
                  >
                    {times.time_21
                      ? Math.round((times.time_21.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_22 ? times.time_22.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_22)}
                  >
                    {times.time_22
                      ? Math.round((times.time_22.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                  <td
                    className={handleTableColors(
                      times.time_23 ? times.time_23.counter : 0
                    )}
                    onClick={() => handleMapView(times.time_23)}
                  >
                    {times.time_23
                      ? Math.round((times.time_23.counter * 90) / 60) + " mins"
                      : "-"}
                  </td>
                </tr>
              </tbody>
            </table>
            <h2 className="bearer-name calculator-time-show-title">
              Total Online Time: <span>{total} min</span>
            </h2>
          </div>
        </Col>
      </div>
    </div>
  );
}
export default OnlineTimeCalculatorTimeSheet;
