import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../../assets/profile.png";
import { log } from "../../../helper/helper";

function TransportRider({ transport, setUrl, load }) {
  const [imageUrl, setImageUrl] = useState("");

  const avatar =
    transport && transport.rider_avatar_url ? transport.rider_avatar_url : "";

  useEffect(() => {
    // Get the download URL
    if (avatar !== "") {
      downloadAvatar();
    }
  }, [load]);

  const downloadAvatar = () => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrl(url);
        setUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const riderHasMotor = () => {
    if (transport.assigned_bearer_type === "riding") {
      return (
        <div className="motor-bike">
          <div className="motor-name">
            {transport.motorbike_make + " " + transport.motorbike_model}
          </div>
          <div className="motor-register-info">
            <div className="motor-state">
              {transport.motorbike_registration_state}
            </div>
            <div className="motor-register">
              {transport.motorbike_registration_number}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-md-4">
        <h5>Rider</h5>
      </div>
      <div className="col-md-4">
        <div className="user-info">
          <figure>
            <img src={imageUrl !== "" ? imageUrl : profileImage} alt="avatar" />
          </figure>
          <figcaption className="value">
            {transport && transport.rider_given_name
              ? transport.rider_given_name + " " + transport.rider_last_name
              : ""}
          </figcaption>
        </div>
      </div>
      <div className="col-md-4">{riderHasMotor()}</div>
    </div>
  );
}

export default TransportRider;
