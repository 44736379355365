import React, { useEffect, useState } from "react";
import "./findTransport.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import TransportParcel from "./components/transportParcel";
import TransportCustomer from "./components/transportCustomer";
import TransportRider from "./components/transportRider";
import * as transportService from "./findTransportService";
import origin from "../../assets/origin.png";
import destination from "../../assets/destination.png";
import rider from "../../assets/riderLocation.png";
import { Col, Modal, Row } from "react-bootstrap";
import * as utils from "../../helper/helper";
import profileImage from "../../assets/profile.png";
import { v4 as uuidv4 } from "uuid";
import { getStorage, getDownloadURL, ref as imageRef } from "firebase/storage";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Polyline,
} from "@react-google-maps/api";

import ridingImage from "../../assets/riding.png";
import walkingImage from "../../assets/walking.png";
import cyclingImage from "../../assets/cycling.png";

import decodePolyline from "decode-google-map-polyline";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import TransportRate from "./components/transportRate";

import { getDatabase, ref, onValue, set } from "firebase/database";
import TransportTimeLine from "./components/transportTimeLine";
import Input from "../common/input";

function FindTransportSingle(props) {
  const transportId = props.history.location.state
    ? props.history.location.state.transportId
    : null;

  const [customerImageUrl, setCustomerImageUrl] = useState(profileImage);
  const [riderImageUrl, setRiderImageUrl] = useState(profileImage);
  const [adminImageUrl, setAdminImageUrl] = useState(profileImage);

  const [originLat, setOriginLat] = useState("");
  const [originLng, setOriginLng] = useState("");
  const [assignBearerType, setAssignBearerType] = useState("");
  const [assignBearerRiders, setAssignBearerRiders] = useState([]);
  const [showRiderListModal, setShowRiderListModal] = useState(false);
  const [assignTransportRiding, setAssignTransportRiding] = useState(false);
  const [assignTransportCycling, setAssignTransportCycling] = useState(false);
  const [assignTransportWalking, setAssignTransportWalking] = useState(false);
  const [searchRiderLimitOnlineStateTime, setSearchRiderLimitOnlineStateTime] =
    useState("");

  const [transport, setTransport] = useState();
  let db = getDatabase();
  const startChatRealTime = ref(db, "messages/" + transportId + "/chats");
  const riderLocation = ref(db, "messages/" + transportId + "/rider_location");
  const [showSelectTransportType, setShowSelectTransportType] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [isLoading, setLoading] = useState(false);
  const [messageChat, setMessageChat] = useState([]);
  const [newChatMessage, setNewChatMessage] = useState("");
  const [load, setLoad] = useState(0);

  const auth = getAuth();

  const [positions, setPositions] = useState([]);

  const checkState = (state) => {
    switch (state) {
      case "Rider on the way to Origin":
      case "Arrived to Origin":
      case "Parcel Picked Up":
      case "Rider Arrived to Destination":
      case "Parcel Dropped Off at Destination":
        return true;
      default:
        return false;
    }
  };

  const getAdminAvatar = () => {
    const avatar = localStorage.getItem("avatar");
    const storage = getStorage();
    const starsRef = imageRef(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        setAdminImageUrl(url);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (transportId === null) {
      return props.history.goBack();
    }
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (localStorage.getItem("avatar") !== null) {
      getAdminAvatar();
    }

    let unsubscribeLocation = onValue(riderLocation, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.val()) {
        if (data !== null) {
          setPositions([
            {
              lat: data.lat,
              lng: data.lng,
              icon: rider,
              zIndex: 99999,
            },
            {
              lat: transport ? transport.origin_geopoint._latitude : 0.0,
              lng: transport ? transport.origin_geopoint._longitude : 0.0,
              icon: origin,
              zIndex: 9,
            },
            {
              lat: transport ? transport.destination_geopoint._latitude : 0.0,
              lng: transport ? transport.destination_geopoint._longitude : 0.0,
              icon: destination,
              zIndex: 9,
            },
          ]);
        }
      }
    });

    let unsubscribe = onValue(startChatRealTime, (snapshot) => {
      const data = snapshot.val();
      utils.log(data);
      if (data !== null) {
        var messages = [];
        Object.entries(data).forEach(([key, value]) => {
          var chat = {
            senderId: value.sender_id,
            timestamp: value.timestamp,
            message: value.message,
            userType: value.user_type,
          };
          messages.push(chat);
        });
        messages.sort(function (x, y) {
          return x.timestamp - y.timestamp;
        });
        setMessageChat(messages);
      }
    });

    getTransportInfo(transportId);

    return () => {
      unsubscribe();
      unsubscribeLocation();
    };
  }, [load]);

  const [map, setMap] = React.useState(null);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const onLoadBounds = (map) => {
    if (!map) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    positions.forEach((child) => {
      bounds.extend(new window.google.maps.LatLng(child.lat, child.lng));
    });
    map.fitBounds(bounds);
    setMap(map);
  };

  const defaultMapOptions = {
    fullscreenControl: true,
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const options = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "blue",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  const loadGoogleMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={13}
        onLoad={onLoadBounds}
        onUnmount={onUnmount}
        defaultOptions={defaultMapOptions}
        options={{
          mapId: utils.getMapIdBasedonEnvironment(),
        }}
      >
        {positions.map((marker) => (
          <Marker
            position={marker}
            key={marker.lat + marker.lng + Math.random()}
            icon={{
              url: marker.icon,
            }}
          />
        ))}

        <Polyline
          key={"polyline-" + 1}
          path={decodePolyline(transport.overview_polyline)}
          options={options}
        />
        <></>
      </GoogleMap>
    );
  };

  const checkStatus = (state) => {
    switch (state) {
      case "Completed":
        return <h2 className="transport-status green">Completed</h2>;
      case "Canceled by Rider":
        return <h2 className="transport-status red">Canceled by Rider</h2>;
      case "Canceled by Customer":
        return <h2 className="transport-status red">Canceled by Customer</h2>;
      default:
        return <h2 className="transport-status green">{state}</h2>;
    }
  };

  const handleBearerType = (bearer) => {
    switch (bearer) {
      case "riding":
        return <img src={ridingImage} alt="rider" />;
      case "cycling":
        return <img src={cyclingImage} alt="cycle" />;
      case "walking":
        return <img src={walkingImage} alt="walk" />;
      default:
        break;
    }
  };

  const getTransportInfo = async (transportId) => {
    setLoading(true);
    try {
      const result = await transportService.adminGetTransport(transportId);
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            setTransport(result.data.transport);
            setPositions([
              {
                lat: result.data.transport.origin_geopoint._latitude,
                lng: result.data.transport.origin_geopoint._longitude,
                icon: origin,
                zIndex: 9,
              },
              {
                lat: result.data.transport.destination_geopoint._latitude,
                lng: result.data.transport.destination_geopoint._longitude,
                icon: destination,
                zIndex: 9,
              },
            ]);

            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleOnlineRiderList = async () => {
    setLoading(true);
    try {
      const result = await transportService.adminGetOnlineRidersForAssignment(
        searchRiderLimitOnlineStateTime,
        originLat,
        originLng,
        assignTransportWalking,
        assignTransportCycling,
        assignTransportRiding
      );
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            setAssignBearerRiders(result.data.riders);
            if (result.data.riders.length > 0) {
              setShowRiderListModal(true);
            } else {
              toast.error("No Bearers found");
            }
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleAssignToNewRider = async () => {
    setLoading(true);
    try {
      const result = await transportService.adminTransportAssignToNewRider(
        transport.transport_id
      );
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            setOriginLat(result.data.origin_lat);
            setOriginLng(result.data.origin_lng);
            setAssignBearerType(result.data.bearer_type);
            setShowSelectTransportType(true);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };
  const handleUnassignToNewRider = async () => {
    setLoading(true);
    try {
      const result = await transportService.adminUnlockTransportForAssignment(
        transport.transport_id
      );
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            setLoad((p) => p + 1);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleMoreRiderInfo = async () => {
    setLoading(true);
    try {
      const result = await transportService.adminGetRiderInfo(
        transport.rider_user_id
      );
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            if (result.data.rider.is_sign_up_completed) {
              props.history.push("/home/findRider/riderList/riderSingle", {
                riderId: result.data.rider.rider_uid,
              });
            } else {
              props.history.push("/home/findRider/riderList/uncompletedRider", {
                riderId: result.data.rider,
              });
            }
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleMoreCustomerInfo = async () => {
    setLoading(true);
    try {
      const result = await transportService.adminGetCustomerInfo(
        transport.customer_user_id
      );
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            props.history.push(
              "/home/findCustomer/customerList/customerSingle",
              {
                customerId: result.data.customer.customer_uid,
              }
            );
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleSelectedRider = async (riderId) => {
    setLoading(true);
    try {
      const result = await transportService.adminTransportAssignmentFinalised(
        transport.transport_id,
        riderId
      );
      utils.log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            setShowRiderListModal(false);
            setLoad((p) => p + 1);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handlePriceTitle = () => {
    if (transport.transport_state === "Canceled by Customer") {
      return <h5>Cancellation Fee</h5>;
    } else {
      if (
        transport.transport_receivable_price !==
        transport.transport_original_price
      ) {
        return (
          <h5>
            Receivable Price
            <span className="discounted">Discounted</span>
          </h5>
        );
      } else {
        return <h5>Receivable Price</h5>;
      }
    }
  };

  const handleWriteChat = () => {
    if (newChatMessage.replace(/\s/g, "").length > 1) {
      const message = newChatMessage;
      setNewChatMessage("");
      utils.log(transport.transport_id);
      set(
        ref(
          db,
          "messages/" + transport.transport_id + "/chats" + "/" + uuidv4()
        ),
        {
          sender_id: transport.transport_id,
          timestamp: Math.round(new Date().getTime()),
          message: message,
          user_type: "admin",
        }
      );
    }
  };

  const handleChatImageSrc = (senderId) => {
    switch (senderId) {
      case transport.rider_user_id:
        return riderImageUrl;
      case transport.customer_user_id:
        return customerImageUrl;
      default:
        return adminImageUrl;
    }
  };

  const handleChatOwner = (senderId) => {
    switch (senderId) {
      case transport.customer_user_id:
        return "user customer";
      case transport.rider_user_id:
        return "user rider";
      default:
        return "user admin";
    }
  };

  const handleTransportModeImage = (transport) => {
    switch (transport) {
      case "cycling":
        return (
          <img
            style={{ width: 45, display: "block", margin: "0 auto" }}
            src={require("../../assets/cycling.png")}
            alt="walk"
          />
        );
      case "riding":
        return (
          <img
            style={{ width: 45, display: "block", margin: "0 auto" }}
            src={require("../../assets/riding.png")}
            alt="walk"
          />
        );
      case "walking":
        return (
          <img
            style={{ width: 45, display: "block", margin: "0 auto" }}
            src={require("../../assets/walking.png")}
            alt="walk"
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {renderSpinner()}
      {transport && (
        <div className="transport">
          <ToastContainer />
          <div className="row">
            <div className="col-md-12">
              <div>
                {checkStatus(
                  transport.state ? transport.state : transport.state
                )}

                <h3 className="assignment-process">
                  {transport.is_in_assignment_process &&
                    "Transport is Locked for Assignment"}
                </h3>

                {positions.length > 0 && (
                  <div className="map"> {loadGoogleMap()}</div>
                )}
                <div className="location">
                  <div className="origin-address">
                    <span className="icon">
                      <img
                        src={require("../../assets/origin.png")}
                        alt="origin
            "
                      />
                    </span>
                    <span className="title">Origin:</span>
                    <span className="address">{transport.origin_address}</span>
                  </div>
                  <div className="des-address">
                    <span className="icon">
                      <img
                        src={require("../../assets/destination.png")}
                        alt="origin
            "
                      />
                    </span>
                    <span className="title">Destination:</span>
                    <span className="address">
                      {transport.destination_address}
                    </span>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Origin Details</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="value">{transport.origin_details}</div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Destination Details</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="value">
                        {transport.destination_details}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <TransportTimeLine transport={transport} />
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Order Number</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="value">{transport.order_number}</div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                {transport.rider_rated_by_customer ? (
                  <TransportRate transport={transport} />
                ) : null}
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Transport Type</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="value">{transport.transport_type}</div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Recipient Info</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="value">{transport.recipient_name}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="value">
                        <span className="transport-icon">
                          <img
                            src={require("../../assets/customer-info-phone.png")}
                            alt="phone-icon"
                          />
                        </span>
                        {transport.recipient_phone_number}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Sender Info</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="value">{transport.sender_name}</div>
                    </div>
                    <div className="col-md-4">
                      <div className="value">
                        <span className="transport-icon">
                          <img
                            src={require("../../assets/customer-info-phone.png")}
                            alt="phone-icon"
                          />
                        </span>
                        {transport.sender_phone_number}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Requested Bearer Type</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="bearer-value">
                        {handleBearerType(transport.bearer_type)}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Assigned Bearer Type </h5>
                    </div>
                    <div className="col-md-4">
                      <div className="bearer-value">
                        {handleBearerType(transport.assigned_bearer_type)}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.transport_original_price !== "undefined" &&
                  transport.transport_original_price !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Original Price </h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.transport_original_price}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.transport_receivable_price !== "undefined" &&
                  transport.transport_receivable_price !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">{handlePriceTitle()}</div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.transport_receivable_price}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.original_payable_to_rider !== "undefined" &&
                  transport.original_payable_to_rider !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Original Payable to Rider </h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.original_payable_to_rider}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.application_fee !== "undefined" &&
                  transport.application_fee !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Application Fee</h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.application_fee}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.rider_bonus !== "undefined" &&
                  transport.rider_bonus !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Rider Bonus</h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.rider_bonus}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.final_paid_to_rider !== "undefined" &&
                  transport.final_paid_to_rider !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Final Paid to Rider</h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.final_paid_to_rider}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.cancellation_fee !== "undefined" &&
                  transport.cancellation_fee !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Cancellation Fee</h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {"A$ " + transport.cancellation_fee}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.points_earned_by_rider !== "undefined" &&
                  transport.points_earned_by_rider !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5
                            className={
                              transport.points_earned_by_rider > 0
                                ? "green"
                                : "red"
                            }
                          >
                            Points earned
                          </h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {transport.points_earned_by_rider}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-item-wrapper">
                  {transport &&
                  typeof transport.cancelation_reason !== "undefined" &&
                  transport.cancelation_reason !== null ? (
                    <div className="transport-wrapper">
                      <div className="row">
                        <div className="col-md-4">
                          <h5>Cancellation Reason </h5>
                        </div>
                        <div className="col-md-4">
                          <div className="value">
                            {transport.cancelation_reason}
                          </div>
                        </div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Drop off Verification</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="bearer-value">
                        {transport &&
                        transport.dropoff_verification_method === "Code"
                          ? `Code, ${transport.order_secret}`
                          : "None"}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Travel Time from Org. To Dst. </h5>
                    </div>
                    <div className="col-md-4">
                      <div className="bearer-value">
                        {transport ? transport.transport_time : ""}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-wrapper">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Distance from Org. To Dst</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="bearer-value">
                        {transport ? transport.transport_length : ""}
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </div>
                <div className="transport-user">
                  <TransportCustomer
                    transport={transport}
                    load={load}
                    setUrl={(url) => setCustomerImageUrl(url)}
                  />
                </div>

                <div className="transport-user">
                  <TransportRider
                    transport={transport}
                    load={load}
                    setUrl={(url) => setRiderImageUrl(url)}
                  />
                </div>

                <div className="parcel">
                  <TransportParcel load={load} transport={transport} />
                </div>
                <div className="group-btn">
                  <ul>
                    <li>
                      <button
                        className="search-view"
                        onClick={() => handleMoreRiderInfo()}
                      >
                        More Rider Info
                      </button>
                    </li>
                    <li>
                      <button
                        className="search-view"
                        onClick={() => handleMoreCustomerInfo()}
                      >
                        More Customer Info
                      </button>
                    </li>
                    {checkState(
                      transport.transport_state
                        ? transport.transport_state
                        : transport.state
                    ) && transport.is_show_assign_to_new_rider_button ? (
                      <li>
                        <button
                          className="search-view"
                          onClick={() => {
                            if (transport.is_in_assignment_process) {
                              handleUnassignToNewRider();
                            } else {
                              handleAssignToNewRider();
                            }
                          }}
                        >
                          {transport.is_in_assignment_process
                            ? "Transport Unlock"
                            : "Assign to New Rider"}
                        </button>
                      </li>
                    ) : null}
                    {checkState(
                      transport.transport_state
                        ? transport.transport_state
                        : transport.state
                    ) ? (
                      <li>
                        <button
                          className="search-view"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          Start Chat
                        </button>
                      </li>
                    ) : (
                      <li>
                        <button
                          className="search-view"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          Open Chat
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} className="chat-modal">
            <Modal.Header>
              <Modal.Title>Chat View</Modal.Title>
              <button
                variant="primary"
                className="chat-close"
                onClick={handleClose}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              {messageChat.map((chat) => (
                <div
                  className="chat-wrapper clear-fix"
                  key={chat.timestamp + chat.message + chat.senderId}
                >
                  <div className={handleChatOwner(chat.senderId)}>
                    <img
                      src={handleChatImageSrc(chat.senderId)}
                      alt="user-image"
                    />
                    <p>{chat.message}</p>
                    <div
                      className={
                        chat.userType && chat.userType === "admin"
                          ? "right time clear-fix"
                          : "left time clear-fix"
                      }
                    >
                      {utils.timestampToDateFull(chat.timestamp)}
                    </div>
                  </div>
                </div>
              ))}
            </Modal.Body>
            {checkState(
              transport.transport_state
                ? transport.transport_state
                : transport.state
            ) ? (
              <Modal.Footer>
                <input
                  type="String"
                  placeholder="new message"
                  value={newChatMessage}
                  onChange={(e) => {
                    const value = e.target.value;
                    setNewChatMessage(value);
                  }}
                />
                <button variant="primary" onClick={() => handleWriteChat()}>
                  Send
                </button>
              </Modal.Footer>
            ) : null}
          </Modal>
          <Modal show={showSelectTransportType} className="chat-modal">
            <Modal.Header>
              <Modal.Title>Search for Bearers</Modal.Title>
              <button
                variant="primary"
                className="chat-close"
                onClick={() => setShowSelectTransportType(false)}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col md="12">
                  <Input
                    label="Latest Online Time in Minutes:"
                    type="number"
                    name="title"
                    value={searchRiderLimitOnlineStateTime}
                    onChange={(e) => {
                      if (e.target.value !== 0) {
                        setSearchRiderLimitOnlineStateTime(e.target.value);
                      }
                    }}
                    style={{ marginTop: 10 }}
                    className="margin-top-bottom"
                  />
                </Col>
                <Col md="12" style={{ marginBottom: 10 }}>
                  Bearer Type:
                </Col>
                <Col md="4">
                  <label style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      checked={assignTransportRiding}
                      onChange={() => {
                        setAssignTransportRiding(!assignTransportRiding);
                      }}
                      style={{ height: 30, marginBottom: 0, marginRight: 5 }}
                    />
                    Riding
                  </label>
                </Col>
                <Col md="4">
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      opacity: assignBearerType === "riding" ? 0.2 : 1,
                    }}
                  >
                    <input
                      disabled={assignBearerType === "riding" ? true : false}
                      type="checkbox"
                      checked={assignTransportCycling}
                      onChange={() => {
                        setAssignTransportCycling(!assignTransportCycling);
                      }}
                      style={{ height: 30, marginBottom: 0, marginRight: 5 }}
                    />
                    Cycling
                  </label>
                </Col>
                <Col md="4" style={{ marginBottom: 20 }}>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      opacity: assignBearerType !== "walking" ? 0.2 : 1,
                    }}
                  >
                    <input
                      disabled={assignBearerType === "walking" ? false : true}
                      type="checkbox"
                      checked={assignTransportWalking}
                      onChange={() => {
                        setAssignTransportWalking(!assignTransportWalking);
                      }}
                      style={{ height: 30, marginBottom: 0, marginRight: 5 }}
                    />
                    Walking
                  </label>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <button
                variant="primary"
                className="transport-type"
                onClick={() => {
                  if (
                    (assignTransportRiding ||
                      assignTransportCycling ||
                      assignTransportWalking) &&
                    searchRiderLimitOnlineStateTime.length > 0
                  ) {
                    setShowSelectTransportType(false);
                    handleOnlineRiderList();
                  } else {
                    toast.error(
                      "Make sure you enter number and select at least one of the tranport modes "
                    );
                  }
                }}
              >
                Search
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showRiderListModal}
            className="chat-modal search-list assign-rider-list"
          >
            <Modal.Header>
              <Modal.Title>Bearer List</Modal.Title>
              <button
                variant="primary"
                className="chat-close"
                onClick={() => setShowRiderListModal(false)}
              >
                Close
              </button>
            </Modal.Header>
            <Modal.Body>
              <table className="table">
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Full Name</th>
                    <th>Mobile Number</th>
                    <th>Mode of Transport</th>
                    <th>Latest Online Time</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {assignBearerRiders.map((rider) => (
                    <tr key={rider.rider_uid}>
                      <td>{rider.given_name + " " + rider.last_name}</td>
                      <td>{rider.mobile_number}</td>
                      <td>
                        {handleTransportModeImage(rider.mode_of_transport)}
                      </td>
                      <td>
                        {utils.timestampToDateFull(rider.last_state_update)}
                      </td>
                      <td>
                        <button
                          className="search-view"
                          onClick={() => handleSelectedRider(rider.rider_uid)}
                        >
                          Select Bearer
                        </button>
                      </td>
                      <td>
                        <button
                          className="search-view"
                          onClick={() =>
                            props.history.push(
                              "/home/findRider/riderList/riderSingle",
                              {
                                riderId: rider.rider_uid,
                              }
                            )
                          }
                        >
                          View Profile
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}

export default FindTransportSingle;
