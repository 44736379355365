import React, { useEffect, useState } from "react";
import avatarImage from "../../../assets/profile.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { log } from "../../../helper/helper";

function TransportCustomer({ transport, setUrl, load }) {
  const [imageUrl, setImageUrl] = useState("");
  const avatar =
    transport && transport.customer_avatar_url
      ? transport.customer_avatar_url
      : "";

  const dowloadavatar = () => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrl(url);
        setUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  useEffect(() => {
    // Get the download URL
    if (avatar !== "") {
      dowloadavatar();
    }
  }, [load]);

  return (
    <div className="row">
      <div className="col-md-4">
        <h5>Customer</h5>
      </div>
      <div className="col-md-4">
        <div className="user-info">
          <figure>
            <img
              src={imageUrl !== "" ? imageUrl : avatarImage}
              alt="rider-avatar"
            />
          </figure>
          <figcaption className="value">
            {transport && transport.customer_given_name
              ? transport.customer_given_name +
                " " +
                transport.customer_last_name
              : ""}
          </figcaption>
        </div>
      </div>
      <div className="col-md-4"></div>
    </div>
  );
}

export default TransportCustomer;
