import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as customerService from "../findCustomerService";
import * as riderService from "../../findRider/findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log, timestampToDateFull } from "../../../helper/helper";
import Input from "../../common/input";
import Select from "../../common/select";
import { Tooltip } from "antd";

function Note({ user, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [historyShow, setHistoryShow] = useState(false);
  const [historyDetailShow, setHistoryDetailShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleHistoryClose = () => setHistoryShow(false);
  const handleHistoryShow = () => setHistoryShow(true);

  const [titleSelected, setTitleSelected] = useState("");
  const [noteTitleError, setNoteTitleError] = useState("");

  const [noteSelected, setNoteSelected] = useState("");
  const [noteTextError, setNoteTextError] = useState("");
  const userType = user && user.user_type ? "customer" : "rider";
  const [adminList, setAdminList] = useState([]);

  const [noteDetailTitle, setNoteDetailTitle] = useState("");
  const [noteDetailText, setNoteDetailText] = useState("");
  const [probabilitySelected, setProbabilitySelected] = useState(0);
  const [selectedEngagementValue, setSelectedEngagementValue] = useState("");

  useEffect(() => {
    const item = targetSelected.filter((option) => {
      return option.id === user.note_user_engagement_scale;
    });
    if (item.length > 0) {
      setSelectedEngagementValue(item[0].name);
      setProbabilitySelected(item[0].id);
    }
  }, []);

  const targetSelected = [
    {
      id: 0,
      name: "0: No Interest",
    },
    {
      id: 1,
      name: "1: Slight Interest",
    },
    {
      id: 2,
      name: "2: Moderate Interest",
    },
    {
      id: 3,
      name: "3: Interested",
    },
    {
      id: 4,
      name: "4: Very Interested",
    },
    {
      id: 5,
      name: "5: Extremely Interested",
    },
  ];

  const handleHistoryDetailClose = () => {
    setHistoryDetailShow(false);
    setHistoryShow(true);
  };
  const handleExit = () => {
    setNoteSelected();
    setTitleSelected("");
    setShow(false);
  };
  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleAdminUserName = (uid) => {
    const filtered = adminList.filter((user) => user.admin_uid.includes(uid));
    if (filtered.length > 0) {
      const name = filtered[0].given_name + " " + filtered[0].last_name;
      return name;
    } else {
      return "Other Admin";
    }
  };

  const HandleAddNote = async () => {
    setNoteTitleError("");
    setNoteTextError("");

    if (titleSelected.length < 3) {
      return setNoteTitleError("Minimum 2 letters");
    }

    if (noteSelected.length < 3) {
      return setNoteTextError("Minimum 2 letters");
    }

    handleClose(true);
    setLoading(true);

    setTitleSelected("");
    setNoteSelected("");
    if (userType === "customer") {
      try {
        const result = await customerService.adminCustomerAddNote(
          user.customer_uid,
          titleSelected,
          noteSelected,
          probabilitySelected
        );
        log(result);
        setLoading(false);
        if (result) {
          switch (result.data.code) {
            case 200:
              toast.success(result.data.message);
              handleReloadSingle();
              return;
            case 401:
              toast.error(result.data.message);
              return props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      try {
        const result = await riderService.adminRiderAddNote(
          user.rider_uid,
          titleSelected,
          noteSelected,
          probabilitySelected
        );
        log(result);
        setLoading(false);
        if (result) {
          switch (result.data.code) {
            case 200:
              toast.success(result.data.message);

              handleReloadSingle();
              return;
            case 401:
              toast.error(result.data.message);
              return props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  const getAdminInfo = async () => {
    try {
      const result = await customerService.getAdminInfo();
      log(result.data);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAdminList(result.data.adminsList);
            handleHistoryShow();
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const tooltipMessage = () => {
    return (
      <ul>
        <li>0: No Interest - Completely uninterested in joining Bearer.</li>
        <li>
          1: Slight Interest - Minimal interest; might have looked into it but
          not actively pursuing.
        </li>
        <li>
          2: Moderate Interest - Curious about the opportunity; may need more
          information to decide.
        </li>
        <li>
          3: Interested - Actively considering the opportunity and possibly
          preparing to join.
        </li>
        <li>
          4: Very Interested - Almost certain about joining; might be waiting
          for a few final details or clarifications.
        </li>
        <li>
          5: Extremely Interested - Fully committed and eager to join Bearer
          immediately.
        </li>
      </ul>
    );
  };

  return (
    <Row>
      <ToastContainer />
      {renderSpinner()}
      <Col md="4">
        <h5>Note</h5>
      </Col>
      <Col md="4">
        <div className="value">{user ? user.note_title : ""}</div>
      </Col>
      <Col ms="4">
        <ul>
          <li>
            <Button
              variant="primary"
              className="edit-btn note-btn"
              onClick={() => {
                handleShow();
              }}
            >
              Edit
            </Button>
          </li>
          {user && user.admin_notes_history !== null ? (
            <li>
              <Button
                className="edit-btn note-btn"
                onClick={() => {
                  getAdminInfo();
                  setLoading(true);
                }}
              >
                History
              </Button>
            </li>
          ) : null}
        </ul>
        <Modal show={show} className="note-modal" onHide={handleExit}>
          <Modal.Header closeButton>
            <Modal.Title>Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="12">
                <Tooltip title={tooltipMessage}>
                  <Select
                    style={{ marginTop: 10, marginBottom: 15 }}
                    name="target"
                    options={targetSelected}
                    value={selectedEngagementValue}
                    label="Engagement Scale:"
                    onChange={(e) => {
                      const item = targetSelected.filter((option) => {
                        return option.name === e.target.value;
                      });
                      setProbabilitySelected(item[0].id);
                      setSelectedEngagementValue(item[0].name);
                    }}
                    disabled={false}
                    disableSelect={false}
                  />
                </Tooltip>
              </Col>
              <Col md="12">
                <Input
                  label="Title:"
                  type="string"
                  name="title"
                  value={titleSelected}
                  onChange={(e) => {
                    setTitleSelected(e.target.value);
                    setNoteTitleError("");
                  }}
                  error={noteTitleError}
                  style={{ marginTop: 10 }}
                  className="margin-top-bottom"
                />
              </Col>
              <Col md="12">
                <textarea
                  value={noteSelected}
                  onChange={(e) => {
                    setNoteSelected(e.target.value);
                    setNoteTextError("");
                  }}
                  className="note-text"
                ></textarea>
                {noteTextError && (
                  <div className="alert alert-danger custom-error">
                    {noteTextError}
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={HandleAddNote}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {user && user.admin_notes_history ? (
          <Modal
            show={historyShow}
            className="note-history-modal"
            onHide={handleHistoryClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Note Histroy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-design">
                <Row>
                  <Col md="12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Note</th>
                          <th>User</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(user.admin_notes_history)
                          .sort(([a], [b]) => b - a)
                          .map(([key, value]) => (
                            <tr key={key}>
                              <td>{timestampToDateFull(key)}</td>
                              <td>{value.note_title}</td>
                              <td className="admin-uid">
                                {handleAdminUserName(
                                  value.drafted_by_admin_uid
                                )}
                              </td>
                              <td>
                                <Button
                                  className="search-view"
                                  onClick={() => {
                                    setNoteDetailTitle(value.note_title);
                                    setNoteDetailText(value.note_text);
                                    setHistoryDetailShow(true);
                                    setHistoryShow(false);
                                  }}
                                >
                                  Detail
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
        <Modal
          show={historyDetailShow}
          className="note-history-detail-modal"
          onHide={handleHistoryDetailClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>{noteDetailTitle}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ whiteSpace: "pre-line" }}>{noteDetailText}</p>
          </Modal.Body>
        </Modal>
      </Col>
    </Row>
  );
}

export default Note;
