import { getFunctions, httpsCallable } from "firebase/functions";
import config from "./../../config.json";

export function adminMotorbikeInbox() {
  const functions = getFunctions();
  const motorbike = httpsCallable(
    functions,
    config.adminMotorbikeInbox
  );
  return motorbike();
}