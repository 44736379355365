import React, { useEffect, useState } from "react";
import "../global/global.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { log } from "../../helper/helper";
import * as motorbikeService from "./findMotorbikeService";

function MotorbikeList(props) {
  const motorbikes = props.history.location.state
    ? props.history.location.state.motorList
    : [];

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });

    if (motorbikes.length < 1) {
      return props.history.push("/home/findMotorbike");
    }
  });

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const getAdminInfo = async (motorbike) => {
    setLoading(true);
    try {
      const result = await motorbikeService.getAdminInfo();
      log(result.data);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            log(result.data.adminsList);
            props.history.push("/home/findMotorbike/motorbikeList/history", {
              history: motorbike.motorbike_status_history,
              adminList: result.data.adminsList,
            });
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const statusCheck = (motorbike) => {
    switch (motorbike.motorbike_latest_status) {
      case "Processing":
        return (
          <div className="status-custom-modes processing">
            <span>Processing</span>
          </div>
        );

      case "Approved":
        if (motorbike.is_rego_date_still_valid) {
          if (motorbike.is_insurance_policy_date_still_valid) {
            return (
              <div className="status-custom-modes approved-valid">
                <span>Approved</span>
              </div>
            );
          } else {
            return (
              <div className="status-custom-modes approved-invalid">
                <span>Insurance Policy Expired</span>
              </div>
            );
          }
        } else {
          return (
            <div className="status-custom-modes approved-invalid">
              <span>Registration Expired</span>
            </div>
          );
        }

      case "Policy Expired":
        return (
          <div className="status-custom-modes expired">
            <span>Policy Expired</span>
          </div>
        );
      case "Rego Expired":
        return (
          <div className="status-custom-modes expired">
            <span>Rego Expired</span>
          </div>
        );
      case "Further Investigation":
        return (
          <div className="status-custom-modes investigation">
            <span>Further Investigation</span>
          </div>
        );
      case "Restricted":
        return (
          <div className="status-custom-modes restricted">
            <span>Restricted</span>
          </div>
        );
      case "Rejected":
        return (
          <div className="status-custom-modes rejected">
            <span>Rejected</span>
          </div>
        );
      case "Removed":
        return (
          <div className="status-custom-modes removed">
            <span>Removed</span>
          </div>
        );
      default:
        break;
    }
  };

  const handleButtonControl = (motorbike) => {
    if (motorbike.motorbike_latest_status === "Processing") {
      if (motorbike.is_allow_show_edit_button_result) {
        return (
          <button
            className="single-view"
            onClick={() =>
              props.history.push(
                "/home/findMotorbike/motorbikeList/motorbike",
                {
                  motorbike: motorbike,
                }
              )
            }
          >
            View
          </button>
        );
      } else {
        return null;
      }
    } else {
      return (
        <button
          className="single-view"
          onClick={() =>
            props.history.push("/home/findMotorbike/motorbikeList/motorbike", {
              motorbike: motorbike,
            })
          }
        >
          View
        </button>
      );
    }
  };

  return (
    <div className="show-all-list">
      <ToastContainer />
      {renderSpinner()}
      <div className="headline">
        <h3>Show All Motorbikes</h3>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Make</th>
            <th>Model</th>
            <th>Status</th>
            <th>Registration State</th>
            <th>Registration Number</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {motorbikes.map((motorbike) => (
            <tr key={motorbike.motorbike_uid}>
              <td>{motorbike.make}</td>
              <td>{motorbike.model}</td>
              <td className="motobike-status-modes-transport">
                {statusCheck(motorbike)}
              </td>
              <td>{motorbike.registration_state}</td>
              <td>{motorbike.registration_number}</td>
              <td>{handleButtonControl(motorbike)}</td>
              <td>
                {motorbike.motorbike_status_history !== null ? (
                  <button
                    className="single-view"
                    onClick={() => getAdminInfo(motorbike)}
                  >
                    History
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MotorbikeList;
