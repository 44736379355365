import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./motorbike.scss";
import AdminInfo from "../common/adminInfo";
import * as helper from "../../helper/helper";
import HiddenByUser from "./components/hiddenByUser";
import MotorbikeLatestStatus from "./components/motorbikeLatestStatus";
import * as service from "../findRider/findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";

function MotorbikeSingle(props) {
  const state = props.history.location.state
    ? props.history.location.state
    : null;
  helper.log(state);
  const [isLoading, setLoading] = useState(true);
  const [motorbike, setMotorbike] = useState();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (state === null) {
      return props.history.replace("/");
    }

    getMotorbikeById();
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const getMotorbikeById = async () => {
    setLoading(true);
    try {
      const result = await service.adminGetMotorbikeInfo(
        state.motorbike.motorbike_uid
      );
      helper.log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setMotorbike(result.data.motorbike);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="motorbike">
      <ToastContainer />
      {renderSpinner()}
      {motorbike && motorbike.motorbike_latest_status !== "Processing" ? (
        <div className="container">
          <div className="row">
            <div className="headline">
              <h3>{"Motorbike " + motorbike.make + " " + motorbike.model}</h3>
            </div>
          </div>
          <AdminInfo
            header="Registration State"
            value={motorbike.registration_state}
          />
          <AdminInfo
            header="Registration Number"
            value={motorbike.registration_number}
          />
          <AdminInfo header="Motorbike Type" value={motorbike.motorbike_type} />
          <AdminInfo header="Engine Size" value={motorbike.engine_size} />

          <AdminInfo
            header="Insurance Policy Validity Date"
            value={motorbike.ipc_validity_date}
          />
          <AdminInfo
            header="Registration Expiry Date"
            value={motorbike.rego_validity_date}
          />
          <AdminInfo
            header="Insurance Company"
            value={motorbike.ipc_insurer_company}
          />
          <AdminInfo
            header="Policy Number"
            value={motorbike.ipc_policy_number}
          />
          <AdminInfo
            header="Date Visibility Update"
            value={helper.timestampToDateYearNanoSecond(
              motorbike.date_visibility_updated._seconds,
              motorbike.date_visibility_updated._nanoseconds
            )}
          />
          <HiddenByUser
            header="Hidden By User"
            value={motorbike.is_hidden_by_user}
            id={motorbike.motorbike_uid}
            props={props}
            handleReloadSingle={() => getMotorbikeById()}
          />
          <MotorbikeLatestStatus
            motor={motorbike}
            props={props}
            handleReloadSingle={() => getMotorbikeById()}
          />
          {motorbike.motorbike_latest_status_note !== "" ? (
            <AdminInfo
              header="Motorbike Latest Status Note"
              value={motorbike.motorbike_latest_status_note}
            />
          ) : null}

          <div className="row">
            <div className="group-btn">
              <ul>
                <li>
                  <button
                    onClick={() =>
                      props.history.push(
                        "/home/findMotorbike/motorbikeList/motorbike/rider",
                        { riderId: motorbike.owner_uid }
                      )
                    }
                  >
                    View Rider
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <>
          {motorbike ? (
            <MotorbikeLatestStatus
              motor={motorbike}
              props={props}
              handleReloadSingle={() => getMotorbikeById()}
            />
          ) : null}

          {motorbike && motorbike.motorbike_latest_status_note !== "" ? (
            <AdminInfo
              header="Motorbike Latest Status Note"
              value={motorbike.motorbike_latest_status_note}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default MotorbikeSingle;
