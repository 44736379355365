import React from "react";
import * as helper from "../../../helper/helper";

function RiderBearerInfo({ user }) {
  return (
    <div>
      <h5>Rider Bearer Info</h5>
      <div className="row">
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Completed Job</div>
            <div className="value">
              {user ? user.riders_total_completed_jobs : ""}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Rider Point</div>
            <div className="value">{user ? user.riders_point : ""}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Total Rated</div>
            <div className="value">
              {user ? user.riders_total_rated_jobs : ""}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Agreed TC-PP</div>
            <div className="value">
              {user && user.latest_tc_pp_vers_signed_accepted
                ? user.latest_tc_pp_vers_signed_accepted
                : ""}
            </div>
          </div>
        </div>
        {user && user.latest_tc_pp_vers_signed_date ? (
          <div className="col-md-4">
            <div className="thumbnail">
              <div className="title">Agreed TC-PP Date</div>
              <div className="value">
                {helper.timestampToDateYearAndTime(
                  user.latest_tc_pp_vers_signed_date._seconds,
                  user.latest_tc_pp_vers_signed_date._nanoseconds
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default RiderBearerInfo;
