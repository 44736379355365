import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import * as service from "../findRider/findRiderService";
import "./riderList.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Input from "../common/input";
import Alart from "../common/alart";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { log } from "../../helper/helper";

function RightToWork(props) {
  const rider = props.history.location.state
    ? props.history.location.state.rider
    : null;
  const [isLoading, setLoading] = useState(false);
  const [alartTriggerMessage, setAlartTriggerMessage] = useState("");

  const [rightWorkDocUrl, setRightWorkDocUrl] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [dateOfIssueError, setDateOfIssueError] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState("");
  const [isCitizen, setCitizen] = useState(true);
  const [isLimitedWorkCondition, setLimitedWorkCondition] = useState(true);
  const [visaGrandDate, setVisaGrandDate] = useState("");
  const [visaGrandDateError, setVisaGrandDateError] = useState("");
  const [visaExpiryDate, setVisaExpiryDate] = useState("");
  const [visaExpiryDateError, setVisaExpiryDateError] = useState("");
  const [residencyStatus, setResidencyStatus] = useState(0);
  const [workingCondition, setWorkingCondition] = useState(0);
  const [visaClass, setVisaClass] = useState("");
  const [visaClassError, setVisaClassError] = useState("");
  const [workingLimit, setWorkingLimit] = useState("");
  const [workingLimitError, setWorkingLimitError] = useState("");
  const [workAreaVisaCondition, setWorkAreaVisaCondition] = useState("");
  const [workAreaVisaConditionError, setWorkAreaVisaConditionError] =
    useState("");

  const [rejectionText, setRejectionText] = useState("");
  const [isRejectBtnDisable, setRejectBtnDisable] = useState(true);
  const [isVerifyBtnDisable, setVerifyBtnDisable] = useState(false);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      } else {
        if (rider === null) {
          props.history.replace("/home/allRiders");
        } else {
          if (rightWorkDocUrl === "") {
            if (rider ? rider.ncc.right_work_doc_url : "") {
              getRightWorkDocuments(rider ? rider.ncc.right_work_doc_url : "");
            }
          }
        }
      }
    });
  }, []);

  const getRightWorkDocuments = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setRightWorkDocUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleAlart = () => {
    setRightWorkDocUrl("");
    setDateOfIssue("");
    setDateOfIssueError("");
    setFullName("");
    setFullNameError("");
    setDateOfBirth("");
    setDateOfBirthError("");
    setVisaGrandDate("");
    setVisaGrandDateError("");
    setVisaExpiryDate("");
    setVisaExpiryDateError("");
    setVisaClass("");
    setVisaClassError("");
    setWorkingLimit("");
    setWorkingLimitError("");
    setWorkAreaVisaCondition("");
    setWorkAreaVisaConditionError("");

    props.history.replace("/home/allRiders");
  };

  const handleChangeResidencyStatus = (value) => {
    if (value === 0) {
      setResidencyStatus(0);
      setCitizen(true);
    } else {
      setResidencyStatus(1);
      setCitizen(false);
    }
  };

  const handleWorkCondition = (value) => {
    if (value === 0) {
      setWorkingCondition(0);
      setLimitedWorkCondition(true);
    } else {
      setWorkingCondition(1);
      setLimitedWorkCondition(false);
    }
  };

  const verifyRightToWork = async () => {
    let momentTimeDateOfIssue = "";
    setDateOfIssueError("");

    if (dateOfIssue !== "" || dateOfIssue === null) {
      const sortedDateOfIssue = new Date(dateOfIssue);
      momentTimeDateOfIssue = Moment(sortedDateOfIssue).format("YYYY-MM-DD");
      setDateOfIssueError("");
    } else {
      return setDateOfIssueError(
        "The document MUST be valid for at least three calendar months"
      );
    }

    setFullNameError("");
    if (fullName.replace(/\s/g, "") === "") {
      return setFullNameError("Must contain English Letter(s) only");
    }

    let momentBirthTime = "";
    if (dateOfBirth !== "" && dateOfBirth !== null) {
      const sortedDateOfBirth = new Date(dateOfBirth);
      momentBirthTime = Moment(sortedDateOfBirth).format("YYYY-MM-DD");
      setDateOfBirthError("");
    } else {
      setDateOfBirthError("You must be at least 16 years old");
      return;
    }

    let momentTimeGrandDate = "";

    if (!isCitizen) {
      if (visaGrandDate !== "" && visaGrandDate !== null) {
        const sortedVisaGrandDate = new Date(visaGrandDate);
        momentTimeGrandDate = Moment(sortedVisaGrandDate).format("YYYY-MM-DD");
        setVisaGrandDateError("");
      } else {
        setVisaGrandDateError("Pick a date to continue");
        return;
      }
    }
    let momentVisaExpiryDate = "";
    if (!isCitizen && workingCondition === 0) {
      setVisaExpiryDateError("");
      setVisaClassError("");
      setWorkingLimitError("");
      setWorkAreaVisaConditionError("");
    } else if (!isCitizen && workingCondition === 1) {
      if (visaExpiryDate === "" || visaExpiryDate === null) {
        return setVisaExpiryDateError("Pick a date to continue");
      }
      const sortedVisaExpiryDate = new Date(visaExpiryDate);
      momentVisaExpiryDate = Moment(sortedVisaExpiryDate).format("YYYY-MM-DD");
      setVisaExpiryDateError("");

      if (visaClass.length < 2) {
        return setVisaClassError("Minimum 2 Char");
      }
      setVisaClassError("");
      if (workingLimit < 1 || isNaN(+workingLimit)) {
        return setWorkingLimitError("Only Numbers are accepted");
      }
      setWorkingLimitError("");
      if (workAreaVisaCondition.length < 3) {
        return setWorkAreaVisaConditionError(
          "Minimum 2 Char Number And Alphabetical"
        );
      }
      setWorkAreaVisaConditionError("");
    }

    const isIssuedWithUnlimitedWorkRight =
      workingCondition === 0 ? true : false;
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewVerifyRightToWork(
        rider.rider_uid,
        momentTimeDateOfIssue,
        fullName.toLocaleUpperCase(),
        momentBirthTime,
        isCitizen,
        momentTimeGrandDate,
        isIssuedWithUnlimitedWorkRight,
        momentVisaExpiryDate,
        workingLimit,
        visaClass,
        workAreaVisaCondition
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setRightWorkDocUrl("");
            setDateOfIssue("");
            setDateOfIssueError("");
            setFullName("");
            setFullNameError("");
            setDateOfBirth("");
            setDateOfBirthError("");
            setVisaGrandDate("");
            setVisaGrandDateError("");
            setVisaExpiryDate("");
            setVisaExpiryDateError("");
            setVisaClass("");
            setVisaClassError("");
            setWorkingLimit("");
            setWorkingLimitError("");
            setWorkAreaVisaCondition("");
            setWorkAreaVisaConditionError("");
            props.history.replace("/home/allRiders/policeCheck", {
              rider: rider,
            });
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleRejectionText = (value) => {
    if (value !== "") {
      setRejectionText(value);
      setRejectBtnDisable(false);
    } else {
      setRejectionText("");
      setRejectBtnDisable(true);
    }
  };

  const adminRiderDocumentReviewReject = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewReject(
        rider.rider_uid,
        note,
        3,
        ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminRiderFurtherInvestigation = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewFurtherInvestigation(
        rider.rider_uid,
        note,
        3,
        ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <div className="right-work-confirm new-rider-steps">
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="steps">
            <div className="step number active">1</div>
            <div className="step line active"></div>
            <div className="step number active">2</div>
            <div className="step line active"></div>
            <div className="step number active">3</div>
            <div className="step line"></div>
            <div className="step number">4</div>
          </div>
        </div>
        <div className="row">
          <div className="header-title">
            <h1>Right To Work</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <iframe src={rightWorkDocUrl} title="pdf" />
          </div>
          <div className="col-md-5">
            <div className="input-text-wrapper">
              <label htmlFor="dateTime">Date of Issue(certificate):</label>
              <DatePicker
                selected={dateOfIssue}
                onChange={(date) => {
                  setDateOfIssue(date);
                  setDateOfIssueError("");
                }}
                dateFormat="dd-MM-yyyy"
                minDate={
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    new Date().getDate()
                  )
                }
                maxDate={new Date()}
                className="form-control"
              />
              {dateOfIssueError && (
                <div className="alert alert-danger custom-error">
                  {dateOfIssueError}
                </div>
              )}
            </div>
            <div className="input-text-wrapper">
              <Input
                label="Full Name:"
                type="String"
                name="fullName"
                value={fullName}
                onChange={(e) => {
                  setFullNameError("");
                  setFullName(e.target.value);
                }}
                disabled={isVerifyBtnDisable}
                error={fullNameError}
              />
            </div>
            <div className="input-text-wrapper">
              <label htmlFor="dateTime">Date of Birth:</label>
              <DatePicker
                selected={dateOfBirth}
                onChange={(date) => {
                  setDateOfBirthError("");
                  setDateOfBirth(date);
                }}
                maxDate={
                  new Date(
                    new Date().getFullYear() - 16,
                    new Date().getMonth() - 1,
                    new Date().getDate()
                  )
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
              />
              {dateOfBirthError && (
                <div className="alert alert-danger custom-error">
                  {dateOfBirthError}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-radio-wrapper">
                  <div className="title">Residency Status:</div>
                  <input
                    type="radio"
                    value={residencyStatus === 0}
                    name="residencyStatus"
                    checked={residencyStatus === 0}
                    onChange={() => {
                      handleChangeResidencyStatus(0);
                      setVisaGrandDateError("");
                    }}
                  />{" "}
                  <label htmlFor="">Australia / New Zealand Citizen</label>
                  <br />
                  <input
                    type="radio"
                    value={residencyStatus === 1}
                    name="residencyStatus"
                    checked={residencyStatus === 1}
                    onChange={() => handleChangeResidencyStatus(1)}
                  />{" "}
                  <label htmlFor="">On a Valid Visa</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-text-wrapper">
                  <label htmlFor="dateTime">Visa Grant Date:</label>
                  <DatePicker
                    selected={visaGrandDate}
                    onChange={(date) => {
                      setVisaGrandDateError("");
                      setVisaGrandDate(date);
                    }}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    disabled={isCitizen}
                  />
                  {visaGrandDateError && (
                    <div className="alert alert-danger custom-error">
                      {visaGrandDateError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-radio-wrapper">
                  <div className="title">Working Condition:</div>
                  <input
                    type="radio"
                    value={workingCondition === 0}
                    name="workingCondition"
                    checked={workingCondition === 0}
                    onChange={() => handleWorkCondition(0)}
                    disabled={isCitizen}
                  />{" "}
                  <label htmlFor="">Unlimited rights to work</label>
                  <br />
                  <input
                    type="radio"
                    value={workingCondition === 1}
                    name="workingCondition"
                    checked={workingCondition === 1}
                    onChange={() => handleWorkCondition(1)}
                    disabled={isCitizen}
                  />{" "}
                  <label htmlFor=""> Limited</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-text-wrapper">
                  <label htmlFor="dateTime">Visa expiry date:</label>
                  <DatePicker
                    selected={visaExpiryDate}
                    onChange={(date) => {
                      setVisaExpiryDate(date);
                      setVisaExpiryDateError("");
                    }}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    disabled={isCitizen || isLimitedWorkCondition}
                  />
                  {visaExpiryDateError && (
                    <div className="alert alert-danger custom-error">
                      {visaExpiryDateError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-text-wrapper">
                  <Input
                    label="Visa Subclass:"
                    type="String"
                    name="visaClass"
                    value={visaClass}
                    error={visaClassError}
                    onChange={(e) => {
                      setVisaClassError("");
                      setVisaClass(e.target.value.toLocaleUpperCase());
                    }}
                    disabled={isCitizen || isLimitedWorkCondition}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-text-wrapper">
                  <Input
                    label="Working limit(H/W):"
                    type="number"
                    name="workingLimit"
                    value={workingLimit}
                    error={workingLimitError}
                    onChange={(e) => {
                      setWorkingLimitError("");
                      setWorkingLimit(e.target.value);
                    }}
                    disabled={isCitizen || isLimitedWorkCondition}
                  />
                </div>
              </div>
            </div>
            <div className="input-text-wrapper">
              <div className="title">Work area & Visa condition:</div>
              <textarea
                disabled={isCitizen || isLimitedWorkCondition}
                className="textarea-info"
                name="workAreaVisaCondition"
                id="workAreaVisaCondition"
                cols="30"
                rows="10"
                value={workAreaVisaCondition}
                onChange={(e) => setWorkAreaVisaCondition(e.target.value)}
              ></textarea>
              {workAreaVisaConditionError && (
                <div className="alert alert-danger custom-error">
                  {workAreaVisaConditionError}
                </div>
              )}
            </div>
            <div className="group-btn-verify">
              <button onClick={() => verifyRightToWork()}>Verify</button>
            </div>
            <div className="text-area-info">
              <div>Note:</div>
              <textarea
                className="textarea-infop"
                name="rejection"
                id="rejection"
                cols="30"
                rows="10"
                value={rejectionText}
                onChange={(e) => handleRejectionText(e.target.value)}
              ></textarea>
            </div>
            <div className="group-btn-reject">
              <div className="row">
                <div className="col-md-4">
                  <button
                    onClick={() =>
                      adminRiderDocumentReviewReject(rejectionText)
                    }
                    disabled={isRejectBtnDisable}
                  >
                    Reject
                  </button>
                </div>
                <div className="col-md-8">
                  <button
                    onClick={() =>
                      adminRiderFurtherInvestigation(rejectionText)
                    }
                    disabled={isRejectBtnDisable}
                    className="request"
                  >
                    Request User For More Information
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alartTriggerMessage !== "" ? (
        <Alart
          message={alartTriggerMessage}
          actionTitle="Ok"
          handleAlartAction={() => handleAlart()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default RightToWork;
