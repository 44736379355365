import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";

export function adminFindMotorbike(regNumber, regState) {
  const data = { registration_number: regNumber, registration_state: regState };
  log(data);
  const functions = getFunctions();
  const findMotor = httpsCallable(functions, config.adminFindMotorbike);
  return findMotor(data);
}

export function adminMotorbikeChangeHidden(motorId, isHidden) {
  const data = { motorbike_uid: motorId, is_hidden_by_user: isHidden };
  const functions = getFunctions();
  const findMotor = httpsCallable(functions, config.adminMotorbikeChangeHidden);
  return findMotor(data);
}

export function adminMotorbikeDocumentReviewFurtherInvestigation(uid, note) {
  const data = { motorbike_uid: uid, motorbike_status_note: note };
  const functions = getFunctions();
  const documentReviewInvestigation = httpsCallable(
    functions,
    config.adminMotorbikeDocumentReviewFurtherInvestigation
  );
  log(data);
  return documentReviewInvestigation(data);
}

export function adminMotorbikeDocumentReviewReject(uid, note) {
  const data = { motorbike_uid: uid, motorbike_status_note: note };
  const functions = getFunctions();
  const documentReviewReject = httpsCallable(
    functions,
    config.adminMotorbikeDocumentReviewReject
  );
  log(data);
  return documentReviewReject(data);
}

export function adminMotorbikeDocumentReviewVehicleRegistration(
  uid,
  ownerName,
  registrationNumber,
  registrationState,
  regoValidityDate
) {
  log("adminMotorbikeDocumentReviewVehicleRegistration");

  const data = {
    motorbike_uid: uid,
    owner_name: ownerName,
    registration_number: registrationNumber,
    registration_state: registrationState,
    rego_validity_date: regoValidityDate,
  };
  const functions = getFunctions();
  const documentReviewReject = httpsCallable(
    functions,
    config.adminMotorbikeDocumentReviewVehicleRegistration
  );
  log(data);
  return documentReviewReject(data);
}

export function adminMotorbikeDocumentReviewVerifyInsurancePolicy(
  uid,
  policyName,
  registrationNumber,
  registrationState,
  policyNumber,
  validityDate,
  insurancePolicyType
) {
  log("adminMotorbikeDocumentReviewVerifyInsurancePolicy");

  const data = {
    motorbike_uid: uid,
    policy_name: policyName,
    registration_number: registrationNumber,
    registration_state: registrationState,
    policy_number: policyNumber,
    insurance_policy_validity_date: validityDate,
    insurance_policy_type: insurancePolicyType,
  };
  const functions = getFunctions();
  const documentReviewReject = httpsCallable(
    functions,
    config.adminMotorbikeDocumentReviewVerifyInsurancePolicy
  );
  log(data);
  return documentReviewReject(data);
}

export function adminMotorbikeRestrict(uid, statusNote) {
  const data = {
    motorbike_uid: uid,
    motorbike_status_note: statusNote,
  };
  const functions = getFunctions();
  const motorbikeRestrict = httpsCallable(
    functions,
    config.adminMotorbikeRestrict
  );
  log(data);
  return motorbikeRestrict(data);
}

export function adminMotorbikeReject(uid, note) {
  const data = { motorbike_uid: uid, motorbike_status_note: note };
  const functions = getFunctions();
  const documentReviewReject = httpsCallable(
    functions,
    config.adminMotorbikeReject
  );
  log(data);
  return documentReviewReject(data);
}

export function adminMotorbikeFurtherInvestigation(uid, note) {
  const data = { motorbike_uid: uid, motorbike_status_note: note };
  const functions = getFunctions();
  const documentReviewInvestigation = httpsCallable(
    functions,
    config.adminMotorbikeFurtherInvestigation
  );
  log(data);
  return documentReviewInvestigation(data);
}

export function adminMotorbikeApprove(uid, note) {
  const data = { motorbike_uid: uid, motorbike_status_note: note };
  const functions = getFunctions();
  const documentReviewInvestigation = httpsCallable(
    functions,
    config.adminMotorbikeApprove
  );
  log(data);
  return documentReviewInvestigation(data);
}

export function getAdminInfo() {
  const functions = getFunctions();
  const adminGetAdminInfo = httpsCallable(functions, config.adminGetAdminInfo);
  return adminGetAdminInfo();
}
