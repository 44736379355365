import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Input from "../../common/input";

import * as riderService from "../findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log, timestampToDateFull } from "../../../helper/helper";

function BonusPercentage({ user, props, handleReloadSingle }) {
  const [bonus, setBonus] = useState("0");
  const [info, setInfo] = useState("");
  const [infoError, setInfoError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [historyShow, setHistoryShow] = useState(false);
  const handleHistoryClose = () => setHistoryShow(false);
  const handleHistoryShow = () => setHistoryShow(true);

  const saveBonusChange = async () => {
    setInfoError("");

    if (info.trim().length < 4) {
      return setInfoError("This field could not be empty.");
    }

    handleClose(true);
    setLoading(true);
    setBtnDisable(true);
    try {
      const result = await riderService.adminRiderChangeBonusPercentage(
        user.rider_uid,
        bonus,
        info
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            setBonus(user.riders_bonus_on_fees * 100);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setBonus(user.riders_bonus_on_fees * 100);
    }
    setLoading(false);
  };

  const handleInputChange = (value) => {
    if (!isNaN(+value)) {
      if (value < 100) {
        if (value !== `${user.riders_bonus_on_fees * 100}`) {
          setBtnDisable(false);
        } else {
          setBtnDisable(true);
        }
        setBonus(value);
      }
    }
  };

  const exitBtn = () => {
    setBtnDisable(true);
    setShow(false);
  };

  const handleDefaultValue = () => {
    setBonus(user.riders_bonus_on_fees * 100);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>Bonus Percentage</h5>
      </div>
      <div className="col-md-4">
        <div className="value">
          %{user ? user.riders_bonus_on_fees * 100 : 0}
        </div>
      </div>
      <div className="col-md-4">
        <ul className="bonus-btn-popups">
          <li>
            <Button
              variant="primary"
              className="edit-btn"
              onClick={() => {
                handleShow();
                handleDefaultValue();
              }}
            >
              Edit
            </Button>
          </li>
          <li>
            {user && user.bonus_change_history !== null ? (
              <Button
                variant="primary"
                className="edit-btn"
                onClick={() => handleHistoryShow()}
              >
                History
              </Button>
            ) : null}
          </li>
        </ul>
        <Modal show={show} className="bonus-modal" onHide={exitBtn}>
          <Modal.Header closeButton>
            <Modal.Title>Bouns Percentage</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              label="Bouns Percentage Number:"
              type="string"
              name="bounsPercentageNumber"
              value={bonus}
              onChange={(e) => handleInputChange(e.target.value)}
            />
            <div className="info-textarea">
              <div className="title">Description:</div>
              <textarea
                className="textarea-info"
                name="workAreaVisaCondition"
                id="workAreaVisaCondition"
                cols="30"
                rows="10"
                value={info}
                onChange={(e) => setInfo(e.target.value)}
              ></textarea>
              {infoError && (
                <div className="alert alert-danger custom-error">
                  {infoError}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="footer">
            <Button
              variant="primary"
              disabled={btnDisable}
              onClick={() => saveBonusChange()}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {user && user.bonus_change_history ? (
          <Modal
            show={historyShow}
            className="note-history-modal"
            onHide={handleHistoryClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bonus Percentage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-design">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>User type</th>
                          <th>Reason description</th>
                          <th>Bonus</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(user.bonus_change_history)
                          .sort(([a], [b]) => b - a)
                          .map(([key, value]) => (
                            <tr key={key}>
                              <td>{timestampToDateFull(key)}</td>
                              <td>{value.user_type}</td>
                              <td>{value.riders_bonus_reason_description}</td>
                              <td className="admin-uid">
                                %{value.riders_bonus_on_fees * 100}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
    </div>
  );
}

export default BonusPercentage;
