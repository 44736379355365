import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";

export function adminFindCustomer(inputType, inputValue) {
  const data = { type: inputType, value: inputValue };
  const functions = getFunctions();
  const findCustomer = httpsCallable(functions, config.adminFindCustomer);
  log(data);
  return findCustomer(data);
}

export function adminCustomerResetLatestActivity(uid) {
  const data = { uid: uid };
  const functions = getFunctions();
  const resetLatestActivity = httpsCallable(
    functions,
    config.adminCustomerResetLatestActivity
  );
  log(data);
  return resetLatestActivity(data);
}

export function adminCustomerChangeAllow(uid, status, reason) {
  let data = {};
  if (reason !== "") {
    data = {
      uid: uid,
      is_user_allowed_by_bearer: status,
      not_allowed_reason: reason,
    };
  } else {
    data = { uid: uid, is_user_allowed_by_bearer: status };
  }
  const functions = getFunctions();
  const changeAllow = httpsCallable(functions, config.adminCustomerChangeAllow);
  log(data);
  return changeAllow(data);
}

export function adminCustomerChangeCRM(uid, crm) {
  const data = { uid: uid, crm_tag: crm };
  const functions = getFunctions();
  const customerChangeCRM = httpsCallable(
    functions,
    config.adminCustomerChangeCRM
  );
  log(data);
  return customerChangeCRM(data);
}

export function adminCustomerChangeLoyaltyTier(uid, loyalty) {
  const data = { uid: uid, loyalty_tier: loyalty };
  const functions = getFunctions();
  const changeLoyaltyTier = httpsCallable(
    functions,
    config.adminCustomerChangeLoyaltyTier
  );
  log(data);
  return changeLoyaltyTier(data);
}

export function adminCustomerChangeOrderLimit(uid, limits) {
  const data = { uid: uid, order_limits: limits };
  const functions = getFunctions();
  const changeOrderLimit = httpsCallable(
    functions,
    config.adminCustomerChangeOrderLimit
  );
  log(data);
  return changeOrderLimit(data);
}

export function adminCustomerAddNote(uid, title, note, probabilitySelected) {
  const data = {
    uid: uid,
    note_title: title,
    note_text: note,
    note_user_engagement_scale: probabilitySelected,
  };

  const functions = getFunctions();
  const customerAddNote = httpsCallable(functions, config.adminCustomerAddNote);
  log(data);
  return customerAddNote(data);
}

export function adminGetCustomerInfo(customerID) {
  const data = { customer_user_id: customerID };
  const functions = getFunctions();
  log(data);
  const findTransport = httpsCallable(functions, config.adminGetCustomerInfo);
  return findTransport(data);
}

export function getAdminInfo() {
  const functions = getFunctions();
  const adminGetAdminInfo = httpsCallable(functions, config.adminGetAdminInfo);
  return adminGetAdminInfo();
}
