import React, { useEffect } from "react";
import "./parcel.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";
import { timestampToDateFull } from "../../helper/helper.jsx";
import HistorySingleComponents from "./parcelHistorySingleComponents";
import { useState } from "react";

function ParcelHistorySingle(props) {
  const [time] = useState(
    props.location.state ? props.location.state.key : null
  );
  const [value] = useState(
    props.location.state ? props.location.state.value : null
  );

  log(value);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (value === null) {
      return props.history.replace("/home");
    }
  }, []);

  const handleHistoryValue = () => {
    if (value !== null) {
      return (
        <div>
          <HistorySingleComponents
            header="Admin UID"
            value={value.admin_uid}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Date"
            value={timestampToDateFull(time)}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Data model version"
            value={value.data_model_version}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Parcel type"
            value={value.parcel_type}
            isChanged={value.parcel_type_was_changed}
          />
          <HistorySingleComponents
            header="Shown for orders"
            value={value.is_shown_for_orders}
            isChanged={value.is_shown_for_orders_was_changed}
          />
          <HistorySingleComponents
            header="List index on the apps was changed"
            value={value.list_index_on_the_apps_was_changed}
            isChanged={value.list_index_on_the_apps_was_changed}
          />
          <HistorySingleComponents
            header="Parcel description"
            value={value.parcel_description}
            isChanged={value.parcel_description_was_changed}
          />
          <HistorySingleComponents
            header="Parcel min weight"
            value={value.parcel_min_weight}
            isChanged={value.parcel_min_weight_was_changed}
          />
          <HistorySingleComponents
            header="Parcel max weight"
            value={value.parcel_max_weight}
            isChanged={value.parcel_max_weight_was_changed}
          />
          <HistorySingleComponents
            header="Riding"
            value={value.vehicle_type.riding}
            isChanged={value.vehicle_type.riding_was_changed}
          />
          <HistorySingleComponents
            header="Cycling"
            value={value.vehicle_type.cycling}
            isChanged={value.vehicle_type.cycling_was_changed}
          />
          <HistorySingleComponents
            header="Walking"
            value={value.vehicle_type.walking}
            isChanged={value.vehicle_type.walking_was_changed}
          />
          <HistorySingleComponents
            header="Change note"
            value={value.change_note}
            isChanged={false}
          />
        </div>
      );
    }
  };

  return <div className="history-single">{handleHistoryValue()}</div>;
}

export default ParcelHistorySingle;
