import React, { useEffect, useState } from "react";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import "../motorbike/motorbike.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as service from "./motorbikesServices.js";

import Select from "../common/select";
import Input from "../common/input";
import { log } from "../../helper/helper";
function MotorbikeLists(props) {
  const [isLoading, setLoading] = useState(true);
  const [motorbikes, setMotorbikes] = useState([]);
  const [FilterList, setFilterList] = useState([]);
  let target = [{ id: "1", name: "Registration Number" }];
  const [targetSelected, setTargetSelected] = useState("");
  const [filter, setFilter] = useState("");

  const getAllMotorbikes = async () => {
    try {
      const result = await service.adminMotorbikeInbox();
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setMotorbikes(result.data.motorbikeList);
            setFilterList(result.data.motorbikeList);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleFilterData = (value) => {
    setFilter(value);
    if (value !== "") {
      switch (targetSelected) {
        case "Registration Number":
          const newList = motorbikes.filter(
            (li) =>
              li.registration_number
                .toLowerCase()
                .indexOf(value.toLowerCase()) !== -1
          );
          setFilterList(newList);
          break;
        default:
          break;
      }
    } else {
      setFilterList(motorbikes);
    }
  };
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });

    getAllMotorbikes();
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleStatus = (motorbikeLatestStatus) => {
    switch (motorbikeLatestStatus) {
      case "Approved":
        return (
          <div className="motorbike-status">
            <span className="approved">{motorbikeLatestStatus}</span>
          </div>
        );
      case "Processing":
        return (
          <div className="motorbike-status">
            <span className="processing">{motorbikeLatestStatus}</span>
          </div>
        );
      case "Further Investigation":
        return (
          <div className="motorbike-status">
            <span className="further-investigation">
              {motorbikeLatestStatus}
            </span>
          </div>
        );
      case "Restricted":
        return (
          <div className="motorbike-status">
            <span className="restricted">{motorbikeLatestStatus}</span>
          </div>
        );
      case "Rejected":
        return (
          <div className="motorbike-status">
            <span className="rejected">{motorbikeLatestStatus}</span>
          </div>
        );
      case "Removed":
        return (
          <div className="motorbike-status">
            <span className="removed">{motorbikeLatestStatus}</span>
          </div>
        );
      default:
        break;
    }
  };

  const handleButtonControl = (motorbike) => {
    if (motorbike.motorbike_latest_status === "Processing") {
      if (motorbike.is_allow_show_edit_button_result) {
        return (
          <button
            className="single-view"
            onClick={() =>
              props.history.push(
                "/home/findMotorbike/motorbikeList/motorbike",
                {
                  motorbike: motorbike,
                }
              )
            }
          >
            View
          </button>
        );
      } else {
        return null;
      }
    } else {
      return (
        <button
          className="single-view"
          onClick={() =>
            props.history.push("/home/findMotorbike/motorbikeList/motorbike", {
              motorbike: motorbike,
            })
          }
        >
          View
        </button>
      );
    }
  };

  return (
    <div className="show-all-list">
      <ToastContainer />
      {renderSpinner()}
      <div className="headline">
        <h3>Motorbike List</h3>
      </div>
      <div className="filter-section">
        <div className="row">
          <div className="col-md-6">
            <Select
              name="target"
              options={target}
              onChange={(e) => {
                setTargetSelected(e.target.value);
                setFilter("");
                setFilterList(motorbikes);
              }}
              disabled={false}
              defaultValue="Select your Option"
              disabledDefaultValue={false}
              disableSelect={false}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeHolder="filter list"
              type="String"
              name="message"
              value={filter}
              onChange={(e) => handleFilterData(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Make</th>
            <th>Model</th>
            <th>Status</th>
            <th>Registration State</th>
            <th>Registration Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {FilterList.map((motorbike) => (
            <tr key={motorbike.motorbike_uid}>
              <td>{motorbike.make}</td>
              <td>{motorbike.model}</td>
              <td>{handleStatus(motorbike.motorbike_latest_status)}</td>
              <td>{motorbike.registration_state}</td>
              <td>{motorbike.registration_number}</td>
              <td>{handleButtonControl(motorbike)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MotorbikeLists;
