import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import * as helper from "../../../helper/helper";
import ridingImage from "../../../assets/riding.png";
import walkingImage from "../../../assets/walking.png";
import cyclingImage from "../../../assets/cycling.png";
import refresh from "../../../assets/refresh-location.png";
import * as service from "../findRiderService";
import Spinner from "../../common/spinner";

function RiderStatus({ user, props }) {
  const [motorbikeName, setMotorbikeName] = useState("");
  const [registrationState, setRegistrationState] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [stateTime, setStateTime] = useState(
    user && user.last_state_update ? user.last_state_update : null
  );
  const [locationLat, setLocationLat] = useState(user ? user.current_lat : 0.0);
  const [locationLng, setLocationLng] = useState(user ? user.current_lng : 0.0);

  useEffect(() => {
    if (user && user.mode_of_transport === "riding") {
      getMotorBikeInfo();
    }
  }, []);

  const checkModes = () => {
    if (user.mode_of_transport === "riding") {
      return (
        <div className="transport-type">
          <div className="row">
            <div className="col-md-2">
              <img src={ridingImage} alt="riding" />
            </div>
            <div className="col-md-10">
              <div className="motor-bike">
                <div className="motor-name">{motorbikeName}</div>
                <div className="motor-register-info">
                  <div className="motor-state">{registrationState}</div>
                  <div className="motor-register">{registrationNumber}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (user.mode_of_transport === "walking") {
      return (
        <div className="transport-type">
          <img src={walkingImage} alt="walking" />
        </div>
      );
    } else if (user.mode_of_transport === "cycling") {
      return (
        <div className="transport-type">
          <img src={cyclingImage} alt="cycling" />
        </div>
      );
    }
  };

  const containerStyle = {
    width: "100%",
    height: "110px",
  };

  const center = {
    lat: locationLat,
    lng: locationLng,
  };

  const loadGoogleMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        options={{
          mapId: helper.getMapIdBasedonEnvironment(),
        }}
      >
        <Marker position={center} />
      </GoogleMap>
    );
  };

  const handleRiderLocationRefresh = async () => {
    setLoading(true);
    try {
      const result = await service.adminRiderLocationRefresh(user.rider_uid);
      helper.log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            setLoading(false);
            setStateTime(result.data.last_state_update);
            setLocationLat(result.data.current_lat);
            setLocationLng(result.data.current_lng);
            return;
          case 401:
            helper.log(result.data.message);
            setLoading(false);
            return props.history.replace("/login");
          case 500:
            setLoading(false);
            return helper.log(result.data.message);
          default:
            setLoading(false);
            return helper.log(result.data.message);
        }
      }
    } catch (error) {
      helper.log(error);
    }
    setLoading(false);
  };

  const getMotorBikeInfo = async () => {
    try {
      const result = await service.adminGetMotorbikeInfo(user.motorbike_id);
      helper.log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            setMotorbikeName(
              result.data.motorbike.make + " " + result.data.motorbike.model
            );
            setRegistrationState(result.data.motorbike.registration_state);
            setRegistrationNumber(result.data.motorbike.registration_number);
            return;
          case 401:
            helper.log(result.data.message);
            return props.history.replace("/login");
          case 500:
            return helper.log(result.data.message);
          default:
            return helper.log(result.data.message);
        }
      }
    } catch (error) {
      helper.log(error);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="status-map-wrapper">
      {renderSpinner()}
      <div className="row">
        <div className="col-md-4">
          <h5 className="status">
            Status
            {user && user.online ? (
              <span className="online">(online)</span>
            ) : (
              <span className="offline">(offline)</span>
            )}
          </h5>
        </div>
        <div className="col-md-4">{user ? checkModes() : null}</div>
        <div className="col-md-4">
          <div className="map">{user ? loadGoogleMap() : null}</div>
        </div>
      </div>
      {user && user.latest_user_activity_time ? (
        <div className="latest-activity-warpper">
          <div className="row">
            <div className="col-md-6">
              <h5>Latest Live Location Update</h5>
            </div>
            <div className="col-md-6">
              <div className="value">
                <span>
                  <button onClick={() => handleRiderLocationRefresh()}>
                    <img src={refresh} alt="refresh" />
                  </button>
                </span>
                {user ? helper.timestampToDateFull(stateTime) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default RiderStatus;
