import React, { useEffect, useState } from "react";
import Input from "../common/input";
import Select from "../common/select";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "react-bootstrap";
import "./notification.scss";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import "bootstrap/dist/css/bootstrap.css";
import * as service from "./notificationServices.js";
import { log } from "../../helper/helper";

function Notification(props) {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const selectOptionDefaultValue = useState(
    props.history.location.name
      ? props.history.location.name
      : "Select your Option"
  );
  const [isLoading, setLoading] = useState(false);

  const userID = props.history.location.uid ? props.history.location.uid : "";
  const userType = props.history.location.userType
    ? props.history.location.userType
    : "";

  const [isSelectOptionDisable, setSelectOptionDisable] = useState(false);
  const [targetSelected, setTargetSelected] = useState("All android customers");

  let target = [
    { id: "0", name: "All android customers" },
    { id: "1", name: "All ios customers" },
    { id: "2", name: "All web customers" },
    { id: "3", name: "All mac customers" },
    { id: "4", name: "All individual customers" },
    { id: "5", name: "All business customers" },
    { id: "6", name: "All customers" },
    { id: "7", name: "All android riders" },
    { id: "8", name: "All ios riders" },
    { id: "9", name: "All riders" },
  ];

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, () => {});

    if (userType !== "") {
      setSelectOptionDisable(true);
      setTargetSelected("custom");
    }
  });

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const sendNotification = async () => {
    if (targetSelected !== "" && title !== "" && message !== "") {
      setLoading(true);
      try {
        const result = await service.adminSendPushNotification(
          targetSelected,
          title,
          message,
          userID,
          userType
        );
        setLoading(false);
        log(result.data);
        if (result) {
          switch (result.data.code) {
            case 200:
              props.history.goBack();
              return toast.success(result.data.message);
            case 401:
              toast.error(result.data.message);
              return this.props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        log(error);
        toast.error(error.message);
        setLoading(false);
      }
    } else {
      toast.error("Fill All Inputs");
    }
  };

  const setTargetData = (value) => {
    setTargetSelected(value);
  };

  return (
    <div className="notification">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-6">
          <Select
            name="target"
            label="Target"
            options={target}
            onChange={(e) => setTargetData(e.target.value)}
            disabled={isSelectOptionDisable}
            defaultValue={selectOptionDefaultValue}
            disabledDefaultValue={props.history.location.uid ? false : true}
            disableSelect={false}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="Notification Title:"
            type="String"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="row mg-custom">
        <div className="col-md-12">
          <Input
            label="Notification Message:"
            type="String"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
      <div className="row mg-custom">
        <div className="col-md-12">
          <Button className="notif-send" onClick={sendNotification}>
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Notification;
