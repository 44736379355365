import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./sendEmail.scss";

import Input from "../common/input";
import Select from "../common/select";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as service from "./sendEmailServices";
import { log } from "../../helper/helper";

function SendEmail(props) {
  const [email, setEmail] = useState(
    props.history.location.email ? props.history.location.email : ""
  );
  const [emailError, setEmailError] = useState("");
  const [userTypeSelected, setUserTypeSelected] = useState("");
  //   props.history.location.userType &&
  //     props.history.location.userType === "Rider"
  //     ? "Rider"
  //     : "Business Customers"
  // );
  const [isLoading, setLoading] = useState(false);
  const [isEmailDisable, setIsEmailDisable] = useState(false);
  const [isSelectedUserTypeDisable, setIsSelectedUserTypeDisable] =
    useState(false);
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [targetSelected, setTargetSelected] = useState("");

  const [customerTitle, setCustomerTitle] = useState("");
  const [customerTitleError, setCustomerTitleError] = useState("");

  const [industryName, setIndustryName] = useState("");
  const [industryNameError, setIndustryNameError] = useState("");

  const [customerMessage, setCustomerMessage] = useState("");

  const [target, setTarget] = useState([]);

  let userTypeSelect = [
    {
      id: "0",
      name: "Business Customers",
    },
    {
      id: "1",
      name: "Rider",
    },
  ];

  useEffect(() => {
    if (email !== "") {
      setIsEmailDisable(true);
      setIsSelectedUserTypeDisable(true);
    }
    // if (userTypeSelected === "Rider") {
    //   handleUserTypeSelection("Rider");
    // } else {
    //   handleUserTypeSelection("Business Customers");
    // }
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const sendEmail = async () => {
    if (email.length == 0 || emailError.length > 0) {
      setEmailError("Input valid email");
      return;
    }
    let temp = target.find((o) => o.name === targetSelected);

    if (userTypeSelected === "Business Customers") {
      setCustomerTitleError("");
      if (customerTitle.trim().length < 2) {
        return setCustomerTitleError("Recipient Name / Title is Mandatory");
      }

      // if (temp.serverValue === "business-customer-industry-surveys") {
      //   setIndustryNameError("");
      //   if (industryName.trim().length < 2) {
      //     return setIndustryNameError("Industry Name is Mandatory");
      //   }
      // }
    }

    setLoading(true);

    try {
      const result = await service.adminSendEmail(
        userTypeSelected,
        email,
        temp.serverValue,
        customerTitle,
        customerMessage,
        industryName
      );
      log(result);
      if (result) {
        switch (result.data.code) {
          case 200:
            setLoading(false);
            setCustomerTitle("");
            setEmail("");
            return toast.success(result.data.message);
          case 401:
            toast.error(result.data.message);
            setLoading(false);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleUserTypeSelection = (value) => {
    setUserTypeSelected(value);
    if (value == "Rider") {
      setTargetSelected("First Message: Start a new Bearer Application");
      setTarget([
        {
          id: "0",
          name: "First Message: Start a new Bearer Application",
          serverValue: "onboarding-reminder-to-start-application",
        },
        {
          id: "1",
          name: "Reminder: Complete an existing Bearer Application",
          serverValue: "onboarding-reminder-to-complete-application",
        },
        {
          id: "2",
          name: "Reminder: Check the Documents’ Portal",
          serverValue: "onboarding-reminder-to-check-document-portal",
        },
        // {
        //   id: "3",
        //   name: "Go Online Stay Online Promotion",
        //   serverValue: "bearers-stay-online-promotion-announcement",
        // },
      ]);
    } else {
      setTargetSelected("Introducing Bearer to Business Customers");
      setTarget([
        {
          id: "0",
          name: "Introducing Bearer to Business Customers",
          serverValue: "business-customer-bearer-introduction",
        },
        // {
        //   id: "1",
        //   name: "Bearer in a New Industry Surveys",
        //   serverValue: "business-customer-industry-surveys",
        // },
      ]);
    }
  };

  return (
    <div className="admin-send-email">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-12">
          <Select
            name="userType"
            label="User Type"
            options={userTypeSelect}
            onChange={(e) => handleUserTypeSelection(e.target.value)}
            disabled={false}
            disableSelect={isSelectedUserTypeDisable}
            value={userTypeSelected}
            defaultValue="Select target user type"
          />
        </div>
        {userTypeSelected.length > 0 && (
          <>
            <div className="col-md-12">
              <Select
                name="target"
                label="Template name"
                options={target}
                onChange={(e) => setTargetSelected(e.target.value)}
                disabled={false}
                disableSelect={false}
              />
            </div>

            <div className="col-md-12">
              <Input
                label="Email Address:"
                type="String"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (e.target.value.match(isValidEmail)) {
                    setEmailError("");
                  } else {
                    setEmailError("Input valid email");
                  }
                }}
                error={emailError}
                disabled={isEmailDisable}
              />
            </div>
            {userTypeSelected === "Business Customers" && (
              <>
                <div className="col-md-12">
                  <Input
                    label="Recipient’s Title:"
                    type="String"
                    name="email"
                    value={customerTitle}
                    onChange={(e) => {
                      setCustomerTitle(e.target.value);
                    }}
                    error={customerTitleError}
                  />
                </div>
                {targetSelected.includes(
                  "Introducing Bearer to Business Customers"
                ) && (
                  <div className="col-md-12">
                    <Input
                      label="Customised Message:(Optional)"
                      type="String"
                      name="email"
                      value={customerMessage}
                      onChange={(e) => {
                        setCustomerMessage(e.target.value);
                      }}
                    />
                  </div>
                )}
                {targetSelected.includes("New Industry Surveys") && (
                  <div className="col-md-12">
                    <Input
                      label="Industry Name"
                      type="String"
                      name="email"
                      value={industryName}
                      onChange={(e) => {
                        setIndustryName(e.target.value);
                      }}
                      error={industryNameError}
                    />
                  </div>
                )}
              </>
            )}

            {target.length > 0 && (
              <div className="mg-custom">
                <div className="col-md-12">
                  <Button className="email-send" onClick={sendEmail}>
                    Send
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SendEmail;
