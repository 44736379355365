import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "../../common/select";
import Input from "../../common/input";
import DatePicker from "react-datepicker";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import * as service from "../findMotorbikeService";
import Moment from "moment";
import { log } from "../../../helper/helper";

function MotorbikeStatusPopups({ motor, props, handleReloadSingle }) {
  const [isLoading, setLoading] = useState(false);

  // MARK:-   ---------- First Modal --------------

  const handleCloseFirst = () => setShowFirst(false);
  const handleShowFirst = () => setShowFirst(true);
  const [showFirst, setShowFirst] = useState(false);

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [isOwnerAddress, setOwnerAddress] = useState(0);
  const [motorMake, setMotorMake] = useState(0);
  const [motorModel, setMotorModel] = useState(0);
  const [motorEngine, setMotorEngine] = useState(0);
  const [numberPlate, setNumberPlate] = useState("");
  const [numberPlateError, setNumberPlateError] = useState("");
  const [selectState, setSelectState] = useState("ACT");
  const [certificateExpiry, setCertificateExpiry] = useState("");
  const [certificateExpiryError, setCertificateExpiryError] = useState("");

  const [textareaValueVehicle, setTextareaValueVehicle] = useState("");
  const [isRejectBtnDisable, setRejectBtnDisable] = useState(true);

  let stateData = [
    { id: "0", name: "ACT" },
    { id: "1", name: "NSW" },
    { id: "2", name: "NT" },
    { id: "3", name: "QLD" },
    { id: "4", name: "SA" },
    { id: "5", name: "TAS" },
    { id: "6", name: "VIC" },
    { id: "7", name: "WA" },
  ];

  // MARK:-   ---------- Second Modal --------------

  const handleCloseSecond = () => setShowSecond(false);
  const handleShowSecond = () => setShowSecond(true);
  const [showSecond, setShowSecond] = useState(false);
  const [policyFullName, setPolicyFullName] = useState("");
  const [policyFullNameError, setPolicyFullNameError] = useState("");
  const [isPolicyOwnerAddress, setPolicyOwnerAddress] = useState(0);
  const [motorMakePolicy, setMotorMakePolicy] = useState(0);
  const [motorModelPolicy, setMotorModelPolicy] = useState(0);
  const [motorEnginePolicy, setMotorEnginePolicy] = useState(0);
  const [policyNumberPlate, setPolicyNumberPlate] = useState("");
  const [policyNumberPlateError, setPolicyNumberPlateError] = useState("");
  const [policyNumber, setPolicyFullNumber] = useState("");
  const [policyNumberError, setPolicyFullNumberError] = useState("");
  const [selectPolicyState, setPolicySelectState] = useState("ACT");
  const [insuranceType, setInsuranceType] = useState(0);
  const [userType, setUserType] = useState(0);
  const [policyExpiry, setPolicyExpiry] = useState("");
  const [policyExpiryError, setPolicyExpiryError] = useState();
  const [insuranceCompany, setInsuranceCompany] = useState(0);
  const [textareaValuePolicy, setTextareaValuePolicy] = useState("");
  const [isRejectBtnDisablePolicy, setRejectBtnDisablePolicy] = useState(true);
  const ownerAddress = `${
    motor ? motor.legal_address_segmented.street_no : ""
  } ${motor ? motor.legal_address_segmented.street_name : ""} ${
    motor ? motor.legal_address_segmented.region_area : ""
  } ${motor ? motor.legal_address_segmented.state : ""} ${
    motor ? motor.legal_address_segmented.post_code : ""
  }`;

  const english = /^[A-Za-z]*$/;
  const englishFull = /^[A-Za-z0-9]*$/;
  const englishValidation = /^[a-zA-Z0-9' '.-]*$/;

  const [registrationUrl, setRegistrationUrl] = useState("");
  const [policyUrl, setPolicyUrl] = useState("");

  const vehicleRegistration = (certificate) => {
    if (certificate.trim() == "") {
      return;
    }

    const storage = getStorage();
    const starsRef = ref(storage, certificate);

    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setRegistrationUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const handlePolicy = (certificate) => {
    if (certificate.trim() == "") {
      return;
    }
    const storage = getStorage();
    const starsRef = ref(storage, certificate);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setPolicyUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  useEffect(() => {
    if (motor) {
      vehicleRegistration(motor.rego_certificate_url);
      handlePolicy(motor.ipc_policy_certificate_url);
    }
  }, []);

  const verifyRegistration = async () => {
    setFullNameError("");
    setNumberPlateError("");
    setCertificateExpiryError("");

    if (
      !english.test(fullName.replace(/\s/g, "")) ||
      fullName.replace(/\s/g, "") === ""
    ) {
      return setFullNameError("Must contain English Letter(s) only");
    }

    if (isOwnerAddress === 1) {
      return toast.error("Owner address is wrong");
    }

    if (motorMake === 1) {
      return toast.error("motor make is wrong");
    }

    if (motorModel === 1) {
      return toast.error("motor model is wrong");
    }

    if (motorEngine === 1) {
      return toast.error("motor engine is wrong");
    }

    if (
      !englishFull.test(numberPlate) ||
      numberPlate.replace(/\s/g, "") === ""
    ) {
      return setNumberPlateError(
        "Must contain English Letter(s) and/or Number(s)"
      );
    }

    if (certificateExpiry === "" || certificateExpiry === null) {
      return setCertificateExpiryError("Pick a date to continue");
    }

    var sortedBirth = new Date(certificateExpiry);
    const momentTime = Moment(sortedBirth).format("YYYY-MM-DD");

    setLoading(true);

    try {
      const result =
        await service.adminMotorbikeDocumentReviewVehicleRegistration(
          motor.motorbike_uid,
          fullName.toLocaleUpperCase(),
          numberPlate.toLocaleUpperCase(),
          selectState,
          momentTime
        );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setShowFirst(false);
            setShowSecond(true);
            setFullName("");
            setNumberPlate("");
            setCertificateExpiry("");
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleVerifyPolicy = async () => {
    if (
      policyFullName.replace(/\s/g, "") === "" ||
      !english.test(policyFullName.replace(/\s/g, ""))
    ) {
      return setPolicyFullNameError("Must contain English Letter(s) only");
    }

    if (isPolicyOwnerAddress === 1) {
      return toast.error("Owner address is wrong");
    }

    if (motorMakePolicy === 1) {
      return toast.error("motor make is wrong");
    }

    if (motorModelPolicy === 1) {
      return toast.error("motor model is wrong");
    }

    if (motorEnginePolicy === 1) {
      return toast.error("motor engine is wrong");
    }

    if (
      policyNumberPlate.replace(/\s/g, "") === "" ||
      !englishFull.test(policyNumberPlate.replace(/\s/g, ""))
    ) {
      return setPolicyNumberPlateError(
        "Must contain English Letter(s) and/or Number(s)"
      );
    }

    if (policyNumber.trim() === "") {
      return setPolicyFullNumberError(
        "Must contain English Letter(s) and/or Number(s)"
      );
    }

    if (insuranceType === 2) {
      return toast.error("Insurance Type is wrong");
    }

    if (userType !== 0) {
      return toast.error("user type is wrong");
    }

    if (policyExpiry === "" || policyExpiry === null) {
      return setPolicyExpiryError("Pick a date to continue");
    }

    if (insuranceCompany === 1) {
      return toast.error("Insurance Company is wrong");
    }

    var sortedBirth = new Date(policyExpiry);
    const momentTime = Moment(sortedBirth).format("YYYY-MM-DD");

    var insurancePolicyType = "";
    if (insuranceType === 0) {
      insurancePolicyType = "Comprehensive Insurance";
    } else {
      insurancePolicyType = "Third Party Property Damage";
    }

    setLoading(true);
    try {
      const result =
        await service.adminMotorbikeDocumentReviewVerifyInsurancePolicy(
          motor.motorbike_uid,
          policyFullName.toLocaleUpperCase(),
          policyNumberPlate.toLocaleUpperCase(),
          selectPolicyState,
          policyNumber.toLocaleUpperCase(),
          momentTime,
          insurancePolicyType
        );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success("Done successfully");
            setShowFirst(false);
            setShowSecond(false);
            setPolicyFullName("");
            setPolicyNumberPlate("");
            setPolicyFullNumber("");
            setPolicyExpiry("");
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleReject = async (reasonText) => {
    setLoading(true);
    try {
      const result = await service.adminMotorbikeDocumentReviewReject(
        motor.motorbike_uid,
        reasonText
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setShowFirst(false);
            setShowSecond(false);
            setTextareaValueVehicle("");
            setTextareaValuePolicy("");
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleMoreInformationRequest = async (moreInfoText) => {
    setLoading(true);
    try {
      const result =
        await service.adminMotorbikeDocumentReviewFurtherInvestigation(
          motor.motorbike_uid,
          moreInfoText
        );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setShowFirst(false);
            setShowSecond(false);
            setTextareaValueVehicle("");
            setTextareaValuePolicy("");
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div>
      <ToastContainer />
      {renderSpinner()}
      {motor.motorbike_latest_status !== "Processing" ? null : (
        <Button
          variant="primary"
          className="edit-btn"
          onClick={handleShowFirst}
        >
          Review
        </Button>
      )}

      <Modal
        show={showFirst}
        className="crm-modal modal-popup-motorbike-status"
        onHide={handleCloseFirst}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <iframe src={registrationUrl} title="pdf" />
              </div>
              <div className="col-md-5">
                <div className="col-md-12">
                  <div className="modal-text">
                    <Input
                      label="Owner's Full Name:"
                      type="String"
                      name="fullName"
                      value={fullName}
                      onChange={(e) => {
                        const value = e.target.value;
                        var english = /^[A-Za-z' ']*$/;
                        if (english.test(value.replace(/\s/g, ""))) {
                          setFullName(value);
                          setFullNameError("");
                        } else {
                          setFullName("");
                          setFullNameError(
                            "Must contain English Letter(s) only"
                          );
                        }
                      }}
                      error={fullNameError}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="modal-radio">
                    <div className="title">Owner's Address:</div>
                    <input
                      type="radio"
                      value={isOwnerAddress === 0}
                      name="isOwnerAddress"
                      checked={isOwnerAddress === 0}
                      onChange={() => setOwnerAddress(0)}
                    />
                    <label htmlFor="">{ownerAddress}</label>
                    <br />
                    <input
                      type="radio"
                      value={isOwnerAddress === 1}
                      name="isOwnerAddress"
                      checked={isOwnerAddress === 1}
                      onChange={() => setOwnerAddress(1)}
                    />
                    <label htmlFor="">Other</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">Make:</div>
                      <input
                        type="radio"
                        value={motorMake === 0}
                        name="motorMake"
                        checked={motorMake === 0}
                        onChange={() => setMotorMake(0)}
                      />
                      <label htmlFor="">{motor ? motor.make : ""}</label>
                      <br />
                      <input
                        type="radio"
                        value={motorMake === 1}
                        name="motorMake"
                        checked={motorMake === 1}
                        onChange={() => setMotorMake(1)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">Model:</div>
                      <input
                        type="radio"
                        value={motorModel === 0}
                        name="motorModel"
                        checked={motorModel === 0}
                        onChange={() => setMotorModel(0)}
                      />
                      <label htmlFor="">{motor ? motor.model : ""}</label>
                      <br />
                      <input
                        type="radio"
                        value={motorModel === 1}
                        name="motorModel"
                        checked={motorModel === 1}
                        onChange={() => setMotorModel(1)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">Engine:</div>
                      <input
                        type="radio"
                        value={motorEngine === 0}
                        name="motorEngine"
                        checked={motorEngine === 0}
                        onChange={() => setMotorEngine(0)}
                      />
                      <label htmlFor="">{motor ? motor.engine_size : ""}</label>
                      <br />
                      <input
                        type="radio"
                        value={motorEngine === 1}
                        name="motorEngine"
                        checked={motorEngine === 1}
                        onChange={() => setMotorEngine(1)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="modal-text mg-top">
                      <Input
                        label="Number Plate:"
                        type="String"
                        name="numberPlate"
                        value={numberPlate}
                        onChange={(e) => {
                          const value = e.target.value;
                          var english = /^[A-Za-z0-9]*$/;
                          if (english.test(value.replace(/\s/g, ""))) {
                            setNumberPlate(value);
                            setNumberPlateError("");
                          } else {
                            setNumberPlate("");
                            setNumberPlateError(
                              "Must contain English Letter(s) and/or Number(s)"
                            );
                          }
                        }}
                        error={numberPlateError}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 mg-top">
                    <Select
                      name="state"
                      label="State:"
                      options={stateData}
                      onChange={(e) => setSelectState(e.target.value)}
                      disableSelect={false}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mg-btm">
                    <label htmlFor="dateTime">Certificate Expiry Date:</label>
                    <DatePicker
                      selected={certificateExpiry}
                      onChange={(date) => setCertificateExpiry(date)}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                    />
                    {certificateExpiryError && (
                      <div className="alert alert-danger custom-error">
                        {certificateExpiryError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="group-btn-verify">
                    <button
                      onClick={() => verifyRegistration()}
                      disabled={false}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <textarea
                    className="textarea-info"
                    name="moreInfo"
                    id="moreInfo"
                    cols="30"
                    rows="10"
                    value={textareaValueVehicle}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTextareaValueVehicle(value);
                      if (value.replace(/\s/g, "").length > 1) {
                        setRejectBtnDisable(false);
                      } else {
                        setRejectBtnDisable(true);
                      }
                    }}
                  ></textarea>
                  <div className="group-btn-reject">
                    <button
                      onClick={() => handleReject(textareaValueVehicle)}
                      disabled={isRejectBtnDisable}
                    >
                      Reject
                    </button>
                    <button
                      onClick={() =>
                        handleMoreInformationRequest(textareaValueVehicle)
                      }
                      disabled={isRejectBtnDisable}
                      className="request"
                    >
                      Request User For More Information
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer"></Modal.Footer>
      </Modal>

      <Modal
        show={showSecond}
        className="crm-modal modal-popup-motorbike-status"
        onHide={handleCloseSecond}
      >
        <Modal.Header closeButton>
          <Modal.Title>Insurance Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <iframe src={policyUrl} title="pdf" />
              </div>
              <div className="col-md-5">
                <div className="col-md-12">
                  <div className="modal-text">
                    <Input
                      label="Policy Holder's Full Name:"
                      type="String"
                      name="policyFullName"
                      value={policyFullName}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          value.replace(/\s/g, "") !== "" &&
                          english.test(value.replace(/\s/g, ""))
                        ) {
                          setPolicyFullName(value);
                          setPolicyFullNameError("");
                        } else {
                          setPolicyFullName("");
                          setPolicyFullNameError(
                            "Must contain English Letter(s) only"
                          );
                        }
                      }}
                      error={policyFullNameError}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="modal-radio">
                    <div className="title">Owner's Address:</div>
                    <input
                      type="radio"
                      value={isPolicyOwnerAddress === 0}
                      name="isPolicyOwnerAddress"
                      checked={isPolicyOwnerAddress === 0}
                      onChange={() => setPolicyOwnerAddress(0)}
                    />
                    <label htmlFor="">{ownerAddress}</label>
                    <br />
                    <input
                      type="radio"
                      value={isPolicyOwnerAddress === 1}
                      name="isPolicyOwnerAddress"
                      checked={isPolicyOwnerAddress === 1}
                      onChange={() => setPolicyOwnerAddress(1)}
                    />
                    <label htmlFor=""> Other</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">Make:</div>
                      <input
                        type="radio"
                        value={motorMakePolicy === 0}
                        name="motorMakePolicy"
                        checked={motorMakePolicy === 0}
                        onChange={() => setMotorMakePolicy(0)}
                      />
                      <label htmlFor="">{motor ? motor.make : ""}</label>
                      <br />
                      <input
                        type="radio"
                        value={motorMakePolicy === 1}
                        name="motorMakePolicy"
                        checked={motorMakePolicy === 1}
                        onChange={() => setMotorMakePolicy(1)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">Model:</div>
                      <input
                        type="radio"
                        value={motorModelPolicy === 0}
                        name="motorModelPolicy"
                        checked={motorModelPolicy === 0}
                        onChange={() => setMotorModelPolicy(0)}
                      />
                      <label htmlFor="">{motor ? motor.model : ""}</label>
                      <br />
                      <input
                        type="radio"
                        value={motorModelPolicy === 1}
                        name="motorModelPolicy"
                        checked={motorModelPolicy === 1}
                        onChange={() => setMotorModelPolicy(1)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">Engine:</div>
                      <input
                        type="radio"
                        value={motorEnginePolicy === 0}
                        name="motorEnginePolicy"
                        checked={motorEnginePolicy === 0}
                        onChange={() => setMotorEnginePolicy(0)}
                      />
                      <label htmlFor="">{motor ? motor.engine_size : ""}</label>
                      <br />
                      <input
                        type="radio"
                        value={motorEnginePolicy === 1}
                        name="motorEnginePolicy"
                        checked={motorEnginePolicy === 1}
                        onChange={() => setMotorEnginePolicy(1)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="modal-text mg-top">
                      <Input
                        label="Number Plate:"
                        type="String"
                        name="policyNumberPlate"
                        value={policyNumberPlate}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (
                            value.replace(/\s/g, "") !== "" &&
                            englishFull.test(value.replace(/\s/g, ""))
                          ) {
                            setPolicyNumberPlate(value);
                            setPolicyNumberPlateError("");
                          } else {
                            setPolicyNumberPlate("");
                            setPolicyNumberPlateError(
                              "Must contain English Letter(s) and/or Number(s)"
                            );
                          }
                        }}
                        //   disabled={isVerifyBtnDisable}
                        error={policyNumberPlateError}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 mg-top">
                    <Select
                      name="state"
                      label="State:"
                      options={stateData}
                      onChange={(e) => setPolicySelectState(e.target.value)}
                      disableSelect={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mg-top">
                    <div className="modal-text">
                      <Input
                        label="Policy Number:"
                        type="String"
                        name="policyNumber"
                        value={policyNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (
                            value.trim() !== "" ||
                            englishValidation.test(value.trim())
                          ) {
                            setPolicyFullNumber(value.toLocaleUpperCase());
                            setPolicyFullNumberError("");
                          } else {
                            setPolicyFullNumber("");
                            setPolicyFullNumberError(
                              "Must contain English Letter(s) and/or Number(s)"
                            );
                          }
                        }}
                        //   disabled={isVerifyBtnDisable}
                        error={policyNumberError}
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="modal-radio">
                      <div className="title">Insurance Type:</div>
                      <input
                        type="radio"
                        value={insuranceType === 0}
                        name="insuranceType"
                        checked={insuranceType === 0}
                        onChange={() => setInsuranceType(0)}
                      />
                      <label htmlFor="">Comprehensive Insurance</label>
                      <br />
                      <input
                        type="radio"
                        value={insuranceType === 1}
                        name="insuranceType"
                        checked={insuranceType === 1}
                        onChange={() => setInsuranceType(1)}
                      />
                      <label htmlFor="">Third Party Property Damage</label>
                      <br />
                      <input
                        type="radio"
                        value={insuranceType === 2}
                        name="insuranceType"
                        checked={insuranceType === 2}
                        onChange={() => setInsuranceType(2)}
                      />
                      <label htmlFor="">Other</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="modal-radio">
                      <div className="title">User:</div>
                      <input
                        type="radio"
                        value={userType === 0}
                        name="userType"
                        checked={userType === 0}
                        onChange={() => setUserType(0)}
                      />
                      <label htmlFor="">Business</label>
                      <br />
                      <input
                        type="radio"
                        value={userType === 1}
                        name="userType"
                        checked={userType === 1}
                        onChange={() => setUserType(1)}
                      />
                      <label htmlFor="">Private</label>
                      <br />
                      <input
                        type="radio"
                        value={userType === 2}
                        name="userType"
                        checked={userType === 2}
                        onChange={() => setUserType(2)}
                      />
                      <label htmlFor=""> Other</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group mg-top">
                      <label htmlFor="dateTime">Policy Expiry Date:</label>
                      <DatePicker
                        selected={policyExpiry}
                        onChange={(date) => setPolicyExpiry(date)}
                        dateFormat="dd-MM-yyyy"
                        className="form-control"
                      />
                      {policyExpiryError && (
                        <div className="alert alert-danger custom-error">
                          {policyExpiryError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="modal-radio">
                      <div className="title">Insurance Company:</div>
                      <input
                        type="radio"
                        value={insuranceCompany === 0}
                        name="insuranceCompany"
                        checked={insuranceCompany === 0}
                        onChange={() => setInsuranceCompany(0)}
                      />
                      <label htmlFor="">
                        {motor ? motor.ipc_insurer_company : ""}
                      </label>
                      <br />
                      <input
                        type="radio"
                        value={insuranceCompany === 1}
                        name="insuranceCompany"
                        checked={insuranceCompany === 1}
                        onChange={() => setInsuranceCompany(1)}
                      />
                      <label htmlFor="">other</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="group-btn-verify">
                    <button
                      onClick={() => handleVerifyPolicy()}
                      disabled={false}
                    >
                      Verify
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <textarea
                    className="textarea-info"
                    name="moreInfo"
                    id="moreInfo"
                    cols="30"
                    rows="10"
                    value={textareaValuePolicy}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTextareaValuePolicy(value);
                      if (value.replace(/\s/g, "").length > 1) {
                        setRejectBtnDisablePolicy(false);
                      } else {
                        setRejectBtnDisablePolicy(true);
                      }
                    }}
                  ></textarea>
                  <div className="group-btn-reject">
                    <button
                      onClick={() => handleReject(textareaValuePolicy)}
                      disabled={isRejectBtnDisablePolicy}
                    >
                      Reject
                    </button>
                    <button
                      onClick={() =>
                        handleMoreInformationRequest(textareaValuePolicy)
                      }
                      disabled={isRejectBtnDisablePolicy}
                      className="request"
                    >
                      Request User For More Information
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer"></Modal.Footer>
      </Modal>
    </div>
  );
}

export default MotorbikeStatusPopups;
