import React, { useEffect, useState } from "react";
import Input from "../../common/input";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as service from "../findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function OnboardingMotorCount({ rider, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [motorCount, setMotorCount] = useState(0);
  const [countBtnDisable, setCountBtnDisable] = useState(true);

  const handleExit = () => {
    setShow(false);
  };

  const handleDefault = () => {
    setMotorCount(rider.mb_deletes_during_onboarding);
    setCountBtnDisable(true);
  };

  const setCount = (value) => {
    if (value <= `${rider.mb_deletes_during_onboarding}` && value >= 0) {
      if (value === `${rider.mb_deletes_during_onboarding}`) {
        setCountBtnDisable(true);
        setMotorCount(value);
      } else {
        setMotorCount(value);
        setCountBtnDisable(false);
      }
    }
  };

  const handleChangeCount = async () => {
    handleClose(true);
    setLoading(true);
    try {
      const result = await service.adminOnboardingChangeMotorbikeDeletes(
        rider.rider_uid,
        motorCount
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="motorbike-count">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-4">
          <h5>Delete Onboarding Motorbike</h5>
        </div>
        <div className="col-md-4">
          <div className="value">
            {rider ? rider.mb_deletes_during_onboarding : 0}
          </div>
        </div>
        <div className="col-md-4">
          {rider && rider.mb_deletes_during_onboarding > 0 ? (
            <Button
              variant="primary"
              className="edit-btn"
              onClick={() => {
                handleShow();
                handleDefault();
              }}
            >
              Edit
            </Button>
          ) : null}
          <Modal show={show} className="modal-popup-custom" onHide={handleExit}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Onboarding Motorbike</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Input
                label="Motorbike Count:"
                type="number"
                name="motorCount"
                value={motorCount}
                onChange={(e) => setCount(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                disabled={countBtnDisable}
                onClick={handleChangeCount}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default OnboardingMotorCount;
