import config from "../config.json";

export function timestampToDateFull(timestamp) {
  if (timestamp !== "") {
    let date = new Intl.DateTimeFormat("en-au", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
    return date;
  } else {
    return "";
  }
}

export function timestampToDateYear(timestamp) {
  let date = new Intl.DateTimeFormat("en-au", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(timestamp);
  return date;
}
export function timestampToDateTime(timestamp) {
  let date = new Intl.DateTimeFormat("en-au", {
    hour: "2-digit",
    minute: "2-digit",
  }).format(timestamp);
  return date;
}

export function timestampTo(timestamp) {
  let date = new Intl.DateTimeFormat("en-au", {
    minute: "2-digit",
    second: "2-digit",
  }).format(timestamp);
  return date;
}

export function timestampToDateForTimeLine(timestamp) {
  let date = new Intl.DateTimeFormat("en-au", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(timestamp);
  return date;
}

export function timestampToDateYearNanoSecond(second, nanoSecond) {
  if (second !== "" && nanoSecond !== "") {
    const timestamp = second * 1000 + nanoSecond / 1000000;

    let date = new Intl.DateTimeFormat("en-au", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(timestamp);
    return date;
  } else {
    return "";
  }
}

export function timestampToDateYearAndTime(second, nanoSecond) {
  if (second !== "" && nanoSecond !== "") {
    const timestamp = second * 1000 + nanoSecond / 1000000;

    let date = new Intl.DateTimeFormat("en-au", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
    return date;
  } else {
    return "";
  }
}

export function timestampToDateYearFormat(second, nanoSecond) {
  if (second !== "" && nanoSecond !== "") {
    const timestamp = second * 1000 + nanoSecond / 1000000;

    let date = new Intl.DateTimeFormat("en-au", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(timestamp);
    return date;
  } else {
    return "";
  }
}
export function timestampToDateTimeFormat(second, nanoSecond) {
  if (second !== "" && nanoSecond !== "") {
    const timestamp = second * 1000 + nanoSecond / 1000000;

    let date = new Intl.DateTimeFormat("en-au", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timestamp);
    return date;
  } else {
    return "";
  }
}

export function timestampMiliSecondToDateYear(timestamp) {
  var d = new Date(parseInt(timestamp, 10));
  var ds = d.toString("MM/dd/yyyy HH:mm:ss");
  return ds;
}

export function abnValidator(myAbn) {
  let result = false;
  let ABN;
  try {
    ABN = myAbn;
  } catch (e) {
    ABN = "";
  }
  if (ABN.length === 11) {
    try {
      let control = new Array(11);
      control[0] = (Number(ABN[0], false) - 1) * 10;
      control[1] = Number(ABN[1], false);
      control[2] = Number(ABN[2], false) * 3;
      control[3] = Number(ABN[3], false) * 5;
      control[4] = Number(ABN[4], false) * 7;
      control[5] = Number(ABN[5], false) * 9;
      control[6] = Number(ABN[6], false) * 11;
      control[7] = Number(ABN[7], false) * 13;
      control[8] = Number(ABN[8], false) * 15;
      control[9] = Number(ABN[9], false) * 17;
      control[10] = Number(ABN[10], false) * 19;
      let total = 0;
      for (let i = 0; i < 11; i++) {
        total += control[i];
      }

      if (total % 89 === 0) result = true;
      else result = false;
    } catch {
      result = false;
    }
  } else result = false;
  return result;
}

export const log = (any) => {
  if (process.env.NODE_ENV === "development") console.log(any);
};

export const getMapIdBasedonEnvironment = () => {
  var environment = window.localStorage.getItem(config.environmentCode);
  switch (environment) {
    case "DEVELOPMENT":
      return "39e429275f5344f2";
    case "STAGING":
      return "f4e86786fbb6b3bb";
    case "PRODUCTION":
      return "683a7c74fe921546";
    default:
      break;
  }
};
