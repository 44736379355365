import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./components/login/login";
import NotFound from "./components/notFound";
import ConfirmMobile from "./components/login/confirmMobile";
import ConfirmCode from "./components/login/confirmCode";
import Home from "./components/home/home";
import { Route, Switch, Redirect } from "react-router-dom";
import * as service from "./remoteConfigService";
import config from "./config.json";
import { Modal, Button } from "react-bootstrap";
import Spinner from "./components/common/spinner";

function App() {
  const [isData, setIsData] = useState(false);
  const [isHaveValidTimeSesssion, setIsHaveValidTimeSesssion] = useState(false);
  const [bearerCloudfunctionsIsRunning, setBearerCloudfunctionsIsRunning] = useState(false);
  const [bearerAdminpanelIsRunning, setBearerAdminpanelIsRunning] = useState(false);
  const [isNetWork, setIsNetWork] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isUserLoggedInWithUserAndPass = Boolean(localStorage.getItem("isLoginSuccessfully"));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true)

    try {
      const remote = await service.getRemoteConfigData();
      if (remote) {
        setLoading(false)
        if (localStorage.getItem(config.bearerCloudfunctionsIsRunning).toLowerCase().includes("true")) {
          if (localStorage.getItem(config.bearerAdminpanelIsRunning).toLowerCase().includes("true")) {
            const lastLogin = (new Date(localStorage.getItem("startTime")).getTime());
            const deadLineMinute = localStorage.getItem(config.bearerAdminPanelWebSessionValidityMinutes)
            const now = new Date().getTime();
            const adminLeftTime = (new Date(localStorage.getItem("startTime")).getTime() + 1000 * 60 * Number(deadLineMinute));
            if (lastLogin === 0) {
              setIsHaveValidTimeSesssion(false)
              setIsData(true);
            } else {
              if (now < adminLeftTime) {
                setIsHaveValidTimeSesssion(true)
                setIsData(true);
              } else {
                setIsHaveValidTimeSesssion(false)
                setIsData(true);
              }
            }
          } else {
            setBearerAdminpanelIsRunning(true)
          }
        } else {
          setBearerCloudfunctionsIsRunning(true)
        }
      } else {
        setIsNetWork(true)
        setLoading(false)
      }
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };

  const handleRoute = () => {
    return (
      <div className="App">
        <Switch>
          <Route path="/login" component={Login} onEnter={getData} />
          <Route
            path="/confirmMobile"
            component={ConfirmMobile}
            onEnter={getData}
          />
          <Route
            path="/confirmCode"
            component={ConfirmCode}
            onEnter={getData}
          />
          <Route path="/notFound" component={NotFound} onEnter={getData} />
          <Route path="/home" component={Home} onEnter={getData} />
          <Route
            exact
            path="/"
            component={
              isHaveValidTimeSesssion && isUserLoggedInWithUserAndPass
                ? () => <Redirect to="/home/dashboard" />
                : () => <Redirect to="/login" />
            }
          />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  };


  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <>
      {renderSpinner()}
      {isData ? handleRoute() : null}
      <Modal show={bearerCloudfunctionsIsRunning} className="modal-popup-custom-app-start">
        <Modal.Body><p>{localStorage.getItem(config.bearerCloudfunctionsStatusMessageText)}</p></Modal.Body>
      </Modal>
      <Modal show={bearerAdminpanelIsRunning} className="modal-popup-custom-app-start">
        <Modal.Body>
          <p>{localStorage.getItem(config.bearerAdminpanelStatusMessageText)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              if (localStorage.getItem(config.bearerAdminpanelStatusMessageButtonAction) && localStorage.getItem(config.bearerAdminpanelStatusMessageButtonAction).toLowerCase().includes("url")) {
                window.open(localStorage.getItem(config.bearerAdminpanelActionUrl))
              } else {
                setBearerAdminpanelIsRunning(false)
                setIsNetWork(false)
                getData()
              }
            }}
          >
            {localStorage.getItem(config.bearerAdminpanelStatusMessageButtonAction) && localStorage.getItem(config.bearerAdminpanelStatusMessageButtonAction).toLowerCase().includes("url") ? "Learn More" : "Ok"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isNetWork} className="modal-popup-custom-app-start">
        <Modal.Body>
          <p>There is a problem with your network, please check your connection and try again!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setIsNetWork(false)
              getData()
            }}
          >
            Try!
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

export default App;
