import React, { useEffect, useState } from "react";
import "../findCustomer/findCustomer.scss";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as motorbikeService from "./findMotorbikeService";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Button } from "react-bootstrap";
import Input from "../common/input";
import Select from "../common/select";
import { log } from "../../helper/helper";

function FindMotorbike(props) {
  const [isSelected, setSelected] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueError, setInputValueError] = useState("");
  const [dataType, setDataType] = useState("");
  const [serachBtDisable, setSerachBtDisable] = useState(true);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
  });

  const searchType = [
    { id: "1", name: "ACT" },
    { id: "2", name: "NSW" },
    { id: "3", name: "NT" },
    { id: "4", name: "QLD" },
    { id: "5", name: "SA" },
    { id: "6", name: "TAS" },
    { id: "7", name: "VIC" },
    { id: "8", name: "WA" },
  ];

  const handleSearchType = (value) => {
    if (value === "") {
      setInputValue("");
      setDataType("");
      setSerachBtDisable(true);
      setSelected(false);
    } else {
      setInputValue("");
      setSerachBtDisable(true);
      setDataType(value);
      setSelected(true);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };
  const handleCustomerSearch = async () => {
    setLoading(true);
    try {
      const result = await motorbikeService.adminFindMotorbike(
        inputValue.toLocaleUpperCase(),
        dataType
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            props.history.push("/home/findMotorbike/motorbikeList", {
              motorList: result.data.motorbikeList,
            });
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="search">
              <h2>Find Motorbike</h2>
              <Select
                name="target"
                label="Registration State:"
                options={searchType}
                onChange={(e) => handleSearchType(e.target.value)}
                disabled={false}
                disabledDefaultValue={false}
                disableSelect={false}
              />
              {isSelected ? (
                <Input
                  label="Registration Number:"
                  type="String"
                  name="title"
                  value={inputValue}
                  error={inputValueError}
                  onChange={(e) => {
                    const value = e.target.value;
                    var english = /^[A-Za-z0-9]*$/;
                    setInputValue(e.target.value);
                    if (english.test(value) && value.length > 0) {
                      setSerachBtDisable(false);
                      setInputValueError();
                    } else {
                      setInputValueError(
                        "Must contain English Letter(s) and/or Number(s)"
                      );
                      setSerachBtDisable(true);
                    }
                  }}
                />
              ) : null}
              <Button
                className="notif-send"
                disabled={serachBtDisable}
                onClick={() => handleCustomerSearch()}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindMotorbike;
