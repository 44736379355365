import React, { useEffect } from "react";
import "./global.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";

function ModesOfTransports(props) {
  log(props.history.location);
  const auth = getAuth();
  const transportModes = props.history.location.state
    ? props.history.location.state.transportModes
    : "";

  useEffect(() => {
    if (!props.history.location.state) {
      props.history.replace("/home/dashboard");
    }
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
    return () => {};
  }, []);

  const walking = () => {
    log(transportModes.walking);
    if (transportModes.walking) {
      return (
        <div className=" status admin-info-value-enable">
          <span>Enable</span>
        </div>
      );
    } else {
      return (
        <div className=" status admin-info-value-disable">
          <span>Disable</span>
        </div>
      );
    }
  };

  const cycling = () => {
    log(transportModes.walking);
    if (transportModes.cycling) {
      return (
        <div className=" status admin-info-value-enable">
          <span>Enable</span>
        </div>
      );
    } else {
      return (
        <div className=" status admin-info-value-disable">
          <span>Disable</span>
        </div>
      );
    }
  };

  const statusCheck = (motorbike) => {
    switch (motorbike.motorbike_latest_status) {
      case "Processing":
        return (
          <div className="status-custom-modes processing">
            <span>Processing</span>
          </div>
        );

      case "Approved":
        if (motorbike.is_rego_date_still_valid) {
          if (motorbike.is_insurance_policy_date_still_valid) {
            return (
              <div className="status-custom-modes approved-valid">
                <span>Approved</span>
              </div>
            );
          } else {
            return (
              <div className="status-custom-modes approved-invalid">
                <span>Insurance Policy Expired</span>
              </div>
            );
          }
        } else {
          return (
            <div className="status-custom-modes  approved-invalid">
              <span className="">Registration Expired</span>
            </div>
          );
        }

      case "Policy Expired":
        return (
          <div className="status-custom-modes expired">
            <span>Policy Expired</span>
          </div>
        );
      case "Rego Expired":
        return (
          <div className="status-custom-modes expired">
            <span>Rego Expired</span>
          </div>
        );
      case "Further Investigation":
        return (
          <div className="status-custom-modes investigation">
            <span>Further Investigation</span>
          </div>
        );
      case "Restricted":
        return (
          <div className="status-custom-modes restricted">
            <span>Restricted</span>
          </div>
        );
      case "Rejected":
        return (
          <div className="status-custom-modes rejected">
            <span>Rejected</span>
          </div>
        );
      case "Removed":
        return (
          <div className="status-custom-modes removed">
            <span>Removed</span>
          </div>
        );
      default:
        break;
    }
  };

  const handleButtonControl = (motorbike) => {
    if (motorbike.motorbike_latest_status === "Processing") {
      if (motorbike.is_allow_show_edit_button_result) {
        return (
          <button
            className="single-view"
            onClick={() =>
              props.history.push("/home/modesOfTransport/motorbike", {
                motorbike: motorbike,
              })
            }
          >
            View
          </button>
        );
      } else {
        return null;
      }
    } else {
      return (
        <button
          className="single-view"
          onClick={() =>
            props.history.push("/home/modesOfTransport/motorbike", {
              motorbike: motorbike,
            })
          }
        >
          View
        </button>
      );
    }
  };

  return (
    <div className="modes-of-transport">
      <div className="headline">
        <h3>Modes of Transport</h3>
      </div>

      <div className="transport-modes">
        <div className="row">
          <div className="col-md-4">
            <div className="title">
              <span>
                <img src={require("../../assets/walking.png")} alt="walk" />
              </span>
              Walking
            </div>
          </div>
          <div className="col-md-4">{walking()}</div>
          <div className="col-md-4">
            {/* <Button variant="primary" className="edit-btn btn-blue">
              Edit
            </Button> */}
          </div>
        </div>
      </div>
      <div className="transport-modes">
        <div className="row">
          <div className="col-md-4">
            <div className="title">
              <span>
                <img
                  className="cycling"
                  src={require("../../assets/cycling.png")}
                  alt="walk"
                />
              </span>
              Cycling
            </div>
          </div>
          <div className="col-md-4">{cycling()}</div>
          <div className="col-md-4"></div>
        </div>
      </div>
      <div className="row">
        <div className="riding">
          <div className="col-md-12">
            <div className="title">
              <span>
                <img src={require("../../assets/riding.png")} alt="walk" />
              </span>
              Riding
            </div>
          </div>
          <div className="col-md-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Make</th>
                  <th>Model</th>
                  <th>State</th>
                  <th>Registration Nr</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {transportModes !== "" ? (
                  transportModes.motorbikeList.map((motorbike) => (
                    <tr key={motorbike.motorbike_uid}>
                      <td>{motorbike.make}</td>
                      <td>{motorbike.model}</td>
                      <td>{motorbike.registration_state}</td>
                      <td>{motorbike.registration_number}</td>
                      <td className="motobike-status-modes-transport">
                        {statusCheck(motorbike)}
                      </td>
                      <td className="custom-td">
                        {handleButtonControl(motorbike)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <div></div>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModesOfTransports;
