import React, { useState } from "react";
import historyImage from "../../../assets/history.png";
import { Modal } from "react-bootstrap";
import { timestampToDateFull, log } from "../../../helper/helper";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import * as customerService from "../../findCustomer/findCustomerService";
import { getAuth } from "firebase/auth";

function RiderSignStatus({ user }) {
  const [historyShow, setHistoryShow] = useState(false);
  const handleHistoryClose = () => setHistoryShow(false);
  const handleHistoryShow = () => setHistoryShow(true);
  const [isLoading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState([]);

  const handleSignupStatus = () => {
    if (user && user.is_sign_up_completed) {
      switch (user.sign_up_documents_review_result) {
        case "Approved":
          return (
            <span className="rider-signup-status signup-status-approved">
              Approved
            </span>
          );
        case "Rejected":
          return (
            <span className="rider-signup-status signup-status-rejected">
              Rejected
            </span>
          );
        case "Processing":
          return (
            <span className="rider-signup-status signup-status-processing">
              Processing
            </span>
          );
        case "Further Investigation":
          return (
            <span className="rider-signup-status signup-status-investigation">
              Further Investigation
            </span>
          );
        default:
          break;
      }
    } else {
      return (
        <span className="rider-signup-status signup-status-purple">false</span>
      );
    }
  };

  const handleAdminUserName = (uid) => {
    const filtered = adminList.filter((user) => user.admin_uid.includes(uid));
    if (filtered.length > 0) {
      const name = filtered[0].given_name + " " + filtered[0].last_name;
      return name;
    } else {
      return "Other Admin";
    }
  };

  const getAdminInfo = async () => {
    setLoading(true);
    try {
      const result = await customerService.getAdminInfo();
      log(result.data);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAdminList(result.data.adminsList);
            handleHistoryShow();
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="rider-sign-status">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-6">
          <div className="sign-title">User Status</div>
          <div className="sign-value">
            <ul>
              <li>
                {user && user.is_account_deleted ? (
                  <div className="deleted">
                    <span>Deleted</span>
                  </div>
                ) : (
                  <div className="active">
                    <span>Active</span>
                  </div>
                )}
              </li>
              <li>
                {user && user.documents_review_history ? (
                  <button onClick={() => getAdminInfo()}>
                    <img src={historyImage} alt="history" />
                  </button>
                ) : null}
              </li>
            </ul>
          </div>
          {user && user.documents_review_history ? (
            <Modal
              show={historyShow}
              className="note-history-modal"
              onHide={handleHistoryClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Histroy</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="table-design">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Time</th>
                            <th>Review result</th>
                            <th>Result note</th>
                            <th>Admin user</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(user.documents_review_history)
                            .sort(([a], [b]) => b - a)
                            .map(([key, value]) => (
                              <tr key={key}>
                                <td>{timestampToDateFull(key)}</td>
                                <td>{value.sign_up_documents_review_result}</td>
                                <td>
                                  {value.sign_up_documents_review_result_note}
                                </td>
                                <td className="admin-uid">
                                  {handleAdminUserName(
                                    value.approving_admin_user_uid
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          ) : null}
        </div>
        <div className="col-md-6">
          <div className="sign-title">Signup Status</div>
          <div className="sign-value">{handleSignupStatus()}</div>
        </div>
      </div>
    </div>
  );
}

export default RiderSignStatus;
