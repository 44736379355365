import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../motorbike.scss";
import * as riderServices from "../findMotorbikeService";
import { log } from "../../../helper/helper";

function ApproveMotorbikeStatus({ motor, props, handleReloadSingle }) {
  const [isLoading, setLoading] = useState(false);
  const [motorStatus, setMotorStatus] = useState(0);
  const [
    textareaValueRejectedMotorStatus,
    setTextareaValueRejectedMotorStatus,
  ] = useState("");
  const handleClose = () => {
    setSelected(!selected);
    setTextareaValueRejectedMotorStatus("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(false);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const inputHasChanged = () => {
    setSelected(!selected);
  };

  const handleMotorStatus = async () => {
    setLoading(true);
    try {
      const result = await riderServices.adminMotorbikeRestrict(
        motor.motorbike_uid,
        textareaValueRejectedMotorStatus
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            setSelected(!selected);
            setTextareaValueRejectedMotorStatus("");
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleSubmitButtonShow = () => {
    if (motorStatus === 0) {
      if (selected && textareaValueRejectedMotorStatus.length > 2) {
        return (
          <Button variant="primary" onClick={() => handleMotorStatus()}>
            Submit
          </Button>
        );
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {renderSpinner()}

      <Button variant="primary" className="edit-btn" onClick={handleShow}>
        Manage
      </Button>
      <Modal
        show={show}
        className="crm-modal modal-popup-motorbike-rejected-status"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage the Motorbike</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <div className="modal-radio">
                  <input
                    type="radio"
                    value={motorStatus === 0}
                    name="motorStatus"
                    checked={motorStatus === 0}
                    onChange={() => setMotorStatus(0)}
                  />
                  <label htmlFor="">Restrict the Motorbike</label>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <div className="toggleSwitch">
                  <div className="container">
                    <span className="switch-label">Confirm</span>
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selected}
                        name="allowedByBearer"
                        id="allowedByBearer"
                        onChange={() => inputHasChanged()}
                      />
                      <label className="label" htmlFor="allowedByBearer">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {selected ? (
                <Col>
                  <textarea
                    className="textarea-info approve-popup-textarea"
                    name="moreInfo"
                    id="moreInfo"
                    rows="10"
                    value={textareaValueRejectedMotorStatus}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTextareaValueRejectedMotorStatus(value);
                    }}
                  ></textarea>
                </Col>
              ) : null}
            </Row>
          </Container>
          {handleSubmitButtonShow()}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ApproveMotorbikeStatus;
