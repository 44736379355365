import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../findCustomer/findCustomer.scss";
import Form from "../common/form";
import { ToastContainer, toast } from "react-toastify";
import Joi from "joi-browser";
import Spinner from "../common/spinner";
import * as transportService from "./findTransportService";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";

class FindTransport extends Form {
  state = {
    data: { orderNumber: "" },
    errors: {},
    isLoading: false,
  };

  schema = {
    orderNumber: Joi.number().required().label("Search Value:"),
  };

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        this.props.history.replace("/login");
      }
    });
  }

  doSubmit = async () => {
    this.setState({ isLoading: true });
    const inputString = this.state.data.orderNumber;
    try {
      const result = await transportService.adminFindTransport(inputString);
      this.setState({ isLoading: false });
      log(result);
      if (result) {
        this.setState({ isLoading: false });
        switch (result.data.code) {
          case 200:
            this.props.history.push("/home/findTransport/findTransportSingle", {
              isFromFindTransport: true,
              // transport: result.data.transport,
              transportId: result.data.transport.transport_id,
              userType: "",
            });
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const isLoading = this.state.isLoading;
    const renderSpinner = () => {
      if (isLoading) {
        return <Spinner />;
      }
    };

    return (
      <div>
        <ToastContainer />
        {renderSpinner()}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="search">
                <h2>Find Transport</h2>
                <form onSubmit={this.handleSubmit}>
                  {this.renderInput(
                    "orderNumber",
                    "Order Number:",
                    "string",
                    "Order Number",
                    false
                  )}
                  {this.renderButton("Search")}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FindTransport;
