import React from "react";

function RateAverage({ user }) {
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <h5>Rate Average</h5>
        </div>
        <div className="col-md-4">
          <div className=" value total-rate">
            {user ? user.rating_average : ""}
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-2 col-xs-12"></div>
        <div className="col-md-2 col-sm-3 col-xs-12">
          <div className="rate">
            <div className="title">Safe Delivery</div>
            <div className="average-value-holder">
              <h6>{user ? user.rider_rated_by_customer_safe_delivery : ""}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-3 col-xs-12">
          <div className="rate">
            <div className="title">Politeness</div>
            <div className="average-value-holder">
              <h6>{user ? user.rider_rated_by_customer_politeness : ""}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-3 col-xs-12">
          <div className="rate">
            <div className="title">Being Ontime</div>
            <div className="average-value-holder">
              <h6>{user ? user.rider_rated_by_being_ontime : ""}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-2 col-xs-12"></div>
      </div>
    </div>
  );
}

export default RateAverage;
