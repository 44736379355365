import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as helper from "../../../helper/helper";
import * as service from "../findCustomerService";
import * as riderService from "../../findRider/findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";

function LatestActivity({ user, isCustomer, props, handleReloadSingle }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setLoading] = useState(false);

  const resetLatestActivity = async () => {
    setLoading(true);
    handleClose(true);

    if (isCustomer) {
      try {
        const result = await service.adminCustomerResetLatestActivity(
          user.customer_uid
        );
        helper.log(result);
        setLoading(false);
        if (result) {
          switch (result.data.code) {
            case 200:
              handleReloadSingle();
              toast.success(result.data.message);
              return;
            case 401:
              toast.error(result.data.message);
              return props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      try {
        const result = await riderService.adminRiderResetLatestActivity(
          user.rider_uid
        );
        helper.log(result);
        setLoading(false);
        if (result) {
          switch (result.data.code) {
            case 200:
              handleReloadSingle();
              toast.success(result.data.message);
              return;
            case 401:
              toast.error(result.data.message);
              return props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleLatestUserctivityTime = () => {
    return helper.timestampToDateFull(
      user ? user.latest_user_activity_time : ""
    );
  };

  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>Latest Activity</h5>
      </div>
      <div className="col-md-4">
        <div className="value">{handleLatestUserctivityTime()}</div>
      </div>
      <div className="col-md-4">
        <Button variant="primary" className="edit-btn" onClick={handleShow}>
          Reset
        </Button>
        <Modal
          show={show}
          className="latest-activity-modal"
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Latest Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You are about to change the latest activity</p>
            <h6>Are you sure?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={resetLatestActivity}>
              Yes, I am
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default LatestActivity;
