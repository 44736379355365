import React, { useEffect } from "react";
import "./dashboard.scss";
import "bootstrap/dist/css/bootstrap.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function Dashboard(props) {
  const riderCount = localStorage.getItem("riderCount");
  const customerCount = localStorage.getItem("customerCount");
  const businessCount = localStorage.getItem("businessCount");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
      } else {
        props.history.replace("/login");
      }
    });
  }, []);

  return (
    <div className="container dashboard">
      <div className="row">
        <div className="col-md-4">
          <div className="rider box-report">
            <span className="title">Rider</span>
            <span className="value">{riderCount}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="customer box-report ">
            <span className="title">Customer</span>
            <span className="value">{customerCount}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="business-customer box-report">
            <span className="title">Business</span>
            <span className="value">{businessCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
