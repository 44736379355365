import React, { useEffect, useState } from "react";
import Spinner from "../common/spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "./parcel.scss";
import Input from "../common/input";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import * as service from "./parcelServices.js";
import { log } from "../../helper/helper";

function ParcelSingle(props) {
  const state = props.history.location.state
    ? props.history.location.state
    : null;

  const [historyData, setHistoryData] = useState();

  const [isLoading, setLoading] = useState(false);
  const [parcelType, setParcelType] = useState(
    state && !state.newParcel ? state.parcel.parcel_type : ""
  );
  const [parcelTypeError, setParcelTypeError] = useState("");

  const [parcelDescription, setParcelDescription] = useState(
    state && !state.newParcel ? state.parcel.parcel_description : ""
  );

  const [parcelDescriptionError, setParcelDescriptionError] = useState("");

  const [parcelNote, setParcelNote] = useState(
    state && !state.newParcel && state.parcel.change_note !== undefined
      ? state.parcel.change_note
      : ""
  );

  const [parcelNoteError, setParcelNoteError] = useState("");

  const [parcelMaxW, setParcelMaxW] = useState(
    state && !state.newParcel ? state.parcel.parcel_max_weight : 0
  );
  const [parcelMaxWError, setParcelMaxWError] = useState("");

  const [parcelMinW, setParcelMinW] = useState(
    state && !state.newParcel ? state.parcel.parcel_min_weight : 0
  );
  const [parcelMinWError, setParcelMinWError] = useState("");

  const [listIndexNumber, setListIndexNumber] = useState(
    state && !state.newParcel ? state.parcel.list_index_on_the_apps : 0
  );

  const [listIndexError, setListIndexError] = useState("");

  const [cycling, setCycling] = useState(
    state && !state.newParcel && state.parcel.vehicle_type.cycling ? 1 : 0
  );
  const [walking, setWalking] = useState(
    state && !state.newParcel && state.parcel.vehicle_type.walking ? 1 : 0
  );
  const [riding, setRiding] = useState(
    state && !state.newParcel && state.parcel.vehicle_type.riding ? 1 : 0
  );

  const [isShowInOrder, setIsShowInOrder] = useState(
    state && !state.newParcel && state.parcel.is_shown_for_orders ? 1 : 0
  );

  const parcelImgUrl =
    state && !state.newParcel ? state.parcel.parcel_img_url : "";
  const parcelID = state && !state.newParcel ? state.parcel.parcel_id : "";

  const storage = getStorage();
  const starsRef = ref(storage, parcelImgUrl);
  const [parcelImgUrlDownload, setParcelImgUrlDownload] = useState("");
  const [historyChangeClassName, setHistoryChangeClassName] = useState("");
  const [isHistory, setHistory] = useState(false);

  let parcelTypeImage;

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });

    if (state === null) {
      return props.history.replace("/home");
    }

    if (parcelImgUrl !== "") {
      getDownloadURL(starsRef)
        .then((url) => {
          setParcelImgUrlDownload(url);
        })
        .catch((error) => {
          log(error);
        });
    }

    if (parcelTypeImage) {
      log("File has been set.");
    }
  }, [parcelTypeImage]);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleAddParcel = async () => {
    setParcelTypeError("");
    setParcelMaxWError("");
    setParcelMinWError("");
    setParcelDescriptionError("");
    setListIndexError("");
    setParcelNoteError("");

    if (parcelType.replace(/\s/g, "").length < 2) {
      return setParcelTypeError("Minimum 1 character");
    }

    if (parcelMaxW === "" || parcelMaxW < 0) {
      return setParcelMaxWError("Only Numbers are accepted");
    }
    if (parcelMinW === "" || parcelMinW < 0) {
      return setParcelMinWError("Only Numbers are accepted");
    }
    if (parcelDescription.replace(/\s/g, "").length < 2) {
      return setParcelDescriptionError("Minimum 1 character");
    }

    if (listIndexNumber === "" || listIndexNumber < 0) {
      return setListIndexError("Only Numbers are accepted");
    }

    if (parcelNote.replace(/\s/g, "").length < 2) {
      return setParcelNoteError("Minimum 1 character");
    }

    if (state.newParcel) {
      if (parcelTypeImage === undefined) {
        return toast.error("Please choose an image");
      }
    }

    setLoading(true);
    try {
      const result = await service.adminParcel(
        parcelID,
        parcelImgUrl,
        parcelDescription,
        parcelNote,
        parcelMaxW,
        parcelMinW,
        parcelType,
        listIndexNumber,
        Boolean(walking),
        Boolean(cycling),
        Boolean(riding),
        Boolean(isShowInOrder)
      );

      if (result) {
        switch (result.data.code) {
          case 200:
            log(result.data);
            const imageUrl = result.data.parcel_img_url
              ? result.data.parcel_img_url
              : parcelImgUrl;
            if (parcelTypeImage !== undefined) {
              log("axi");
              const imageRef = ref(storage, imageUrl);
              const metadata = {
                contentType: "image/png",
              };
              uploadBytes(imageRef, parcelTypeImage, metadata).then(() => {
                props.history.goBack();
              });
            } else {
              props.history.goBack();
            }

            return;
          case 401:
            setLoading(false);
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            setLoading(false);
            return toast.error(result.data.message);
          default:
            setLoading(false);
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      log(error);
      toast.error(error.message);
    }
  };

  // const handleHistoryChange = (value) => {
  //   console.log(value);
  //   if (value !== "") {
  //     const dataValue = state.parcel.parcel_type_history;
  //     setHistoryData(dataValue[value]);
  //     setParcelType(dataValue[value].parcel_type);
  //     setParcelMaxW(dataValue[value].parcel_max_weight);
  //     setParcelMinW(dataValue[value].parcel_min_weight);
  //     setParcelDescription(dataValue[value].parcel_description);
  //     setCycling(dataValue[value].vehicle_type.cycling ? 0 : 1);
  //     setWalking(dataValue[value].vehicle_type.walking ? 0 : 1);
  //     setRiding(dataValue[value].vehicle_type.riding ? 0 : 1);
  //     setHistoryChangeClassName("history-change-bg");
  //     setHistory(true);
  //   } else {
  //     setParcelType(state.parcel.parcel_type);
  //     setParcelMaxW(state.parcel.parcel_max_weight);
  //     setParcelMinW(state.parcel.parcel_min_weight);
  //     setParcelDescription(state.parcel.parcel_description);
  //     setCycling(state.parcel.vehicle_type.cycling ? 0 : 1);
  //     setWalking(state.parcel.vehicle_type.walking ? 0 : 1);
  //     setRiding(state.parcel.vehicle_type.riding ? 0 : 1);
  //     setHistoryChangeClassName("");
  //     console.log(isHistory);
  //   }
  // };

  return (
    <div className="parcel-single">
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {/* {historyData === null ? (
              <div className="form-group">
                <select
                  name="history"
                  id="history"
                  className="form-control form-select"
                  onChange={(e) => handleHistoryChange(e.target.value)}
                >
                  <option value="" disabled={false}>
                    History
                  </option>
                     {Object.entries(state.parcel.parcel_type_history)
                .sort(([a], [b]) => b - a)
                .map(([key, value]) => (
                    <option key={key} value={key}>
                      {timestampToDateFull(key)}
                    </option>
                  ))}
                </select>
              </div>
            ) : null} */}
          </div>
          <div className="col-md-4">
            <div className="header">
              <div className="headline">
                <h3>Parcel</h3>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4">
            <Input
              label="Parcel Type:"
              type="String"
              name="parcelType"
              value={parcelType}
              className={
                isHistory && historyData.parcel_type_was_changed
                  ? historyChangeClassName
                  : ""
              }
              onChange={(e) => {
                const value = e.target.value;
                setParcelType(value);
                if (value.replace(/\s/g, "").length > 1) {
                  setParcelTypeError("");
                } else {
                  setParcelTypeError("Minimum 1 character");
                }
              }}
              error={parcelTypeError}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Parcel Max Weight:"
              type="Number"
              name="parcelMaxW"
              value={parcelMaxW}
              className={
                isHistory && historyData.parcel_max_weight_was_changed
                  ? historyChangeClassName
                  : ""
              }
              onChange={(e) => setParcelMaxW(e.target.value)}
              error={parcelMaxWError}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Parcel Min Weight:"
              type="Number"
              name="parcelMinW"
              value={parcelMinW}
              className={
                isHistory && historyData.parcel_min_weight_was_changed
                  ? historyChangeClassName
                  : ""
              }
              onChange={(e) => setParcelMinW(e.target.value)}
              error={parcelMinWError}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Parcel Description:"
              type="String"
              name="parcelDescription"
              className={
                isHistory && historyData.parcel_description_was_changed
                  ? historyChangeClassName
                  : "somethingWrong"
              }
              value={parcelDescription}
              onChange={(e) => setParcelDescription(e.target.value)}
              error={parcelDescriptionError}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Index number:"
              type="Number"
              name="listIndexNumber"
              value={listIndexNumber}
              onChange={(e) => setListIndexNumber(e.target.value)}
              error={listIndexError}
            />
          </div>
          <div className="col-md-12">
            <div className="radio-selection">
              <div className="radio-input is-shown-for-order">
                <span className="title-span">Is shown for orders:</span>
                <input
                  className="radio"
                  type="radio"
                  value={isShowInOrder === 1}
                  name="isShowInOrder"
                  checked={isShowInOrder === 1}
                  onChange={() => setIsShowInOrder(1)}
                />
                <label htmlFor="">True</label>
                <input
                  className="radio"
                  type="radio"
                  value={isShowInOrder === 0}
                  name="isShowInOrder"
                  checked={isShowInOrder === 0}
                  onChange={() => setIsShowInOrder(0)}
                />
                <label htmlFor="">False</label>
              </div>
            </div>
          </div>
        </div>
        <div className="radio-selection">
          <div className="row">
            <div className="col-md-4">
              <div className="title">
                <img src={require("../../assets/cycling.png")} alt="cycling" />
                Cycling:
              </div>
              <div
                className={
                  isHistory && historyData.vehicle_type.cycling_was_changed
                    ? `radio-input ${historyChangeClassName}`
                    : "radio-input"
                }
              >
                <input
                  className="radio"
                  type="radio"
                  value={cycling === 1}
                  name="cycling"
                  checked={cycling === 1}
                  onChange={() => setCycling(1)}
                />
                <label htmlFor="">True</label>
                <br />
                <input
                  className="radio"
                  type="radio"
                  value={cycling === 0}
                  name="cycling"
                  checked={cycling === 0}
                  onChange={() => setCycling(0)}
                />
                <label htmlFor="">False</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="title">
                {" "}
                <img src={require("../../assets/walking.png")} alt="cycling" />
                Walking:
              </div>
              <div
                className={
                  isHistory && historyData.vehicle_type.walking_was_changed
                    ? `radio-input ${historyChangeClassName}`
                    : "radio-input"
                }
              >
                <input
                  className="radio"
                  type="radio"
                  value={walking === 1}
                  name="walking"
                  checked={walking === 1}
                  onChange={() => setWalking(1)}
                />
                <label htmlFor="">True</label>
                <br />
                <input
                  className="radio"
                  type="radio"
                  value={walking === 0}
                  name="walking"
                  checked={walking === 0}
                  onChange={() => setWalking(0)}
                />
                <label htmlFor="">False</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="title">
                {" "}
                <img src={require("../../assets/riding.png")} alt="riding" />
                Riding:
              </div>
              <div
                className={
                  isHistory && historyData.vehicle_type.riding_was_changed
                    ? `radio-input ${historyChangeClassName}`
                    : "radio-input"
                }
              >
                <input
                  className="radio"
                  type="radio"
                  value={riding === 1}
                  name="riding"
                  checked={riding === 1}
                  onChange={() => setRiding(1)}
                />
                <label htmlFor="">True</label>
                <br />
                <input
                  type="radio"
                  className="radio"
                  value={riding === 0}
                  name="riding"
                  checked={riding === 0}
                  onChange={() => setRiding(0)}
                />
                <label htmlFor="">False</label>
              </div>
            </div>
          </div>
        </div>
        <div className="radio-selection">
          <div className="row">
            <div className="col-md-10">
              <Input
                accept="image/png"
                label="Parcel Type Image:"
                type="file"
                name="parcelType"
                value={parcelTypeImage}
                onChange={(e) => (parcelTypeImage = e.target.files[0])}
              />
            </div>
            <div className="col-md-2">
              {parcelImgUrlDownload !== "" ? (
                <img
                  className="parcel-image"
                  src={parcelImgUrlDownload}
                  alt="parcel"
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <Input
            label="Change note:"
            type="String"
            name="parcelNote"
            value={parcelNote}
            onChange={(e) => setParcelNote(e.target.value)}
            error={parcelNoteError}
          />
        </div>

        <button className="save-parcel" onClick={() => handleAddParcel()}>
          Save
        </button>
      </div>
    </div>
  );
}

export default ParcelSingle;
