import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import "../findRider/findRider.scss";
import * as service from "./allRiderServices";
import * as riderService from "../findRider/findRiderService";
import Select from "../common/select";
import Input from "../common/input";
import { log } from "../../helper/helper";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

let target = [
  { id: "1", name: "Name" },
  { id: "2", name: "Email" },
  { id: "3", name: "Phone" },
];

function AllRiders(props) {
  const [riders, setRider] = useState([]);
  const [ridersFilterList, setRiderFilterList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [targetSelected, setTargetSelected] = useState("");
  const [filter, setFilter] = useState("");
  const [reason, setReason] = useState("");
  const [allowedBtnDisable, setAllowedBtnDisable] = useState(true);
  const [isCheck, setCheck] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (isAccess) => {
    setShow(true);
    setCheck(isAccess);
  };

  const getSinglePage = (rider) => {
    props.history.push("/home/allRiders/avatar", {
      rider: rider,
    });
  };

  const inputHasChanged = (isAlowed) => {
    setAllowedBtnDisable(true);
    setReason("");

    if (isCheck === isAlowed) {
      if (!isCheck) {
        setAllowedBtnDisable(false);
      }
    }
    setCheck(!isCheck);
  };

  const handleTextAreaAllowedBearerChange = (value) => {
    if (value.length > 0) {
      setReason(value);
      setAllowedBtnDisable(false);
    } else {
      setReason("");
      setAllowedBtnDisable(true);
    }
  };

  const getAllRiders = async () => {
    try {
      const result = await service.adminRiderInbox();
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setRider(result.data.rider);
            setRiderFilterList(result.data.rider);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }

      getAllRiders();
    });
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleAllowedOnboarding = async (uid, checkStatus, reasonText) => {
    setLoading(true);
    setShow(false);
    try {
      const result = await riderService.adminOnboardingChangeAllow(
        uid,
        checkStatus,
        reasonText
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setReason("");
            getAllRiders();
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleFilterData = (value) => {
    setFilter(value);
    if (value !== "") {
      switch (targetSelected) {
        case "Name":
          const newList = riders.filter(
            (li) => (li.given_name + " " + li.last_name).indexOf(value) !== -1
          );
          setRiderFilterList(newList);
          break;
        case "Email":
          const emailList = riders.filter((li) =>
            li.email_address.includes(value)
          );
          setRiderFilterList(emailList);
          break;
        case "Phone":
          const phoneList = riders.filter(
            (li) => li.mobile_number.indexOf(value) !== -1
          );
          setRiderFilterList(phoneList);
          break;

        default:
          break;
      }
    } else {
      setRiderFilterList(riders);
    }
  };

  return (
    <div className="search-list">
      <ToastContainer />
      {renderSpinner()}
      <h6>
        Found {riders.length} {riders.length > 1 ? "result" : "results"}
      </h6>
      <div className="filter-section">
        <div className="row">
          <div className="col-md-6">
            <Select
              name="target"
              options={target}
              onChange={(e) => {
                setTargetSelected(e.target.value);
                setFilter("");
                setRiderFilterList(riders);
              }}
              disabled={false}
              defaultValue="Select your Option"
              disabledDefaultValue={false}
              disableSelect={false}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeHolder="filter list"
              type="String"
              name="message"
              value={filter}
              onChange={(e) => handleFilterData(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>NCC</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ridersFilterList.map((rider) => (
            <tr key={rider.rider_uid}>
              <td>{rider.given_name + " " + rider.last_name}</td>
              <td>{rider.email_address}</td>
              <td>{rider.mobile_number}</td>
              <td>
                {rider.ncc && rider.ncc.latest_result ? (
                  <span
                    className={
                      rider.ncc.latest_result === "NDCO"
                        ? "rider-ncc-green"
                        : "rider-ncc-red"
                    }
                  >
                    {rider.ncc.latest_result}
                  </span>
                ) : null}
              </td>
              <td>
                {rider.is_allow_show_edit_button_result ? (
                  <button
                    className="search-view"
                    onClick={() => getSinglePage(rider)}
                  >
                    Review
                  </button>
                ) : null}
                <button
                  className="search-view"
                  onClick={() =>
                    handleShow(rider.user_access_is_alowed_to_onboarding)
                  }
                >
                  {rider.user_access_is_alowed_to_onboarding
                    ? "Disable Onboarding Access"
                    : "Enable Onboarding Access "}
                </button>
                <Modal
                  show={show}
                  className="allowed-by-user"
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {rider.user_access_is_alowed_to_onboarding
                        ? "Disable Onboarding Access"
                        : "Enable Onboarding Access "}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="toggleSwitch">
                      <div className="container">
                        <span className="switch-label">Allowed by Bearer:</span>
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={isCheck}
                            name="allowedByBearer"
                            id="allowedByBearer"
                            onChange={() =>
                              inputHasChanged(
                                rider.user_access_is_alowed_to_onboarding
                              )
                            }
                          />
                          <label className="label" htmlFor="allowedByBearer">
                            <span className="inner" />
                            <span className="switch" />
                          </label>
                        </div>
                      </div>
                    </div>
                    {!isCheck ? (
                      <textarea
                        value={reason}
                        onChange={(e) =>
                          handleTextAreaAllowedBearerChange(e.target.value)
                        }
                        className="allowed-by-user-textArea"
                      />
                    ) : null}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      disabled={allowedBtnDisable}
                      onClick={() =>
                        handleAllowedOnboarding(
                          rider.rider_uid,
                          isCheck,
                          reason
                        )
                      }
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllRiders;
