import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../findCustomer/findCustomer.scss";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import * as riderService from "./findRiderService";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Input from "../common/input";
import Select from "../common/select";
import { abnValidator, log } from "../../helper/helper";

function FindRider(props) {
  const [isLoading, setLoading] = useState(false);
  const [isSelected, setSelected] = useState(false);
  const [inputLabel, setInputLabel] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [dataType, setDataType] = useState("");
  const [inputValueError, setInputValueError] = useState("");
  const [serachBtDisable, setSerachBtDisable] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
  });

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const searchType = [
    { id: "1", name: "Phone Number" },
    { id: "2", name: "Email" },
    { id: "3", name: "Legal Name" },
    { id: "4", name: "ABN" },
  ];

  const handleSearchType = (value) => {
    if (value !== "") {
      setSelected(true);
      setInputLabel(value);
      setInputValue("");
      setInputValueError("");
      setSerachBtDisable(true);
    } else {
      setSelected(false);
    }
  };

  const handleInputData = (value) => {
    switch (inputLabel) {
      case "Phone Number":
        setDataType("phone_number");
        setInputValueError("");
        if (value.length < 13) {
          const result = value.replace(/\D/g, "");
          if (value.length === 1) {
            if (value === "0") {
              setInputValue("+61");
            } else if (value === "+") {
              setInputValue("+61");
            } else {
              setInputValue(`+61${result}`);
            }
          } else if (result.length < 12) {
            setInputValue(`+${result}`);
          }

          if (result.length === 11) {
            setSerachBtDisable(false);
            setInputValueError("");
          } else {
            setInputValueError("12 Digits (Beginning with a +61)");
            setSerachBtDisable(true);
          }
        }
        return;
      case "Email":
        setInputValue(value);
        setDataType("email");
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!regex.test(value)) {
          setInputValueError("Email address is not valid!");
          setSerachBtDisable(true);
        } else {
          setInputValueError("");
          setSerachBtDisable(false);
        }
        return;
      case "Legal Name":
        setInputValue(value);
        setDataType("legal_name");
        var english = /^[A-Za-z]*$/;

        if (
          value.replace(/\s/g, "").length > 1 &&
          english.test(value.replace(/\s/g, ""))
        ) {
          setInputValueError("");
          setSerachBtDisable(false);
        } else {
          setInputValueError("Minimum 2 letters (English Only)");
          setSerachBtDisable(true);
        }
        return;
      case "ABN":
        setInputValue(value);
        setDataType("abn");
        if (abnValidator(value)) {
          setInputValueError("");
          setSerachBtDisable(false);
        } else {
          setInputValueError("ABN must be a 11 digit number");
          setSerachBtDisable(true);
        }
        return;
      default:
        break;
    }
  };

  const handleRiderSearch = async () => {
    setLoading(true);
    try {
      const result = await riderService.adminFindRider(dataType, inputValue);
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            if (result.data.rider.length > 0) {
              return props.history.push("/home/findRider/riderList", {
                riders: result.data.rider,
                dataType: dataType,
                inputString: inputValue,
              });
            } else {
              return toast.error("No Rider Found");
            }
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="search">
              <h2>Find Rider</h2>
              <Select
                name="target"
                label="Select Search Type"
                options={searchType}
                onChange={(e) => handleSearchType(e.target.value)}
                disabled={false}
                disabledDefaultValue={false}
                disableSelect={false}
              />
              {isSelected ? (
                <Input
                  label={inputLabel}
                  type="String"
                  name="title"
                  value={inputValue}
                  onChange={(e) => handleInputData(e.target.value)}
                  error={inputValueError}
                />
              ) : null}
              <Button
                className="notif-send"
                disabled={serachBtDisable}
                onClick={() => handleRiderSearch()}
              >
                Search
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindRider;
