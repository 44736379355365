import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";


export function adminShowAllAssignmentTransports(cursorTransportId) {
    let data = {};
    if (cursorTransportId !== "") {
      data = {
        cursor_transport_id: cursorTransportId,
      };
    }
    log(data);
    const functions = getFunctions();
    const allTransports = httpsCallable(functions, config.adminShowAllLockedForAssignmentTransports);
    return allTransports(data);
  }