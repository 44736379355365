import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import profileImage from "../../assets/profile.png";
import { log } from "../../helper/helper";
import { getAuth, signOut } from "firebase/auth";
function NavBar({ props }) {
  const name = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const avatar = localStorage.getItem("avatar");
  const [imageUrl, setImageUrl] = useState("");

  const storage = getStorage();
  const starsRef = ref(storage, avatar);
  useEffect(() => {
    setTimeout(() => {}, 3000);
    log(avatar);
    if (avatar !== null && getAuth() !== null) {
      getDownloadURL(starsRef)
        .then((url) => {
          setImageUrl(url);
        })
        .catch((error) => {
          log(error);
        });
    } else {
      const auth = getAuth();
      signOut(auth).then(() => {
        return props.history.replace("/login");
      });
    }

    if (name === null && lastName === null) {
      const auth = getAuth();
      signOut(auth).then(() => {
        return props.history.replace("/login");
      });
    }
  }, []);

  return (
    <div className="navbar">
      <div className="userInfo">
        <figure>
          <img src={imageUrl !== "" ? imageUrl : profileImage} alt="avatar" />
        </figure>
        <figcaption>
          <div className="userName">{name + " " + lastName}</div>
          <div className="userLevel">Admin</div>
        </figcaption>
      </div>
      <div className="line"></div>
      <ul>
        <li>
          <NavLink
            to="/home/dashboard"
            activeClassName="active"
            className="dashboardIcon"
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/findCustomer"
            activeClassName="active"
            className="customerIcon"
          >
            Customer
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/allCustomer"
            activeClassName="active"
            className="allCustomer"
          >
            Customers' List
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/findRider"
            activeClassName="active"
            className="riderIcon"
          >
            Bearers
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/allRiders"
            activeClassName="active"
            className="verify-bearers"
          >
            Verify Bearers
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/allNewRiders"
            activeClassName="active"
            className="bearer-list"
          >
            Bearers' List
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/onlineBearers"
            activeClassName="active"
            className="onlineBearerIcon"
          >
            Online Bearers
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/findTransport"
            activeClassName="active"
            className="transportIcon"
          >
            Transport
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/assignmentTransports"
            activeClassName="active"
            className="incomplete-assignments"
          >
            Incomplete Assignments
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/findMotorbike"
            activeClassName="active"
            className="motorbikeIcon"
          >
            Motorbike
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/motorbikeLists"
            activeClassName="active"
            className="motorbike-list-icon"
          >
            Motorbike List
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/couponList"
            className="couponsIcon"
            activeClassName="active"
          >
            Coupons
          </NavLink>
        </li>
        {/* <li>
          <Link to="/" className="remoteConfigIcon">
            Remote Config
          </Link>
        </li> */}
        <li>
          <NavLink
            to="/home/pricingObject"
            activeClassName="active"
            className="priceIcon"
          >
            Pricing Object
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/parcelList"
            className="parcelIcon"
            activeClassName="active"
          >
            Parcel
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/notification"
            activeClassName="active"
            className="pushIcon"
          >
            Push Notification
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/businessCustomer"
            activeClassName="active"
            className="businessCustomerIcon"
          >
            Business Customer
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/sendSMS"
            activeClassName="active"
            className="sendSMSIcon"
          >
            Send SMS
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/sendEmail"
            activeClassName="active"
            className="sendEmailIcon"
          >
            Send Email
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/home/webEnquiries"
            activeClassName="active"
            className="webEnquiries"
          >
            Web Enquiries
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
