import React from "react";

function TransportRate({ transport }) {
  const ontime = transport
    ? transport.rider_rated_by_customer.being_ontime
    : "";
  const politeness = transport
    ? transport.rider_rated_by_customer.politeness
    : "";
  const safeDelivery = transport
    ? transport.rider_rated_by_customer.safe_delivery
    : "";
  return (
    <div className="transport-wrapper rate">
      <div className="row">
        <div className="col-md-12">
          <h5>Rate Average</h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-2 col-xs-12"></div>
        <div className="col-md-2 col-sm-3 col-xs-12">
          <div className="rate">
            <div className="title">Safe Delivery</div>
            <div className="average-value-holder">
              <h6>{safeDelivery}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-3 col-xs-12">
          <div className="rate">
            <div className="title">Politeness</div>
            <div className="average-value-holder">
              <h6>{politeness}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-sm-3 col-xs-12">
          <div className="rate">
            <div className="title">Being Ontime</div>
            <div className="average-value-holder">
              <h6>{ontime}</h6>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-2 col-xs-12"></div>
      </div>
    </div>
  );
}

export default TransportRate;
