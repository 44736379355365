import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as customerService from "../findCustomerService";
import * as riderService from "../../findRider/findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function AllowedByBearer({ user, props, handleReloadSingle }) {
  const [isLoading, setLoading] = useState(false);
  const [isCheck, setCheck] = useState(false);
  const [isReasonShow, setReasonShow] = useState(false);
  const userType = user && user.user_type ? "customer" : "rider";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [reason, setReason] = useState("");
  const [allowedBtnDisable, setAllowedBtnDisable] = useState(true);

  const inputHasChanged = () => {
    setAllowedBtnDisable(true);
    setReason("");
    log(!isCheck);
    if (!isCheck === user.is_user_allowed_by_bearer) {
      if (!isCheck) {
        setReasonShow(false);
      } else {
        setReasonShow(true);
      }
    } else {
      if (!isCheck) {
        setReasonShow(false);
        setAllowedBtnDisable(false);
      } else {
        setReasonShow(true);
      }
    }
    setCheck(!isCheck);
  };

  const handleDefaultValue = () => {
    setCheck(user.is_user_allowed_by_bearer);
    setReason(
      user.not_allowed_reason !== undefined || user.not_allowed_reason !== null
        ? user.not_allowed_reason
        : ""
    );
    setReasonShow(user.is_user_allowed_by_bearer ? false : true);
  };

  const exitBtn = () => {
    setShow(false);
  };

  const handleTextAreaAllowedBearerChange = (value) => {
    setReason(value);
    if (value.replace(/\s/g, "").length > 1) {
      setAllowedBtnDisable(false);
    } else {
      setAllowedBtnDisable(true);
    }
  };

  const setAllowedByUserData = async () => {
    handleClose(true);
    setLoading(true);
    setAllowedBtnDisable(true);
    if (userType === "customer") {
      try {
        const result = await customerService.adminCustomerChangeAllow(
          user.customer_uid,
          isCheck,
          reason
        );
        log(result);
        setLoading(false);
        if (result) {
          switch (result.data.code) {
            case 200:
              handleReloadSingle();
              toast.success(result.data.message);
              return;
            case 401:
              toast.error(result.data.message);
              return props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    } else {
      try {
        const result = await riderService.adminRiderChangeAllow(
          user.rider_uid,
          isCheck,
          reason
        );
        log(result);
        setLoading(false);
        if (result) {
          switch (result.data.code) {
            case 200:
              handleReloadSingle();
              toast.success(result.data.message);
              return;
            case 401:
              toast.error(result.data.message);
              return props.history.replace("/login");
            case 500:
              return toast.error(result.data.message);
            default:
              return toast.error(result.data.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleEditBtn = () => {
    if (userType === "customer") {
      return (
        <Button
          variant="primary"
          className="edit-btn"
          onClick={() => {
            handleShow();
            handleDefaultValue();
          }}
        >
          Edit
        </Button>
      );
    } else {
      if (user && user.sign_up_documents_review_result === "Approved") {
        return (
          <Button
            variant="primary"
            className="edit-btn"
            onClick={() => {
              handleShow();
              handleDefaultValue();
            }}
          >
            Edit
          </Button>
        );
      }
    }
  };

  return (
    <div className="row">
      <ToastContainer />
      {renderSpinner()}
      <div className="col-md-4">
        <h5>Allowed By Bearer</h5>
      </div>
      <div className="col-md-4">
        <div
          className={
            user && user.is_user_allowed_by_bearer ? "active" : "deleted"
          }
        >
          <span>
            {user && user.is_user_allowed_by_bearer ? "true" : "false"}
          </span>
          <div className="reason">{user ? user.not_allowed_reason : ""}</div>
        </div>
      </div>
      <div className="col-md-4">
        {handleEditBtn()}
        <Modal show={show} className="allowed-by-user" onHide={exitBtn}>
          <Modal.Header closeButton>
            <Modal.Title>
              {user && user.is_sign_up_completed === false
                ? "User Access to Onboarding"
                : "Allowed By Bearer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="toggleSwitch">
              <div className="container">
                <span className="switch-label">Allowed by Bearer:</span>
                <div className="toggle-switch">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={isCheck}
                    name="allowedByBearer"
                    id="allowedByBearer"
                    onChange={() => inputHasChanged()}
                  />
                  <label className="label" htmlFor="allowedByBearer">
                    <span className="inner" />
                    <span className="switch" />
                  </label>
                </div>
              </div>
            </div>
            {isReasonShow ? (
              <textarea
                value={reason}
                onChange={(e) =>
                  handleTextAreaAllowedBearerChange(e.target.value)
                }
                className="allowed-by-user-textArea"
              />
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={allowedBtnDisable}
              onClick={setAllowedByUserData}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default AllowedByBearer;
