import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";

export function adminRegisterBusinessCustomer(
  name,
  email,
  givenName,
  lastName,
  mobile,
  birth,
  gender,
  abn,
  postCode
) {
  const data = {
    business_name: name,
    email_address: email,
    given_name: givenName,
    last_name: lastName,
    mobile_number: mobile,
    birth_date: birth,
    gender: gender,
    abn: abn,
    business_post_code: Number(postCode),
  };
  log(data);
  const functions = getFunctions();
  const adminRegisterBusiness = httpsCallable(
    functions,
    config.adminRegisterBusinessCustomer
  );
  return adminRegisterBusiness(data);
}
