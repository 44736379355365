import React from "react";

function Alart({
  message,
  handleAlartAction,
  actionTitle,
  isNoActive = false,
  handleCancelAction,
}) {
  return (
    <div className="global-alart">
      <div className="inside-global-alart">
        <p>{message}</p>
        <button className="submit" onClick={handleAlartAction}>
          {actionTitle}
        </button>
        {isNoActive ? <button onClick={handleCancelAction}>No</button> : null}
      </div>
    </div>
  );
}

export default Alart;
