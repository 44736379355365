import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import * as service from "./incompleteRiderListService";
import Select from "../common/select";
import { log } from "../../helper/helper";
import { Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Moment from "moment";
import "./incompleteRiderList.scss";
import { Badge } from "antd";
import { NotificationFilled } from "@ant-design/icons";
import Input from "../common/input";

let target = [
  { id: "0", name: "0: Retrieve All Applicants" },
  { id: "1", name: "1: Contact Details Provided" },
  { id: "2", name: "2: Personal Details Provided" },
  { id: "3", name: "3: Legal Details Provided" },
  { id: "4", name: "4: Modes of Transport Set" },
  { id: "5", name: "5: Stripe Onboarding Done" },
  { id: "6", name: "6: NCC Application Submitted" },
  { id: "7", name: "7: Application Signed" },
  { id: "8", name: "8: Sent to Document's Portal" },
  { id: "9", name: "9: Application Rejected" },
  { id: "10", name: "10: Result Disputed" },
  { id: "11", name: "11: New Documents Submitted" },
  { id: "12", name: "12: Bearer user is Approved" },
];

let filterTarget = [
  { id: "1", name: "Name" },
  { id: "2", name: "Engagement Scale" },
  { id: "3", name: "Email" },
  { id: "4", name: "Phone" },
  { id: "5", name: "Referral" },
];

const IncompleteRiderList = (props) => {
  const [riders, setRider] = useState([]);
  const [ridersFilterList, setRiderFilterList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [stageSelected, setStageSelected] = useState(
    window.localStorage.getItem("selectedItemId") !== null
      ? window.localStorage.getItem("selectedItemId")
      : 1
  );
  const [startData, setStartDate] = useState(
    window.localStorage.getItem("startData") !== null
      ? new Date(window.localStorage.getItem("startData"))
      : new Date()
  );
  const [endData, setEndDate] = useState(
    window.localStorage.getItem("endData") !== null
      ? new Date(window.localStorage.getItem("endData"))
      : new Date()
  );
  const [isFilterDate, setIsFilterDate] = useState(
    window.localStorage.getItem("isFilterDate") !== null
      ? window.localStorage.getItem("isFilterDate") === "true"
        ? true
        : false
      : false
  );
  const [defaultSelectedValue, setDefaultSelectedValue] = useState(
    window.localStorage.getItem("selectedItemName") !== null
      ? window.localStorage.getItem("selectedItemName")
      : "1: Contact Details Provided"
  );
  const [filterTargetSelected, setFilterTargetSelected] = useState("");
  const [isStageShow, setIsStageShow] = useState(false);

  const [filter, setFilter] = useState("");
  const currentdate = new Date();
  const maxdate = new Date(
    currentdate.setYear(currentdate.getFullYear() + 100)
  );

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
      getAllRiders();
    });
  }, []);

  const getAllRiders = async () => {
    if (stageSelected === "0" && !isFilterDate) {
      toast.error("Date range must be provided");
      return;
    }

    var end = Moment(endData);
    var start = Moment(startData);
    var diffDays = parseInt((end - start) / (1000 * 60 * 60 * 24), 10);
    if (diffDays > 90) {
      toast.error("Date range must be less than 90 days");
      return;
    }

    setLoading(true);
    try {
      const result = await service.getAdminOnboardingInbox(
        stageSelected,
        isFilterDate,
        isFilterDate ? Moment(startData).format("YYYY-MM-DD") : "",
        isFilterDate ? Moment(endData).format("YYYY-MM-DD") : ""
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setRider(result.data.rider);
            setRiderFilterList(result.data.rider);
            stageSelected === "0"
              ? setIsStageShow(true)
              : setIsStageShow(false);
            return;
          case 201:
            setRider([]);
            setRiderFilterList([]);
            return;
          case 401:
          case 410:
          case 499:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const getSinglePage = (rider) => {
    switch (rider.sign_up_stage) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        props.history.push("/home/findRider/riderList/uncompletedRider", {
          riderId: rider.rider_uid,
        });
        break;
      default:
        props.history.push("/home/findRider/riderList/riderSingle", {
          riderId: rider.rider_uid,
        });
        break;
    }
  };

  const handleNoteScale = (scaleNumber) => {
    switch (scaleNumber) {
      case 0:
        return (
          <div className="engagement-note no-interest">
            <span>0: No Interest</span>
          </div>
        );
      case 1:
        return (
          <div className="engagement-note slight-interest">
            <span>1: Slight Interest</span>
          </div>
        );
      case 2:
        return (
          <div className="engagement-note moderate-interest">
            <span>2: Moderate Interest</span>
          </div>
        );
      case 3:
        return (
          <div className="engagement-note interested">
            <span>3: Interested</span>
          </div>
        );
      case 4:
        return (
          <div className="engagement-note very-interested">
            <span>4: Very Interested</span>
          </div>
        );
      case 5:
        return (
          <div className="engagement-note extremely-interested">
            <span>5: Extremely Interested</span>
          </div>
        );
      default:
        break;
    }
  };

  const handleFilterData = (value) => {
    setFilter(value);
    if (value !== "") {
      switch (filterTargetSelected) {
        case "Name":
          const nameList = riders.filter(
            (li) =>
              (
                li.given_name.toLowerCase() +
                " " +
                li.last_name.toLowerCase()
              ).indexOf(value.toLowerCase()) !== -1
          );
          setRiderFilterList(nameList);
          break;
        case "Engagement Scale":
          const newList = riders.filter(
            (li) =>
              (
                li.note_user_engagement_scale +
                " " +
                li.note_user_engagement_scale
              ).indexOf(value) !== -1
          );
          setRiderFilterList(newList);
          break;
        case "Email":
          const emailList = riders.filter((li) =>
            li.email_address.includes(value)
          );
          setRiderFilterList(emailList);
          break;
        case "Phone":
          const phoneList = riders.filter(
            (li) => li.mobile_number.indexOf(value) !== -1
          );
          setRiderFilterList(phoneList);
          break;
        case "Referral":
          const referral = riders.filter((li) =>
            li.referred_by ? li.referred_by.indexOf(value) !== -1 : null
          );
          setRiderFilterList(referral);
          break;
        default:
          break;
      }
    } else {
      setRiderFilterList(riders);
    }
  };

  return (
    <div className="search-list incomplete-rider-ist">
      <ToastContainer />
      {renderSpinner()}
      <h6>
        Found {riders.length} {riders.length > 1 ? "result" : "results"}
      </h6>
      <hr />
      <div className="filter-section">
        <Row>
          <Col md="4" style={{ marginTop: 35, textAlign: "left" }}>
            Select Stage Number:
          </Col>
          <Col md="8">
            <Select
              style={{ height: 40 }}
              name="target"
              defaultValue="Select"
              value={defaultSelectedValue}
              options={target}
              onChange={(e) => {
                const item = target.filter((option) => {
                  return option.name === e.target.value;
                });
                window.localStorage.setItem("selectedItemId", item[0].id);
                window.localStorage.setItem("selectedItemName", item[0].name);
                setStageSelected(item[0].id);
                setDefaultSelectedValue(item[0].name);
              }}
              disabled={false}
              disableSelect={false}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <div
              className="filter-date"
              style={{ textAlign: "left", marginTop: 10 }}
            >
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={isFilterDate}
                  onChange={() => {
                    var filter = isFilterDate;
                    setIsFilterDate(!isFilterDate);
                    window.localStorage.setItem("isFilterDate", !filter);
                  }}
                  style={{ height: 30, marginBottom: 0, marginRight: 5 }}
                />
                Sign up Date Range
              </label>
            </div>
          </Col>
          <Col md="4">
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="dateTime"
                style={{ marginBottom: 20, marginRight: 5 }}
              >
                Start:
              </label>
              <DatePicker
                selected={startData}
                onChange={(date) => {
                  setStartDate(date);
                  window.localStorage.setItem("startData", date);
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                disabled={!isFilterDate}
                maxDate={maxdate}
              />
            </div>
          </Col>
          <Col md="4">
            <div
              className="form-group"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="dateTime"
                style={{ marginBottom: 20, marginRight: 5 }}
              >
                End:
              </label>
              <DatePicker
                selected={endData}
                onChange={(date) => {
                  setEndDate(date);
                  window.localStorage.setItem("endData", date);
                }}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                disabled={!isFilterDate}
                maxDate={maxdate}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="10"></Col>
          <Col md="2" style={{ textAlign: "right" }}>
            <button
              className="search-view"
              onClick={() => getAllRiders()}
              style={{ margin: "10px 0 20px" }}
            >
              Search
            </button>
          </Col>
        </Row>
      </div>
      <hr />
      <div className="filter-section">
        <div className="row">
          <div className="col-md-6">
            <Select
              name="target"
              options={filterTarget}
              onChange={(e) => {
                setFilterTargetSelected(e.target.value);
                setFilter("");
                setRiderFilterList(riders);
              }}
              disabled={false}
              defaultValue="Select your filter type"
              disabledDefaultValue={false}
              disableSelect={false}
            />
          </div>
          <div className="col-md-6">
            <Input
              placeHolder="Filter input"
              type="String"
              name="message"
              value={filter}
              onChange={(e) => handleFilterData(e.target.value)}
            />
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Engagement Scale</th>
            {isStageShow && <th>Stage</th>}
            <th>Referred by</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ridersFilterList.map((rider) => (
            <tr key={rider.rider_uid}>
              <td>{rider.given_name + " " + rider.last_name}</td>
              <td>{rider.email_address}</td>
              <td>{rider.mobile_number}</td>
              <td>{handleNoteScale(rider.note_user_engagement_scale)}</td>
              {isStageShow && (
                <td>
                  <span className="rider-stage">{rider.sign_up_stage}</span>
                </td>
              )}
              <td>{rider.referred_by && rider.referred_by}</td>
              <td>
                {!rider.admin_notes_history && (
                  <Badge
                    count={
                      <NotificationFilled
                        style={{ color: "##019eff", fontSize: 20 }}
                      />
                    }
                  />
                )}
                <button
                  className="search-view"
                  onClick={() => getSinglePage(rider)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IncompleteRiderList;
