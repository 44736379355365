import React, { useEffect, useState } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";
import { timestampToDateFull } from "../../helper/helper.jsx";
import HistorySingleComponents from "../parcel/parcelHistorySingleComponents";

function CouponHistorySingle(props) {
  const [time] = useState(
    props.location.state ? props.location.state.key : null
  );
  const [value] = useState(
    props.location.state ? props.location.state.value : null
  );

  log(value);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (value === null) {
      return props.history.replace("/home");
    }
  }, []);

  const handleHistoryValue = () => {
    if (value !== null) {
      const time =
        value.duration_validity._seconds * 1000 +
        value.duration_validity._nanoseconds * 1000000;

      return (
        <div>
          <HistorySingleComponents
            header="Admin UID"
            value={value.admin_uid}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Date"
            value={timestampToDateFull(time)}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Data model version"
            value={value.data_model_version}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Change note"
            value={value.change_note}
            isChanged={false}
          />
          <HistorySingleComponents
            header="Allowed user id"
            value={value.allowed_user_id}
            isChanged={value.allowed_user_id_was_changed}
          />
          <HistorySingleComponents
            header="Allowed user type"
            value={value.allowed_user_type}
            isChanged={value.allowed_user_type_was_changed}
          />
          <HistorySingleComponents
            header="Coupon type"
            value={value.coupon_type}
            isChanged={value.coupon_type_was_changed}
          />
          <HistorySingleComponents
            header="Description"
            value={value.description}
            isChanged={value.description_was_changed}
          />
          <HistorySingleComponents
            header="Duration"
            value={value.duration}
            isChanged={value.duration_was_changed}
          />
          <HistorySingleComponents
            header="Duration validity"
            value={timestampToDateFull(time)}
            isChanged={value.duration_validity_was_changed}
          />
          <HistorySingleComponents
            header="Marketing description admin"
            value={value.marketing_description_admin}
            isChanged={value.marketing_description_admin_was_changed}
          />
          <HistorySingleComponents
            header="max amount off"
            value={value.max_amount_off}
            isChanged={value.max_amount_off_was_changed}
          />
          <HistorySingleComponents
            header="Max overal redemptions"
            value={value.max_overal_redemptions}
            isChanged={value.max_overal_redemptions_was_changed}
          />
          <HistorySingleComponents
            header="Max redemptions per customer"
            value={value.max_redemptions_per_customer}
            isChanged={value.max_redemptions_per_customer_was_changed}
          />
          <HistorySingleComponents
            header="Min order amount"
            value={value.min_order_amount}
            isChanged={value.min_order_amount_was_changed}
          />
          <HistorySingleComponents
            header="Name tag"
            value={value.name_tag}
            isChanged={value.name_tag_was_changed}
          />
          <HistorySingleComponents
            header="Percent off"
            value={value.percent_off}
            isChanged={value.percent_off_was_changed}
          />
          <HistorySingleComponents
            header="still valid by bearer"
            value={value.still_valid_by_bearer}
            isChanged={value.still_valid_by_bearer_was_changed}
          />
          <HistorySingleComponents
            header="Crm status"
            value={value.preliminary_condition.crm_status}
            isChanged={value.preliminary_condition.crm_status_was_changed}
          />
          <HistorySingleComponents
            header="Loyalty tier"
            value={value.preliminary_condition.loyalty_tier}
            isChanged={value.preliminary_condition.loyalty_tier_was_changed}
          />
          <HistorySingleComponents
            header="on birthday"
            value={value.preliminary_condition.on_birthday}
            isChanged={value.preliminary_condition.on_birthday_was_changed}
          />
          <HistorySingleComponents
            header="on signup day"
            value={value.preliminary_condition.on_signup_day}
            isChanged={value.preliminary_condition.on_signup_day_was_changed}
          />
        </div>
      );
    }
  };

  return <div className="history-single">{handleHistoryValue()}</div>;
}

export default CouponHistorySingle;
