import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { timestampToDateFull } from "../../helper/helper.jsx";

function MotorbikeHistoryList(props) {
  const [list, setList] = useState([]);
  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (props.location.state === undefined) {
      return props.history.replace("/home");
    }
    setList(props.location.state.history);
    setAdminList(props.location.state.adminList);
  }, []);

  const handleUserId = (value) => {
    const filtered = adminList.filter((user) => user.admin_uid.includes(value));
    if (filtered.length > 0) {
      const name = filtered[0].given_name + " " + filtered[0].last_name;
      return name;
    } else {
      return "Other Admin";
    }
  };

  const statusCheck = (motor) => {
    switch (motor.motorbike_status) {
      case "Processing":
        return (
          <div className="status-custom-modes processing">
            <span>Processing</span>
          </div>
        );

      case "Approved":
        return (
          <div className="status-custom-modes approved-valid">
            <span>Approved</span>
          </div>
        );
      case "Insurance Policy Expired":
        return (
          <div className="status-custom-modes approved-invalid">
            <span>Insurance Policy Expired</span>
          </div>
        );

      case "Registration Expired":
        return (
          <div className="status-custom-modes approved-invalid">
            <span>Registration Expired</span>
          </div>
        );

      case "Policy Expired":
        return (
          <div className="status-custom-modes expired">
            <span>Policy Expired</span>
          </div>
        );
      case "Rego Expired":
        return (
          <div className="status-custom-modes expired">
            <span>Rego Expired</span>
          </div>
        );
      case "Further Investigation":
        return (
          <div className="status-custom-modes investigation">
            <span>Further Investigation</span>
          </div>
        );
      case "Restricted":
        return (
          <div className="status-custom-modes restricted">
            <span>Restricted</span>
          </div>
        );
      case "Rejected":
        return (
          <div className="status-custom-modes rejected">
            <span>Rejected</span>
          </div>
        );
      case "Removed":
        return (
          <div className="status-custom-modes removed">
            <span>Removed</span>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="table-design">
      <div className="row">
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>User type</th>
                <th>Status</th>
                <th>Status note</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(list)
                .sort(([a], [b]) => b - a)
                .map(([key, value]) => (
                  <tr key={key}>
                    <td>{timestampToDateFull(key)}</td>
                    <td>{value.acting_user_type}</td>
                    {/* <td>{value.motorbike_status}</td> */}
                    <td className="motobike-status-modes-transport">
                      {statusCheck(value)}
                    </td>
                    <td>{value.motorbike_status_note}</td>
                    <td>{handleUserId(value.acting_user_uid)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MotorbikeHistoryList;
