import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import "./onlineBearer.scss";

import { Col, Row } from "react-bootstrap";
import Input from "../common/input";
import { Autocomplete } from "@react-google-maps/api";

import * as transportService from "../findTransport/findTransportService";

function OnlineBearer(props) {
  const [isLoading, setLoading] = useState(false);
  const [assignTransportRiding, setAssignTransportRiding] = useState(false);
  const [assignTransportCycling, setAssignTransportCycling] = useState(false);
  const [assignTransportWalking, setAssignTransportWalking] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [searchResult, setSearchResult] = useState("");

  const [searchRiderLimitOnlineStateTime, setSearchRiderLimitOnlineStateTime] =
    useState("");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const getAllOnlineRider = async () => {
    if (lat === "" && long === "") {
      return toast.error("Please enter a proper location");
    }

    if (searchRiderLimitOnlineStateTime === "") {
      return toast.error("Please enter latest online time in interval");
    }

    if (
      !assignTransportRiding &&
      !assignTransportCycling &&
      !assignTransportWalking
    ) {
      return toast.error("Please select at least one bearer type");
    }

    setLoading(true);
    try {
      const result = await transportService.adminGetOnlineRidersForAssignment(
        searchRiderLimitOnlineStateTime,
        lat,
        long,
        assignTransportWalking,
        assignTransportCycling,
        assignTransportRiding
      );
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            if (result.data.riders.length > 0) {
              props.history.push("/home/onlineBearers/list", {
                riders: result.data.riders,
                lat: lat,
                long: long,
              });
            } else {
              return toast.error("No rider found");
            }
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setLat(place.geometry.location.lat());
      setLong(place.geometry.location.lng());
    } else {
      alert("Please enter text");
    }
  };

  return (
    <div className="search-list online-bearers">
      <ToastContainer />
      {renderSpinner()}

      <Row>
        <Col md="12" style={{ marginBottom: 10 }}>
          Enter Location:
        </Col>
        <Col md="12">
          <Autocomplete
            onPlaceChanged={onPlaceChanged}
            onLoad={onLoad}
            options={{
              types: [],
              strictBounds: false,
              componentRestrictions: { country: "au" },
            }}
          >
            <input
              type="text"
              placeholder="Enter an Address"
              style={{
                display: "block",
                width: "100%",
                backgroundColor: "#FFF",
                border: "1px solid #ced4da",
                borderRadius: 25,
                padding: "18px 10px",
                fontSize: "1rem",
                fontWeight: 400,
                lineHeight: 1.5,
                color: "#212529",
                appearance: "none",
                marginBottom: 20,
                transition:
                  "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
              }}
            />
          </Autocomplete>
        </Col>
        <Col md="12">
          <Input
            label="Latest Online Time in Minutes:"
            type="number"
            name="title"
            value={searchRiderLimitOnlineStateTime}
            onChange={(e) => {
              if (e.target.value !== 0) {
                setSearchRiderLimitOnlineStateTime(e.target.value);
              }
            }}
            style={{ marginTop: 10 }}
            className="margin-top-bottom"
          />
        </Col>
        <Col md="12" style={{ marginBottom: 10 }}>
          Bearer Type:
        </Col>
        <Col md="4">
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={assignTransportRiding}
              onChange={() => {
                setAssignTransportRiding(!assignTransportRiding);
              }}
              style={{ height: 30, marginBottom: 0, marginRight: 5 }}
            />
            Riding
          </label>
        </Col>
        <Col md="4">
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={assignTransportCycling}
              onChange={() => {
                setAssignTransportCycling(!assignTransportCycling);
              }}
              style={{ height: 30, marginBottom: 0, marginRight: 5 }}
            />
            Cycling
          </label>
        </Col>
        <Col md="4" style={{ marginBottom: 20 }}>
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={assignTransportWalking}
              onChange={() => {
                setAssignTransportWalking(!assignTransportWalking);
              }}
              style={{ height: 30, marginBottom: 0, marginRight: 5 }}
            />
            Walking
          </label>
        </Col>
        <Row>
          <Col md="9"></Col>
          <Col md="3" style={{ textAlign: "right" }}>
            <button
              className="search-btn"
              onClick={() => getAllOnlineRider()}
              style={{ margin: "10px 0 20px", display: "block", width: "100%" }}
            >
              Search
            </button>
          </Col>
        </Row>
      </Row>
    </div>
  );
}

export default OnlineBearer;
