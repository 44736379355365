import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import * as service from "../findRider/findRiderService";
import "./riderList.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Input from "../common/input";
import Alart from "../common/alart";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { log } from "../../helper/helper";
import { Row, Col, Space, Typography } from "antd";

function LegalDetail(props) {
  const rider = props.history.location.state
    ? props.history.location.state.rider
    : null;
  const [isLoading, setLoading] = useState(false);
  const [alartTriggerMessage, setAlartTriggerMessage] = useState("");

  const [legalDetailImageUrl, setLegalDetailImageUrl] = useState("");
  const [legalName, setLegalName] = useState("");
  const [legalNameError, setLegalNameError] = useState("");
  const [isLegalAddress, setLegalAddress] = useState(0);
  const [isInsurance, setInsurance] = useState(0);
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");
  const [insuranceExpireDate, setInsuranceExpireDate] = useState("");
  const [insuranceExpireDateError, setInsuranceExpireDateError] = useState();
  const [textareaValue, setTextareaValue] = useState("");
  const [insuranceInvalidityReason, setInsuranceInvalidityReason] =
    useState("");
  const { Title, Text } = Typography;

  const [isVerifyBtnDisable, setVerifyBtnDisable] = useState(false);
  const legalAddress =
    rider && rider.legal_address_segmented
      ? rider.legal_address_segmented.street_no +
        " " +
        rider.legal_address_segmented.street_name +
        " " +
        rider.legal_address_segmented.region_area +
        " " +
        rider.legal_address_segmented.state +
        " " +
        rider.legal_address_segmented.post_code
      : "";
  const residentialAddress =
    rider && rider.residential_address_segmented
      ? rider.residential_address_segmented.street_no +
        " " +
        rider.residential_address_segmented.street_name +
        " " +
        rider.residential_address_segmented.region_area +
        " " +
        rider.residential_address_segmented.state +
        " " +
        rider.residential_address_segmented.post_code
      : "";

  const insouranceCompanyName = rider ? rider.insurer_company : "";

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const getLegalDetailImage = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        log(url);
        setLegalDetailImageUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      } else {
        if (rider === null) {
          props.history.replace("/home/allRiders");
        } else {
          if (legalDetailImageUrl === "") {
            if (rider ? rider.insurance_certificate_url : "") {
              getLegalDetailImage(rider ? rider.insurance_certificate_url : "");
            }
          }
        }
      }
    });
  }, []);

  const handleValidationLegalName = (value) => {
    if (value.replace(/\s/g, "") !== "") {
      setLegalName(value);
      setLegalNameError("");
    } else {
      setLegalName("");
      setLegalNameError("Please fill Legal Name");
    }
  };

  const handleLegalAddressOther = (value) => {
    if (value === 0) {
      setLegalAddress(0);
      if (isInsurance === 0) {
        setVerifyBtnDisable(false);
      } else {
        setVerifyBtnDisable(true);
      }
    } else {
      setLegalAddress(1);
      setVerifyBtnDisable(true);
    }
  };

  const handleInsouranceCompany = (value) => {
    if (value === 0) {
      setInsurance(0);
      if (isLegalAddress === 0) {
        setVerifyBtnDisable(false);
      } else {
        setVerifyBtnDisable(true);
      }
    } else {
      setInsurance(1);
      setVerifyBtnDisable(true);
    }
  };

  const handlePolicyNumber = (value) => {
    if (value.trim() !== "") {
      setPolicyNumber(value);
      setPolicyNumberError("");
    } else {
      setPolicyNumber("");
      setPolicyNumberError("Please fill Policy Number");
    }
  };

  const adminRiderDocumentReviewReject = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewReject(
        rider.rider_uid,
        note,
        2,
        insuranceInvalidityReason
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const adminRiderFurtherInvestigation = async (note) => {
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewFurtherInvestigation(
        rider.rider_uid,
        note,
        2,
        insuranceInvalidityReason
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setAlartTriggerMessage(result.data.message);
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleAlart = () => {
    setLegalDetailImageUrl("");
    setAlartTriggerMessage("");
    setLegalName("");
    setLegalNameError("");
    setPolicyNumber("");
    setPolicyNumberError("");
    setInsuranceExpireDate("");
    setInsuranceExpireDateError("");
    setInsuranceInvalidityReason("");
    setTextareaValue("");
    props.history.replace("/home/allRiders");
  };

  const verifyLegalDetail = async () => {
    setLegalNameError("");
    setPolicyNumberError("");
    setInsuranceExpireDateError("");

    if (legalName.replace(/\s/g, "") === "") {
      return setLegalNameError("Must contain English Letter(s) only");
    }

    if (policyNumber.trim() === "") {
      return setPolicyNumberError(
        "Must contain English Letter(s) and/or Number(s)"
      );
    }

    if (insuranceExpireDate === "" || insuranceExpireDate === null) {
      return setInsuranceExpireDateError("Pick a date to continue");
    }

    var sortedBirth = new Date(insuranceExpireDate);
    const momentTime = Moment(sortedBirth).format("YYYY-MM-DD");
    setLoading(true);
    try {
      const result = await service.adminRiderDocumentReviewVerifyLegalDetails(
        rider.rider_uid,
        legalName.toLocaleUpperCase(),
        policyNumber.toLocaleUpperCase(),
        momentTime
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setLegalDetailImageUrl("");
            setAlartTriggerMessage("");
            setLegalName("");
            setLegalNameError("");
            setPolicyNumber("");
            setPolicyNumberError("");
            setInsuranceExpireDate("");
            setInsuranceExpireDateError("");
            setTextareaValue("");
            props.history.replace("/home/allRiders/rightToWork", {
              rider: rider,
            });
            return;
          case 401:
            toast.error(result.data.message);
            return this.props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const items = [
    {
      key: "1",
      label: "Full Name",
      children: (
        <p>
          {rider.given_name + " " + rider.middle_name + " " + rider.last_name}
        </p>
      ),
    },
    {
      key: "2",
      label: "Legal Name",
      children: <p>{rider.legal_name}</p>,
    },
    {
      key: "3",
      label: "Residential Address",
      children: <p>{residentialAddress}</p>,
    },
    {
      key: "4",
      label: "Legal Address",
      children: <p>{legalAddress}</p>,
    },
  ];

  return (
    <div className="legal-detail-confirm new-rider-steps">
      <ToastContainer />
      {renderSpinner()}
      <div className="container">
        <div className="row">
          <div className="steps">
            <div className="step number active">1</div>
            <div className="step line active"></div>
            <div className="step number active">2</div>
            <div className="step line"></div>
            <div className="step number">3</div>
            <div className="step line"></div>
            <div className="step number">4</div>
          </div>
        </div>
        <div className="row">
          <div className="header-title">
            <h1>Legal Details</h1>
          </div>
        </div>
        {rider && rider.public_liability_insurance_not_provided ? (
          <Row>
            <Col span={18} offset={3}>
              <Title level={4}>
                Mismatch detected between{" "}
                <span style={{ color: "blue" }}>Full Name</span> &{" "}
                <span style={{ color: "red" }}>Legal Name</span>
              </Title>
            </Col>
            <Col span={18} offset={3}>
              <Text
                type="secondary"
                style={{ fontSize: 16, textAlign: "justify", display: "block" }}
              >
                Please ask the applicant to provide the correct ABN details that
                are registered under their name and match their Full Name
              </Text>
            </Col>
            <Col span={18} offset={3} style={{ marginTop: 50 }}>
              {/* <Descriptions layout="vertical" items={items} /> */}
              <Row>
                <Col span={12}>
                  <Space.Compact direction="vertical" block align="start">
                    <Text type="secondary">Full Name:</Text>
                    <Title level={4} style={{ marginTop: 0, color: "blue" }}>
                      {" "}
                      {rider.given_name +
                        " " +
                        rider.middle_name +
                        " " +
                        rider.last_name}
                    </Title>
                  </Space.Compact>
                </Col>
                <Col span={12}>
                  <Space.Compact direction="vertical" block align="start">
                    <Text type="secondary">Legal Name:</Text>
                    <Title level={4} style={{ marginTop: 0, color: "red" }}>
                      {rider.legal_name}
                    </Title>
                  </Space.Compact>
                </Col>
                <Col span={12} style={{ marginTop: 30 }}>
                  <Space.Compact direction="vertical" block align="start">
                    <Text type="secondary">Residential Address:</Text>
                    <Title level={5} style={{ marginTop: 0 }}>
                      {residentialAddress}
                    </Title>
                  </Space.Compact>
                </Col>
                <Col span={12} style={{ marginTop: 30 }}>
                  <Space.Compact direction="vertical" block align="start">
                    <Text type="secondary">Legal Address:</Text>
                    <Title level={5} style={{ marginTop: 0 }}>
                      {legalAddress}
                    </Title>
                  </Space.Compact>
                </Col>
                <Col span={24} style={{ marginTop: 30 }}>
                  <div className="text-area-info">
                    <div>Note:</div>
                    <textarea
                      className="textarea-info"
                      name="moreInfo"
                      id="moreInfo"
                      cols="30"
                      rows="10"
                      value={textareaValue}
                      onChange={(e) => {
                        setTextareaValue(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </Col>
                <div className="group-btn-reject" style={{ width: "100%" }}>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <button
                        onClick={() =>
                          adminRiderDocumentReviewReject(textareaValue)
                        }
                        disabled={
                          textareaValue.replace(/\s/g, "").length > 1
                            ? false
                            : true
                        }
                      >
                        Reject
                      </button>
                    </Col>
                    <Col span={12}>
                      <button
                        onClick={() =>
                          adminRiderFurtherInvestigation(textareaValue)
                        }
                        disabled={
                          textareaValue.replace(/\s/g, "").length > 1
                            ? false
                            : true
                        }
                        className="request"
                      >
                        Request User For More Information
                      </button>
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
          </Row>
        ) : (
          <div className="row">
            <div className="col-md-7">
              <iframe src={legalDetailImageUrl} title="pdf" />
            </div>
            <div className="col-md-5">
              <div className="input-text-wrapper">
                <Input
                  label="Legal Name:"
                  type="String"
                  name="legalName"
                  value={legalName}
                  onChange={(e) => handleValidationLegalName(e.target.value)}
                  disabled={isVerifyBtnDisable}
                  error={legalNameError}
                />
              </div>
              <div className="input-radio-wrapper">
                <div className="title">Legal Address:</div>
                <input
                  type="radio"
                  value={isLegalAddress === 0}
                  name="legalAddress"
                  checked={isLegalAddress === 0}
                  onChange={() => handleLegalAddressOther(0)}
                />{" "}
                <label htmlFor=""> {legalAddress}</label>
                <br />
                <input
                  type="radio"
                  value={isLegalAddress === 1}
                  name="legalAddress"
                  checked={isLegalAddress === 1}
                  onChange={() => handleLegalAddressOther(1)}
                />{" "}
                <label htmlFor=""> Other</label>
              </div>
              <div className="input-radio-wrapper">
                <div className="title">Insurance Company:</div>
                <input
                  type="radio"
                  value={isInsurance === 0}
                  name="isInsurance"
                  checked={isInsurance === 0}
                  onChange={() => handleInsouranceCompany(0)}
                />{" "}
                <label htmlFor="">{insouranceCompanyName}</label>
                <br />
                <input
                  type="radio"
                  value={isInsurance === 1}
                  name="isInsurance"
                  checked={isInsurance === 1}
                  onChange={() => handleInsouranceCompany(1)}
                />{" "}
                <label htmlFor="">Other</label>
              </div>
              <div className="input-text-wrapper">
                <Input
                  label="Policy Number:"
                  type="String"
                  name="policyNumber"
                  error={policyNumberError}
                  value={policyNumber}
                  onChange={(e) => handlePolicyNumber(e.target.value)}
                  disabled={isVerifyBtnDisable}
                />
              </div>
              <div className="input-text-wrapper form-group">
                <label htmlFor="dateTime">Insurance Expiry Date:</label>
                <DatePicker
                  selected={insuranceExpireDate}
                  onChange={(date) => setInsuranceExpireDate(date)}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  disabled={isVerifyBtnDisable}
                />
                {insuranceExpireDateError && (
                  <div className="alert alert-danger custom-error">
                    {insuranceExpireDateError}
                  </div>
                )}
              </div>
              <div className="group-btn-verify">
                <button
                  onClick={() => verifyLegalDetail()}
                  disabled={isVerifyBtnDisable}
                >
                  Verify
                </button>
              </div>
              <div className="text-area-info">
                <div>Insurance Invalidity Reason:</div>
                <textarea
                  label="Insurance Invalidity Reason: "
                  type="String"
                  cols="30"
                  rows="10"
                  className="textarea-info"
                  name="insuranceInvalidityReason"
                  value={insuranceInvalidityReason}
                  onChange={(e) => {
                    setInsuranceInvalidityReason(e.target.value);
                  }}
                />
              </div>
              <div className="text-area-info">
                <div>Note:</div>
                <textarea
                  className="textarea-info"
                  name="moreInfo"
                  id="moreInfo"
                  cols="30"
                  rows="10"
                  value={textareaValue}
                  onChange={(e) => {
                    setTextareaValue(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="group-btn-reject">
                <div className="row">
                  <div className="col-md-4">
                    <button
                      onClick={() =>
                        adminRiderDocumentReviewReject(textareaValue)
                      }
                      disabled={
                        textareaValue.replace(/\s/g, "").length > 1
                          ? false
                          : true
                      }
                    >
                      Reject
                    </button>
                  </div>
                  <div className="col-md-8">
                    <button
                      onClick={() =>
                        adminRiderFurtherInvestigation(textareaValue)
                      }
                      disabled={
                        textareaValue.replace(/\s/g, "").length > 1
                          ? false
                          : true
                      }
                      className="request"
                    >
                      Request User For More Information
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {alartTriggerMessage !== "" ? (
        <Alart
          message={alartTriggerMessage}
          actionTitle="Ok"
          handleAlartAction={() => handleAlart()}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default LegalDetail;
