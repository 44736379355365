import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import * as riderService from "../findRiderService";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../common/spinner";
import { log } from "../../../helper/helper";

function AllowedOnBoarding({ user, props, handleReloadSingle }) {
  const [isLoading, setLoading] = useState(false);
  const [isCheck, setCheck] = useState(
    user.user_access_is_alowed_to_onboarding
  );

  const [isReasonShow, setReasonShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [reason, setReason] = useState("");
  const [allowedBtnDisable, setAllowedBtnDisable] = useState(true);

  const inputHasChanged = () => {
    setAllowedBtnDisable(true);
    setReason("");

    if (!isCheck === user.is_user_allowed_by_bearer) {
      if (!isCheck) {
        setReasonShow(false);
      } else {
        setReasonShow(true);
      }
    } else {
      if (!isCheck) {
        setReasonShow(false);
        setAllowedBtnDisable(false);
      } else {
        setReasonShow(true);
      }
    }
    setCheck(!isCheck);
  };

  const handleDefaultValue = () => {
    setCheck(user.user_access_is_alowed_to_onboarding);
    setReason(
      user.not_allowed_reason !== undefined || user.not_allowed_reason !== null
        ? user.not_allowed_reason
        : ""
    );
    setReasonShow(user.user_access_is_alowed_to_onboarding ? false : true);
    setAllowedBtnDisable(true);
  };

  const exitBtn = () => {
    setShow(false);
    handleDefaultValue();
  };

  const handleTextAreaAllowedBearerChange = (value) => {
    if (value.length > 0) {
      setReason(value);
      setAllowedBtnDisable(false);
    } else {
      setReason("");
      setAllowedBtnDisable(true);
    }
  };

  const setAllowedByUserData = async () => {
    handleClose(true);
    setLoading(true);
    setAllowedBtnDisable(true);
    try {
      const result = await riderService.adminOnboardingChangeAllow(
        user.rider_uid,
        isCheck,
        reason
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success(result.data.message);
            handleReloadSingle();
            return;
          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  return (
    <div className="motorbike-count allowed-onboarding">
      <div className="row">
        <ToastContainer />
        {renderSpinner()}
        <div className="col-md-4">
          <h5>User Access to Onboarding</h5>
        </div>
        <div className="col-md-4">
          <div
            className={
              user && user.user_access_is_alowed_to_onboarding
                ? "active"
                : "deleted"
            }
          >
            <span>
              {user && user.user_access_is_alowed_to_onboarding
                ? "true"
                : "false"}
            </span>
            <div className="reason">{user ? user.not_allowed_reason : ""}</div>
          </div>
        </div>
        <div className="col-md-4">
          <Button variant="primary" className="edit-btn" onClick={handleShow}>
            Edit
          </Button>
          <Modal show={show} className="allowed-by-user" onHide={exitBtn}>
            <Modal.Header closeButton>
              <Modal.Title>
                {user && user.is_sign_up_completed === false
                  ? "User Access to Onboarding"
                  : "Allowed By Bearer"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="toggleSwitch">
                <div className="container">
                  <span className="switch-label">Allowed by Bearer:</span>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isCheck}
                      name="allowedByBearer"
                      id="allowedByBearer"
                      onChange={() => inputHasChanged()}
                    />
                    <label className="label" htmlFor="allowedByBearer">
                      <span className="inner" />
                      <span className="switch" />
                    </label>
                  </div>
                </div>
              </div>
              {isReasonShow ? (
                <textarea
                  value={reason}
                  onChange={(e) =>
                    handleTextAreaAllowedBearerChange(e.target.value)
                  }
                  className="allowed-by-user-textArea"
                />
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                disabled={allowedBtnDisable}
                onClick={setAllowedByUserData}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default AllowedOnBoarding;
