import React, { useEffect } from "react";
import "./global.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  timestampToDateYearFormat,
  timestampToDateTimeFormat,
} from "../../helper/helper";
import ridingImage from "../../assets/riding.png";
import walkingImage from "../../assets/walking.png";
import cyclingImage from "../../assets/cycling.png";

function ShowActiveTransports(props) {
  const transports = props.history.location.state
    ? props.history.location.state.transportList
    : [];
  const userType = props.history.location.state
    ? props.history.location.state.userType
    : "";
  const auth = getAuth();
  useEffect(() => {
    if (!props.history.location.state) {
      props.history.replace("/home/dashboard");
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        props.history.replace("/login");
      }
    });
  });

  const handleTransportDate = (time) => {
    return timestampToDateYearFormat(time._seconds, time._nanoseconds);
  };
  const handleTransportTime = (time) => {
    return timestampToDateTimeFormat(time._seconds, time._nanoseconds);
  };
  const handleBearerType = (bearer) => {
    switch (bearer) {
      case "riding":
        return (
          <div className="bearer">
            <img src={ridingImage} alt="rider" />
          </div>
        );
      case "cycling":
        return (
          <div className="bearer">
            <img src={cyclingImage} alt="cycle" />
          </div>
        );
      case "walking":
        return (
          <div className="bearer">
            <img src={walkingImage} alt="walk" />
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className="show-all-list">
      <div className="headline">
        <h3>Show Active Transports</h3>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Transport Date</th>
            <th>Rider’s Name</th>
            <th>
              {userType && userType === "customer"
                ? "Original Price"
                : "Original Payable"}
            </th>
            <th>
              {userType && userType === "customer"
                ? "Paid Amount"
                : "Final Paid"}
            </th>
            <th>Mode of Transport</th>
            <th>Transport State</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transports.map((transport) => (
            <tr key={transport.transport_id}>
              <td>
                {handleTransportDate(transport.time)}
                <br />
                {handleTransportTime(transport.time)}
              </td>
              <td>
                {transport.rider_given_name + " " + transport.rider_last_name}
              </td>
              <td>
                {userType && userType === "customer"
                  ? `A$ ${transport.transport_original_price}`
                  : `A$ ${transport.original_payable_to_rider}`}
              </td>
              <td>
                {userType && userType === "customer"
                  ? `A$ ${transport.transport_receivable_price}`
                  : `A$ ${transport.final_paid_to_rider}`}
              </td>
              <td>{handleBearerType(transport.assigned_bearer_type)}</td>
              <td>
                {transport.transport_state
                  ? transport.transport_state
                  : transport.state}
              </td>

              <td>
                <button
                  className="single-view"
                  onClick={() =>
                    props.history.push("/home/transportSingle", {
                      transportId: transport.transport_id,
                      userType: userType,
                      isFromFindTransport: false,
                    })
                  }
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ShowActiveTransports;
