import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import loginBackground from "../../assets/login-background.jpg";
import "./login.scss";
import * as loginService from "./loginServices";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { log } from "../../helper/helper";

class ConfirmMobile extends Form {
  state = {
    data: { phoneNumber: "" },
    errors: {},
    isLoading: false,
    code: "",
  };

  schema = {
    mobile: Joi.string().required().label("Phone Number"),
  };

  componentDidMount() {
    log("********88 ConfirmMobile ********");
    log(this.props.location);
    if (this.props.location.state) {
      this.setState({
        data: { phoneNumber: this.props.location.state.mobile },
      });
    } else {
      this.props.history.replace("/login");
    }

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        this.props.history.replace("/login");
      }
    });
  }

  doSubmit = async () => {
    this.setState({ isLoading: true });
    try {
      const result = await loginService.adminSendSMS();
      log(result);
      if (result) {
        this.setState({ isLoading: false });
        switch (result.data.code) {
          case 200:
            log(result.data.code);
            this.props.history.replace("/confirmCode", {
              isComeFromConfirmMobile: true,
            });
            return;
          case 401:
            return toast.error(result.data.message);
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error.message);
      toast.error("There is a problem.");
    }
    this.setState({ isLoading: false });
  };

  render() {
    const isLoading = this.state.isLoading;
    const renderAuthButton = () => {
      if (isLoading) {
        return <Spinner />;
      }
    };
    return (
      <div>
        {renderAuthButton()}
        <ToastContainer />
        <div className="container loginForm">
          <div className="row loginRow">
            <div className="col-md-6 d-none d-sm-none d-md-block">
              <div className="loginImageHolder">
                <img
                  src={loginBackground}
                  className="loginImage"
                  alt="background"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="loginInput">
                <h6>Verification code(sms) will be send to:</h6>
                <form onSubmit={this.handleSubmitWithActiceBtn}>
                  {this.renderInput(
                    "phoneNumber",
                    "",
                    "text",
                    "Phone Number",
                    true
                  )}
                  {this.renderButton("Confirm and Send", true)}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmMobile;
