import React, { useEffect, useState } from "react";
import "./parcel.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { timestampToDateFull } from "../../helper/helper.jsx";

function ParcelHistory(props) {
  const [list, setList] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (props.location.state === undefined) {
      return props.history.replace("/home");
    }
    setList(props.location.state.parcel);
  }, []);

  return (
    <div className="parcel-list">
      <div className="row">
        <div className="col-md-12">
          <table className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Parcel Type</th>
                <th>Weight</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(list)
                .sort(([a], [b]) => b - a)
                .map(([key, value]) => (
                  <tr key={key}>
                    <td>{timestampToDateFull(key)}</td>
                    <td>{value.parcel_type}</td>
                    <td>
                      {value.parcel_min_weight} - {value.parcel_max_weight}
                    </td>
                    <td>{value.parcel_description}</td>
                    <td>
                      <button
                        className="search-view"
                        onClick={() => {
                          props.history.push(
                            "/home/parcelList/parcelHistory/single",
                            {
                              key: key,
                              value: value,
                            }
                          );
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ParcelHistory;
