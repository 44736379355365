import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";

export function adminFindTransport(inputValue) {
  const data = { order_number: Number(inputValue) };
  const functions = getFunctions();
  log(data);
  const findTransport = httpsCallable(functions, config.adminFindTransport);
  return findTransport(data);
}

export function adminGetRiderInfo(riderId) {
  const data = { rider_user_id: riderId };
  const functions = getFunctions();
  log(data);
  const findTransport = httpsCallable(functions, config.adminGetRiderInfo);
  return findTransport(data);
}

export function adminGetCustomerInfo(customerID) {
  const data = { customer_user_id: customerID };
  const functions = getFunctions();
  log(data);
  const findTransport = httpsCallable(functions, config.adminGetCustomerInfo);
  return findTransport(data);
}


export function adminTransportAssignToNewRider(transportId) {
  const data = { transport_id: transportId };
  const functions = getFunctions();
  log(data);
  const asignTransport = httpsCallable(functions, config.adminTransportAssignToNewRider);
  return asignTransport(data);
}

export function adminUnlockTransportForAssignment(transportId) {
  const data = { transport_id: transportId };
  const functions = getFunctions();
  log(data);
  const asignTransport = httpsCallable(functions, config.adminUnlockTransportForAssignment);
  return asignTransport(data);
}

export function adminGetOnlineRidersForAssignment(searchRiderLimitOnlineStateTime, originLat, originLng, walking, cycling, riding) {
  const data = { 
    search_rider_limit_online_state_time: searchRiderLimitOnlineStateTime, 
    origin_lat : Number(originLat), 
    origin_lng : Number(originLng),
    walking : walking,
    cycling : cycling,
    riding : riding

  };
  const functions = getFunctions();
  log(data);
  const asignTransport = httpsCallable(functions, config.adminGetOnlineRidersForAssignment);
  return asignTransport(data);
}

export function adminTransportAssignmentFinalised(transportId, riderId) {
  const data = { 
    transport_id: transportId, 
    rider_user_id : riderId
  };
  const functions = getFunctions();
  log(data);
  const asignTransport = httpsCallable(functions, config.adminTransportAssignmentFinalised);
  return asignTransport(data);
}

export function adminGetTransport(transportId) {
  const data = { 
    transport_id: transportId, 
  };
  const functions = getFunctions();
  log(data);
  const findTransport = httpsCallable(functions, config.adminFindTransportByID);
  return findTransport(data);
}
