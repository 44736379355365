import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./onlineBearer.scss";
import { Col, Row } from "react-bootstrap";
import * as utils from "../../helper/helper";
import { GoogleMap, Marker } from "@react-google-maps/api";
import searchPlace from "../../assets/online-searched-location.svg";
import walk from "../../assets/bearer-online-walk.svg";
import cycle from "../../assets/bearer-online-cycle.svg";
import rider from "../../assets/bearer-online-rider.svg";

function OnlineBearerList(props) {
  const riders = props.location.state.riders ? props.location.state.riders : [];
  const searchedLat = props.location.state.lat ? props.location.state.lat : 0.0;
  const searchLong = props.location.state.long
    ? props.location.state.long
    : 0.0;

  const [map, setMap] = React.useState(null);
  const [positions, setPositions] = useState([
    {
      lat: searchedLat,
      lng: searchLong,
      icon: searchPlace,
      zIndex: 1,
      modes: "",
      title: "Your seached place",
      mobile: "",
      id: "1",
    },
  ]);
  const [markerId, setMarkerId] = useState(0);
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
      const updatedPositions = [...positions];

      riders.forEach((rider) => {
        const newPosition = {
          lat: rider.current_lat,
          lng: rider.current_lng,
          icon: handleIcon(rider.mode_of_transport),
          zIndex: 1,
          modes: rider.mode_of_transport,
          title: rider.given_name + " " + rider.last_name,
          mobile: rider.mobile_number,
          id: rider.rider_uid,
        };
        updatedPositions.push(newPosition);
      });

      setPositions(updatedPositions);
    });
  }, []);

  const handleIcon = (modes) => {
    switch (modes) {
      case "cycling":
        return cycle;
      case "riding":
        return rider;
      case "walking":
        return walk;
      default:
        break;
    }
  };

  const handleTransportModeImage = (transport) => {
    switch (transport) {
      case "cycling":
        return (
          <img
            style={{ width: 45, display: "block", margin: "0 auto" }}
            src={require("../../assets/cycling.png")}
            alt="walk"
          />
        );
      case "riding":
        return (
          <img
            style={{ width: 45, display: "block", margin: "0 auto" }}
            src={require("../../assets/riding.png")}
            alt="walk"
          />
        );
      case "walking":
        return (
          <img
            style={{ width: 45, display: "block", margin: "0 auto" }}
            src={require("../../assets/walking.png")}
            alt="walk"
          />
        );
      default:
        break;
    }
  };

  const defaultMapOptions = {
    fullscreenControl: true,
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const center = {
    lat: searchedLat,
    lng: searchLong,
  };

  const loadGoogleMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        onLoad={onLoadBounds}
        onUnmount={onUnmount}
        defaultOptions={defaultMapOptions}
        center={center}
        options={{
          mapId: utils.getMapIdBasedonEnvironment(),
        }}
      >
        {positions.map((marker) => (
          <Marker
            position={marker}
            key={marker.id}
            // title={marker.mobile}
            icon={{
              url: marker.icon,
            }}
            onClick={() => {
              setMarkerId(marker.id);
            }}
            {...(markerId === marker.id && {
              label: {
                className: "marker-label",
                text: `${marker.title + " - " + marker.mobile}`,
              },
            })}
            zIndex={marker.zIndex}
          />
        ))}
        <></>
      </GoogleMap>
    );
  };

  const onLoadBounds = (map) => {
    if (!map) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    positions.forEach((child) => {
      bounds.extend(new window.google.maps.LatLng(child.lat, child.lng));
    });
    map.fitBounds(bounds);
    setMap(map);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <div className="online-bearers search-list">
      <Row>
        <Col md="12">
          {positions.length > 1 && (
            <div className="map" style={{ width: "100%", height: "300px" }}>
              {" "}
              {loadGoogleMap()}
            </div>
          )}
        </Col>
        <Col md="12" style={{ textAlign: "left", margin: "20px 0" }}>
          We Found {riders.length} riders
        </Col>
        <Col md="12">
          <table className="table">
            <thead style={{ textAlign: "center" }}>
              <tr>
                <th>Full Name</th>
                <th>Mobile Number</th>
                <th>Mode of Transport</th>
                <th>Latest Online Time</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {riders.map((rider) => (
                <tr key={rider.rider_uid}>
                  <td>{rider.given_name + " " + rider.last_name}</td>
                  <td>{rider.mobile_number}</td>
                  <td>{handleTransportModeImage(rider.mode_of_transport)}</td>
                  <td>{utils.timestampToDateFull(rider.last_state_update)}</td>
                  <td>
                    <button
                      className="search-view"
                      onClick={() =>
                        props.history.push(
                          "/home/findRider/riderList/riderSingle",
                          {
                            riderId: rider.rider_uid,
                          }
                        )
                      }
                    >
                      View Profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
}

export default OnlineBearerList;
