import React, { useEffect, useState } from "react";
import "./findRider.scss";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import OnboardingMotorCount from "./components/onboardingMotorCount";
import RiderRoadMap from "./components/riderRoadMap";
import AllowedOnBoarding from "./components/allowedOnboarding";
import * as riderService from "./findRiderService";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import { log } from "../../helper/helper";
import { Col, Row } from "react-bootstrap";
import profileImage from "../../assets/profile.png";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Note from "../findCustomer/components/note";

function UncompletedRider(props) {
  const state = props.history.location.state
    ? props.history.location.state
    : null;
  const [isLoading, setLoading] = useState(true);
  const [rider, setRider] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });

    if (state === null) {
      return props.history.replace("/home");
    }

    getRiderInfoByID();
  }, []);

  const downloadAvatar = (avatar) => {
    const storage = getStorage();
    const starsRef = ref(storage, avatar);
    getDownloadURL(starsRef)
      .then((url) => {
        setImageUrl(url);
      })
      .catch((error) => {
        log(error);
      });
  };

  const getRiderInfoByID = async () => {
    setLoading(true);
    try {
      const result = await riderService.adminGetRiderInfo(state.riderId);
      setLoading(false);
      log(result);
      if (result) {
        setLoading(false);
        switch (result.data.code) {
          case 200:
            if (result.data.rider.is_sign_up_completed) {
              return props.history.replace("/home");
            } else {
              setRider(result.data.rider);
              const avatar = result.data.rider.avatar_url;
              if (avatar) {
                downloadAvatar(avatar);
              }
            }
            break;
          case 401:
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      log(error);
      toast.error(error.message);
      props.history.replace("/home");
    }
    setLoading(false);
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleRiderView = () => {
    if (rider !== null) {
      return (
        <div className="search-single">
          <div className="user-info">
            <div className="profile-avatar">
              <figure>
                <div className="avatar">
                  <img
                    src={imageUrl !== "" ? imageUrl : profileImage}
                    alt="avatar"
                  />
                </div>
              </figure>
              <figcaption>
                {rider.given_name && (
                  <span>
                    <img
                      src={require("../../assets/customer-info-profile.png")}
                      alt="avatar-icon"
                    />
                  </span>
                )}
                {rider.given_name + " " + rider.last_name}
              </figcaption>
            </div>
            <Row>
              <Col md="6">
                <div className="thumbnail">
                  <span>
                    <img
                      src={require("../../assets/customer-info-phone.png")}
                      alt="phone-icon"
                    />
                  </span>
                  <h6>{rider.mobile_number}</h6>
                </div>
              </Col>
              <Col md="6">
                <div className="thumbnail">
                  <span>
                    <img
                      src={require("../../assets/customer-info-email.png")}
                      alt="phone-icon"
                    />
                  </span>
                  <h6>{rider.email_address}</h6>
                </div>
              </Col>
            </Row>
          </div>
          <div className="note">
            <Note
              user={rider ? rider : null}
              props={props}
              handleReloadSingle={() => getRiderInfoByID()}
            />
          </div>
          <OnboardingMotorCount
            props={props}
            rider={rider}
            handleReloadSingle={() => getRiderInfoByID()}
          />
          <AllowedOnBoarding
            props={props}
            user={rider}
            handleReloadSingle={() => getRiderInfoByID()}
          />
          <RiderRoadMap
            props={props}
            rider={rider}
            handleReloadSingle={() => getRiderInfoByID()}
          />
        </div>
      );
    }
  };

  return (
    <div className="search-single">
      <ToastContainer />
      {renderSpinner()}
      {handleRiderView()}
    </div>
  );
}

export default UncompletedRider;
